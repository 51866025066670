import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useDispatch, useSelector } from "react-redux";
import { getValidData, checkInternalEmail } from "../../../utils/lib";
import { updateAuditData } from "../../../reducers/audit.reducer";
import { AuditStyles } from "../../../styles";
import { selectEmail } from "../../../selectors/login.selector";
import moment from 'moment';

const AuditInformationEditModal = ({
  open = false,
  onClose,
  factoryData,
  auditData,
}) => {
  const initData = {
    auditType: getValidData(auditData?.metadata?.auditType),
    auditStartDate: getValidData(auditData?.metadata?.auditStartDate),
    auditEndDate: getValidData(auditData?.metadata?.auditEndDate),
    capStartDate: auditData?.metadata?.capStartDate
      ? moment(auditData.metadata.capStartDate) 
      : null,
    auditScheduler: getValidData(auditData?.metadata?.auditScheduler),
    auditFunder: getValidData(auditData?.metadata?.auditFunder),
    auditFirm: getValidData(auditData?.metadata?.auditFirm),
    auditor: getValidData(auditData?.auditor),
    primaryLanguage: getValidData(auditData?.metadata?.primaryLanguage),
    workersLanguage: getValidData(auditData?.metadata?.workersLanguage),
    state: getValidData(auditData?.state),
    originalAuditScore: getValidData(auditData?.metadata?.originalAuditScore),
    auditScore: getValidData(auditData?.risk?.auditScore),
  };

  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);

  const [formData, setFormData] = useState(initData);
  const [initialData, setInitialData] = useState({});

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [auditData, factoryData]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateAuditData({
          organisationId: auditData?.organisationId,
          supplierId: auditData?.supplierId,
          factoryId: auditData?.factoryId,
          auditId: auditData?._id,
          changes,
        })
      );
    }
    onClose();
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleDelete = () => {
    dispatch(
      updateAuditData({
        organisationId: auditData?.organisationId,
        supplierId: auditData?.supplierId,
        factoryId: auditData?.factoryId,
        auditId: auditData?._id,
        changes: { state: "deleted" },
      })
    );
    setOpenDeleteModal(false);
    onClose();
  };

  return (
    <Box sx={AuditStyles.containerBox}>
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent={"space-between"} sx={{ mb: 1 }}>
          <Grid item>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                fontSize: 16,
                color: "#344054",
                display: "inline-block",
              }}
            >
              Edit Audit Information
            </Typography>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
              size="medium"
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          {isInternalUser && (
            <>
              {/* Internal User Fields */}
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <InputLabel
                    id="audit-state-label"
                    sx={{ color: "orange" }}
                  >
                    Audit State
                  </InputLabel>
                  <Select
                    labelId="audit-state-label"
                    name="state"
                    value={formData["state"]}
                    onChange={handleChange}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                          borderColor: "orange",
                        },
                      },
                      "& .MuiSelect-select": {
                        color: "orange",
                      },
                    }}
                  >
                    <MenuItem value="test" sx={{ color: "orange" }}>
                      Internal - Not Approved
                    </MenuItem>
                    <MenuItem value="active" sx={{ color: "green" }}>
                      Internal - Approved
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={"Original Audit Score"}
                  name={"originalAuditScore"}
                  variant="outlined"
                  value={formData["originalAuditScore"]}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "orange",
                    },
                    "& .MuiInputLabel-root": {
                      color: "orange",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label={"Audit Score"}
                  name={"auditScore"}
                  variant="outlined"
                  value={formData["auditScore"]}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "orange",
                    },
                    "& .MuiInputLabel-root": {
                      color: "orange",
                    },
                  }}
                />
              </Grid>
            </>
          )}

          

          {/* General Fields */}
          {Object.keys(initData).map(
            (field, index) =>
              ![
                "state",
                "auditScore",
                "originalAuditScore",
                "capStartDate",
              ].includes(field) && (
                <Grid item xs={4} key={index}>
                  <TextField
                    fullWidth
                    label={
                      field === "auditType"
                        ? "Auditor Scheme"
                        : field.replace(/([A-Z])/g, " $1").trim()
                    }
                    name={field}
                    variant="outlined"
                    value={formData[field]}
                    onChange={handleChange}
                    margin="normal"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                      },
                    }}
                  />
                </Grid>
              )
          )}
          {/* CAP Start Date Field */}
          <Grid item xs={8}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="CAP Start Date"
                value={formData.capStartDate}
                onChange={(newValue) => {
                  setFormData({ ...formData, capStartDate: newValue });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    placeholder="Default to Audit End Date"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={4} container justifyContent="flex-end">
            <Button
              variant="outlined"
              color="error"
              onClick={handleOpenDeleteModal}
              size="medium"
              sx={{ textTransform: "none" }}
            >
              Delete Reports
            </Button>
          </Grid>
        </Grid>
      </form>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {"Delete Audit Report"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this audit?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AuditInformationEditModal;
