import { min } from "moment";

export const containerCard = {
  padding: "0px 32px",
  boxShadow:
    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",

  border: "1px solid rgba(234, 236, 240, 1)",
  borderRadius: "12px",
};

export const headerOfTheCard = {
  fontWeight: 700,
  fontSize: 16,
  color: "#344054",
  display: "inline-block",
};

export const linkText = {
  fontWeight: 600,
  fontSize: 14,
  color: "#3538CD",
  display: "flex",
  textDecoration: "none",
  fontFamily: "Inter, sans-serif",
  
};

// factory risk card
export const riskCard = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  mb: 2,
  padding: "16px 16px",
  borderRadius: "7px",
};

export const riskCardContent = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const riskCardTypography = { textAlign: "center", width: "100%" };

// factory most recent audit
export const mostRecentAuditHeading = {
  color: "#000",
  fontSize: "18px",
  fontWeight: "700",
  my: 3,
};

// factory additional information
export const additionalInformationSectionHeading = {
  width: "auto",
  justifyContent: "flex-start",
  borderRadius: "6px",
  border: "1px solid #D0D5DD",
  background: "#FFF",
  textTransform: "none",
  fontSize: "14px",
  fontWeight: 500,
  display: "flex",
  padding: "4px 10px",
  mb: 1,
  justifyContent: "center",
  alignItems: "center",
  gap: "3px",
  color: "#344054",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "20px",
  textAlign: "center",
};

// additional information section
export const additionalInformationCard = {
  borderRadius: "12px",
  border: "1px solid #EAECF0",
  background: "#FFF",
  boxShadow:
    "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
  px: 3,
};

// issues card
export const issuesTablePaper = {
  p: 3,
  borderRadius: "12px",
  border: "1px solid #EAECF0",
};
export const issuesTableCell = {
  fontWeight: 500,
  fontSize: 14,
  borderBottom: "2px solid #3538CD",
  cursor: "pointer",
};

export const filteredIssuesTableCell = {
  fontWeight: 500,
  fontSize: 14,
  borderBottom: "none",
  cursor: "pointer",
};

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '80vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  color: "#344054", // Consistent text color
  fontFamily: "Inter, sans-serif", // Consistent font
};

