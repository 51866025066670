import React from "react";
import { Box, Grid, Typography, Drawer, Divider } from "@mui/material";
import { useParams } from "react-router-dom";
import { ComponentStyles } from "../../styles";
import FacilityTierMap from "../FacilityDetails/facilityTierMap.component";

// 4imprint Specific ask to separate tier map from "more information"
const SupplierTierMapDrawer = ({
  supplierData,
  isDrawerOpen,
  toggleDrawer,
}) => {

  const params = useParams();

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
  
      <Box
        sx={{ width: 600, padding: 5, paddingLeft: 6, paddingRight: 6 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box>
          <Typography variant="h4" sx={{ mb: 4, mt: 3 }}>
            Facility Tier Map
          </Typography>
          <Divider sx={{ mb: 4 }} />

    
          <FacilityTierMap
            supplierId={params.supplierId}
            currentFactoryId={params.factoryId}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default SupplierTierMapDrawer;