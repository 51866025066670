import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { PageStyles } from "../../styles";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { selectAdditionalFactoryInformation } from "../../selectors/factory.selector";
import { useSelector } from "react-redux";
import { FacilityStyles } from "../../styles";
const AdditionalFactoryInformation = () => {
  const dataMigrationMode = false;
  const [expanded, setExpanded] = React.useState(true);
  
  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const { factoryInfo, auditExists } = useSelector(selectAdditionalFactoryInformation);

  // const selectedAdditionalFactoryInformation = useSelector(
  //   selectAdditionalFactoryInformation
  // );

  // const {
  //   workerDemographics = {},
  //   peakSeason = [],
  //   lowSeason = [],
  // } = selectedAdditionalFactoryInformation?.metadata || {};

  const formatValue = (value) => {
    if (Array.isArray(value)) {
      return value.join(", ");
    }
    return value;
  };
  
    return (
      <Card variant="outlined" sx={FacilityStyles.additionalInformationCard}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="subtitle1" fontWeight="bold">
                Additional Facility Information
              </Typography>
              <Box
                      sx={PageStyles.tierAndPrivateLabel}
                      size="small"
                      variant="outlined"
                    >
                <Typography variant="caption" color="textSecondary">
                {auditExists ? "Data from latest audit" : "Data from intake form"}
                </Typography>
              </Box>        
            </Box>      
            <Button onClick={handleToggleExpand} size="small">
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
          </Box>
          {expanded && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {dataMigrationMode ? (
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    Data Migration and cleaning in progress. This section is temporarily disabled.
                  </Typography>
                </Grid>
              ) : (
                <>
                  <Grid item xs={3}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      sx={{ display: "inline-flex" }}
                      gap={2}
                    >
                      {factoryInfo.map((section, index) => (
                        <Box
                          key={index}
                          variant="outlined"
                          sx={FacilityStyles.additionalInformationSectionHeading}
                        >
                          {section.heading}
                        </Box>
                      ))}
                    </Box>
                  </Grid>
                  <Grid item xs={9}>
                    <Grid container spacing={2}>
                      {factoryInfo.map(
                        (section, sectionIndex) =>
                          section.values.map((item, itemIndex) => (
                            <Grid item xs={3} key={`${sectionIndex}-${itemIndex}`}>
                              <Typography variant="body2" color="textSecondary">
                                {item.label}
                              </Typography>
                              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                                {formatValue(item.value)}
                              </Typography>
                            </Grid>
                          ))
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </CardContent>
      </Card>
    );
  };
  

export default AdditionalFactoryInformation;
