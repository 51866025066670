import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getValidData } from "../../../utils/lib";
import { updateFactoryData } from "../../../reducers/factory.reducer";

const InternalNotesEditModal = ({
  open = false,
  onClose,
  internalData,
}) => {
  const initData = {
    internalStatusNotes: getValidData(internalData?.metadata?.internalStatusNotes),
  };
  
  const [formData, setFormData] = useState(initData);

  // useEffect(() => {
  //   console.log('InternalData:', internalData);
  //   setFormData(initData);
  // }, [internalData]);
  useEffect(() => {
    //console.log('InternalData:', internalData);
    setFormData({
      internalStatusNotes: getValidData(internalData?.metadata?.internalStatusNotes),
    });
  }, [internalData]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log('FormData:', formData); // Log the updated formData
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const changes = {};
    //const changes = Object.keys(formData).reduce((acc, key) => {
      // if (formData[key] !== initData[key]) {
      //   acc[key] = formData[key];
      // }
    if (formData.internalStatusNotes !== initData.internalStatusNotes) {
      changes['metadata.internalStatusNotes'] = formData.internalStatusNotes;
    }

    console.log('Changes:', changes); // Add this to log the changes

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateFactoryData({
          organisationId: internalData?.organisationId,
          supplierId: internalData?.supplierId,
          factoryId: internalData?._id,
          changes,
        })
      );
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%", // Adjusted width since we're only displaying the RHS
          maxWidth: 700,
          p: { xs: 2, sm: 4 },
          boxShadow:
            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
          border: "1px solid rgba(234, 236, 240, 1)",
          borderRadius: "12px",
          py: 2,
        }}
      >
        <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
                <Card
                sx={{
                    p: 2,
                    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                    border: "1px solid rgba(234, 236, 240, 1)",
                    borderRadius: "12px",
                }}
                >
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                    fontWeight: 700,
                    fontSize: 16,
                    color: "#344054",
                    display: "inline-block",
                    }}
                >
                    Edit Notes
                </Typography>
                <TextField
                    fullWidth
                    multiline
                    rows={10} // Adjusted row height for better content space
                    label="Internal Status Notes"
                    name="internalStatusNotes"
                    variant="outlined"
                    value={formData.internalStatusNotes}
                    onChange={handleChange}
                    margin="normal"
                    sx={{
                    "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                        borderRadius: "8px", // Set the border-radius here
                        borderColor: "#E0E0E0", // Lighten border color for subtlety
                        },
                        padding: "12px 16px", // Add padding to make the text more spaced
                    },
                    "& .MuiInputBase-input": {
                        fontSize: "16px", // Adjust font size for consistency
                        lineHeight: "1.5", // Improve text line spacing
                    },
                    }}
                />
                </Card>
            </Grid>
            </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              type="button"
              onClick={onClose}
              sx={{
                mr: 2,
                borderRadius: "8px",
                textTransform: "none",
                color: "#585aeb",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Card>
    </Modal>
  );
};

export default InternalNotesEditModal;