import React, { useEffect, useRef } from "react";
import { Grid, Typography, Box, Divider, Card, Switch } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSupplierNameFromId,
  selectSupplierPageTab,
} from "../selectors/supplier.selector";

import TopBreadcrumbs from "../components/topBreadcrumbs.component";

import SupplierInformation from "../components/SupplierDetails/supplierInformation.component";
import InternalInformation from "../components/SupplierDetails/internalInformation.component";
import SupplierTabs from "../components/SupplierDetails/supplierTabs.component";
import SupplierCompliance from "../components/SupplierDetails/supplierComplianceStatus.component";

import LeftSideNavbar from "../components/leftSideNavbar.component";

import SupplierFacilityAuditsTabList from "../components/SupplierDetails/supplierFacilityAuditsTabList.component";

import EditToggleSwitch from "../components/editToggleSwitch.component";
import ConfidentialBusinessInformation from "../components/confidentialBusinessInformation.component";
import SupplierInternalInformationEditModal from "../components/SupplierDetails/EditModals/editSupplierInternalInformation.component";
import SupplierSupplyChainOverview from "../components/SupplierDetails/supplierSupplyChainOverview.component";
import SupplierFactoryList from "../components/SupplierDetails/supplierFactoryList.component";
import { selectSupplierData } from "../selectors/supplier.selector";
import { PageStyles } from "../styles";
import { supplyChainOverviewCard } from "../styles/supplier.style";
import CommentSection from "../components/commentSection.component";
import { getSupplierById } from "../reducers/supplier.reducer";


const SupplierDetails = () => {
  const generalInfoRef = useRef(null);
  const params = useParams();
  const dispatch = useDispatch();
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );
  const supplierData = useSelector(selectSupplierData);
  const selectedTab = useSelector(selectSupplierPageTab);

  useEffect(() => {
    const fetchSupplierData = async () => {
      console.log(params.supplierId.toString())

      if (!supplierData) {
        dispatch(
          getSupplierById(params.supplierId)
        );
      }
    };

    fetchSupplierData();
  }, [params.supplierId, dispatch]);

  return (
    <Box sx={PageStyles.containerBox}>
      <LeftSideNavbar />
      <Box sx={PageStyles.containerBoxExceptNavbar}>
        <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
          <TopBreadcrumbs />
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography gutterBottom sx={PageStyles.name}>
                {supplierData?.name || supplierName || "-"}
                {supplierData?.metadata?.privateLabel === "Yes" && (
                  <Box
                    sx={PageStyles.tierAndPrivateLabel}
                    size="small"
                    variant="outlined"
                  >
                    Private Label
                  </Box>
                )}
              </Typography>
            </Grid>
            <Grid item>
              <EditToggleSwitch />
            </Grid>
          </Grid>
        </Box>
        <Divider />

        <Grid container spacing={2} sx={PageStyles.secondSectionGrid}>
          <Grid item xs={12} md={12}>
            <div ref={generalInfoRef}>
              <SupplierInformation />
            </div>
          </Grid>
          {/* Internal Information */}
          {/* <Grid item xs={12}>
            <InternalInformation />
          </Grid> */}
          {/* <Grid item xs={12} md ={4}>
            <ConfidentialBusinessInformation
              title="Confidential Business Information"
              data={supplyChainOverviewCard}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Card
              sx={{
                p: 1,
                pt: 3,
                boxShadow:
                  "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                border: "1px solid rgba(234, 236, 240, 1)",
                borderRadius: "12px",
                height: '100%', // Ensure the card takes up the full height
                margin: 'auto',
              }}
            >
              <Grid
                container
                spacing={2}
                justifyContent="space-between"
                sx={{ mb: 2, ml: 2, mr: 2 }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontWeight: 700,
                      fontSize: 16,
                      color: "#344054",
                      display: "inline-block",
                    }}
                  >
                    Internal Notes
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mt: 0, mr: 2 }}
                >
                  <Grid item xs={11.5}>
                    <CommentSection source="supplier"></CommentSection>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={PageStyles.cardForTabsAndBelow}>
              <Grid container>
                <Grid item xs={12}>
                  <SupplierTabs />
                </Grid>
                <Grid item xs={12}>
                  {selectedTab === "status" ? (
                    <SupplierSupplyChainOverview />
                  ) : (
                    <SupplierFactoryList />
                  )}
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SupplierDetails;
