import {
  all,
  put,
  post,
  call,
  take,
  select,
  takeLatest,
} from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import {
  getListOfAllFactories,
  getSearchResultOfFactories,
  getFactoryById,
  setListOfAllFactories,
  setFactoryData,
  setFactoriesDataCache,
  updateFactoryData,
  createFactory,
  fetchSAQFactories,
  setSAQFactories,
  createNewFactoryFromSAQ,
  setFactoryCreationResult,
  uploadFactoryFile,
  setUploadFactoryFiles,
  setSignedUrl,
  getDueActionCerticatesData,
  setDueActionCerticatesData,
} from "../reducers/factory.reducer";
import {
  selectAuthToken,
  selectOrganisationId,
} from "../selectors/login.selector";
import * as API from "../utils/api";
import axios from "axios";
import { logout } from "../reducers/login.reducer";
import { loading, setFilesUploadedIds } from "../reducers/misc.reducer";
import _ from "lodash";
import {
  getSupplierByIdWorker,
  updateSupplierDataWorker,
  createNewSupplierFromSAQWorker,
} from "./supplier.saga";
import {
  getSupplierById,
  setSupplierData,
  updateSupplierData,
} from "../reducers/supplier.reducer";

function* getListOfFactoriesWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const { limit, offset, page, search, supplierId, supplierFactoryList } =
    action.payload;

  const requestURL = search
    ? API.FACTORIES.SEARCH.replace("<ORGANISATION_ID>", organisationId)
    : supplierId
    ? API.FACTORIES.LIST.replace("<ORGANISATION_ID>", organisationId).replace(
        "<SUPPLIER_ID>",
        supplierId
      )
    : API.FACTORIES.GET_LIST_BY_ORGANISATION_ID.replace(
        "<ORGANISATION_ID>",
        organisationId
      );

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const params = search ? { search } : { limit: 999, offset: 0 };
  try {
    const startTime = performance.now();
    yield put(loading(true));
    const response = yield axios.get(requestURL, { headers, params });
    const endTime = performance.now();
    const duration = endTime - startTime;
    console.log(`API call took ${duration.toFixed(2)} milliseconds`);

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(loading(false));

        const liveFactories = response.data.results.factories.filter(
          (factory) => factory.factoryStatus === "live"
        );

        const liveFactoryCount = liveFactories.length;

        if (!supplierFactoryList) {
          if (!search) {
            yield put(
              setListOfAllFactories({
                factories: liveFactories,
                factoryCount: liveFactoryCount,
              })
            );
          } else {
            const liveSearchResults = response.data.results.factories.filter(
              (factory) => factory.status === "live"
            );
            yield put(
              setListOfAllFactories({
                searchResults: liveSearchResults,
                searchCount: liveSearchResults.length,
              })
            );
          }
          yield put(
            setFactoriesDataCache({
              page: search ? search : page,
              results: liveFactories,
            })
          );
        } else {
          yield put(
            setListOfAllFactories({
              factories: liveFactories,
            })
          );
        }
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* getFactoryByIdWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const requestURL = API.FACTORIES.GET_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  try {
    const response = yield axios.get(requestURL, { headers });

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setFactoryData(response.data.results));
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* updateFactoryDataWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.FACTORIES.UPDATE_BY_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const body = {
    ...action.payload.changes,
  };
  try {
    const response = yield axios.put(requestURL, body, { headers });
    console.log("API Response:", response);

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setFactoryData(response.data.results));
      }
    }
  } catch (error) {
    console.error("Failed to fetch suppliers:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* fetchSAQFactoriesWorker() {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.FACTORIES.GET_LIST_BY_ORGANISATION_ID.replace(
    "<ORGANISATION_ID>",
    organisationId
  );

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  const params = { limit: 999, offset: 0 }; // Adjust as needed

  try {
    const startTime = performance.now();
    yield put(loading(true));
    const response = yield axios.get(requestURL, { headers, params });
    const endTime = performance.now();
    const duration = endTime - startTime;
    console.log(`API call took ${duration.toFixed(2)} milliseconds`);

    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(loading(false));

        // Filter factories with factoryStatus "saq"
        const saqFactories = response.data.results.factories.filter(
          (factory) =>
            factory.factoryStatus &&
            factory.factoryStatus.trim().toLowerCase() === "saq"
        );

        console.log("Filtered SAQ Factories:", saqFactories);

        yield put(setSAQFactories(saqFactories));
      }
    }
  } catch (error) {
    console.error("Failed to fetch SAQ factories:", error);
    if (error && error.response && error.response.status === 401) {
      yield put(logout());
    }
    // Handle errors here, possibly dispatching a failure action
  }
}

function* createNewFactoryFromSAQWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);
  const { factoryId, supplierName, metadata, existingSupplierId } =
    action.payload;

  try {
    yield put(loading(true));
    let supplierId;
    if (existingSupplierId) {
      supplierId = existingSupplierId;

      // First get current supplier data
      yield put(getSupplierById(supplierId));
      // Wait for supplier data to be fetched
      const supplierAction = yield take(setSupplierData.type);
      const suppliersArray = supplierAction.payload;
      if (!Array.isArray(suppliersArray) || suppliersArray.length === 0) {
        throw new Error("Failed to fetch supplier data");
      }
      const supplierData = suppliersArray[0];
      const currentFacilityCount = supplierData.metadata?.facilityCount || 0;
      const supplierName = supplierData?.name;
      const changes = {
        facilityCount: currentFacilityCount + 1,
      };

      //console.log("Updating supplier with changes:", changes);
      yield put(updateSupplierData({ supplierId, changes }));
      // Wait and verify update
      const updateAction = yield take(setSupplierData.type);
      if (!updateAction.payload) {
        throw new Error("Failed to update supplier data");
      }
      //console.log("Supplier update result:", updateAction.payload);
    } else {
      // Create new supplier
      //console.log("Creating new supplier from SAQ");
      supplierId = yield call(createNewSupplierFromSAQWorker, {
        payload: {
          supplierName,
          metadata: {
            ...metadata,
            facilityCount: 1,
          },
        },
      });
      //console.log("New supplier ID", supplierId);
    }
    // Create new factory
    const requestURL = API.FACTORIES.CREATE_NEW_FROM_SAQ.replace(
      "<ORGANISATION_ID>",
      organisationId
    )
      .replace("<SUPPLIER_ID>", supplierId)
      .replace("<FACTORY_ID>", factoryId);

    const headers = {
      "Content-Type": "application/json",
      Authorization: authToken,
    };
    const body = {
      supplierName,
    };
    //console.log("Sending request from SAGA to create new factory from SAQ");
    const response = yield call(axios.post, requestURL, body, { headers });
    //const response = yield call(axios.post, requestURL, {}, { headers });

    if (response?.status === 200 && response.data?.success) {
      yield put(
        setFactoryCreationResult({
          success: true,
          data: {
            newFactory: response.data.results,
            saqId: factoryId,
            updatedSupplier: existingSupplierId
              ? {
                  supplierId,
                  facilityCount: response.data.results.metadata?.facilityCount,
                }
              : null,
          },
        })
      );

      // yield put(fetchSAQFactories());
      // yield put(getListOfAllFactories({
      //   limit: 10,
      //   offset: 0,
      //   page: 1,
      //   search: '',
      //   supplierId: null,
      //   supplierFactoryList: false
      // }));
    } else {
      throw new Error("Failed to create new factory from SAQ");
    }
  } catch (error) {
    console.error("Failed to create new factory from SAQ:", error);
    yield put(
      setFactoryCreationResult({
        success: false,
        error: error.message,
      })
    );
  }
}

function createUploadFileChannel({
  requestURL,
  formData,
  headers,
  params,
  fileId,
}) {
  return eventChannel((emitter) => {
    const source = axios.CancelToken.source();

    axios
      .post(requestURL, formData, {
        headers,
        params,
        cancelToken: source.token,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          emitter({ progress, fileId });
        },
      })
      .then((response) => {
        emitter({ success: true, response, fileId });
        emitter(END);
      })
      .catch((error) => {
        emitter({ error, fileId });
        emitter(END);
      });

    // Unsubscribe function
    return () => {
      source.cancel();
    };
  });
}

function* uploadFactoryFileWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.FACTORIES.UPLOAD_FILE.replace(
    "<ORGANISATION_ID>",
    organisationId
  )
    .replace("<SUPPLIER_ID>", action.payload.supplierId)
    .replace("<FACTORY_ID>", action.payload.factoryId);

  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: authToken,
  };
  if (Array.isArray(action.payload.file)) {
    let fileUpload = {};
    let allUploadedFiles = [];

    for (let file of action.payload.file) {
      const formData = new FormData();
      formData.append("file", file.file);

      try {
        const uploadChannel = yield call(createUploadFileChannel, {
          requestURL,
          formData,
          headers,
          params: { fieldKey: action.payload.fieldKey },
          fileId: file.id, // Pass the file ID
        });

        while (true) {
          const { progress, success, response, error, fileId } = yield take(
            uploadChannel
          );

          if (progress !== undefined) {
            // Dispatch progress update
            yield put({
              type: "factory/updateUploadProgress",
              payload: {
                fileId,
                progress,
              },
            });
          } else if (success) {
            // Dispatch success status
            yield put({
              type: "factory/updateUploadStatus",
              payload: {
                fileId,
                status: "success",
              },
            });

            fileUpload[fileId] = true;

            // Get uploaded files from response
            const uploadedFiles = _.get(
              response.data.results,
              action.payload.fieldKey
            );
            if (uploadedFiles) {
              allUploadedFiles = allUploadedFiles.concat(uploadedFiles);

              // Update state immediately after each successful upload
              yield put(
                setUploadFactoryFiles({
                  fieldKey: action.payload.fieldKey,
                  files: uploadedFiles,
                })
              );
            }
            break;
          } else if (error) {
            // Dispatch error status
            yield put({
              type: "factory/updateUploadStatus",
              payload: {
                fileId,
                status: "error",
              },
            });

            fileUpload[fileId] = false;
            console.error("Failed to upload file:", error);
            break;
          }
        }
      } catch (error) {
        fileUpload[file.id] = false;
        console.error("Failed to upload file:", error);
      }
    }

    // Update upload status
    yield put(setFilesUploadedIds(fileUpload));

    // Call success callback if all files uploaded successfully
    if (Object.values(fileUpload).every((status) => status === true)) {
      if (action.payload.onSuccess) {
        action.payload.onSuccess(allUploadedFiles);
      }
    }
  } else {
    const formData = new FormData();
    formData.append("file", action.payload.file);

    try {
      const response = yield axios.post(requestURL, formData, {
        headers,
        params: { fieldKey: action.payload.fieldKey },
      });

      if (response && response.status === 200 && response.data.success) {
        const uploadedFiles = _.get(
          response.data.results,
          action.payload.fieldKey
        );

        yield put(
          setUploadFactoryFiles({
            fieldKey: action.payload.fieldKey,
            files: uploadedFiles,
          })
        );

        // Call success callback for single file upload
        if (action.payload.onSuccess) {
          action.payload.onSuccess(uploadedFiles);
        }
      }
    } catch (error) {
      console.error("Failed to upload file:", error);
      if (error?.response?.status === 401) {
        yield put(logout());
      }
    }
  }
}
//   if (Array.isArray(action.payload.file)) {
//     let fileUpload = {};

//     for (let file of action.payload.file) {
//       fileUpload[file.id] = false;

//       const formData = new FormData();
//       formData.append("file", file.file);

//       try {
//         const response = yield axios.post(requestURL, formData, {
//           headers,
//           params: { fieldKey: action.payload.fieldKey }
//         });

//         if (response && response.status === 200 && response.data.success) {
//           fileUpload[file.id] = true;
//           yield put(setUploadFactoryFiles({
//             fieldKey: action.payload.fieldKey,
//             files: response.data.results[action.payload.fieldKey]
//           }));
//         }
//       } catch (error) {
//         console.error("Failed to upload file:", error);
//         if (error?.response?.status === 401) {
//           yield put(logout());
//         }
//       }
//     }
//     yield put(setFilesUploadedIds(fileUpload));
//   } else {
//     formData.append("file", action.payload.file);

//     try {
//       const response = yield axios.post(requestURL, formData, {
//         headers,
//         params: { fieldKey: action.payload.fieldKey }
//       });

//       if (response && response.status === 200 && response.data.success) {
//         yield put(setUploadFactoryFiles({
//           fieldKey: action.payload.fieldKey,
//           files: response.data.results[action.payload.fieldKey]
//         }));
//       }
//     } catch (error) {
//       console.error("Failed to upload file:", error);
//       if (error?.response?.status === 401) {
//         yield put(logout());
//       }
//     }
//   }
// }

function* createFactoryWorker(action) {
  const authToken = yield select(selectAuthToken);
  const organisationId = yield select(selectOrganisationId);

  const requestURL = API.FACTORIES.CREATE_FACTORY.replace(
    "<ORGANISATION_ID>",
    organisationId
  ).replace("<SUPPLIER_ID>", action.payload.supplierId);

  const headers = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  // Helper to build nested object structure
  const buildNestedStructure = (obj) => {
    const result = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (key.includes(".")) {
        const parts = key.split(".");
        let current = result;

        parts.forEach((part, index) => {
          if (index === parts.length - 1) {
            current[part] = value;
          } else {
            current[part] = current[part] || {};
            current = current[part];
          }
        });
      } else {
        result[key] = value;
      }
    });

    return result;
  };

  // Build nested structure and extract metadata
  const nestedData = buildNestedStructure(action.payload);
  const {
    name,
    supplierId,
    organisationId: orgId,
    ...metadataFields
  } = nestedData;

  const factoryObj = {
    name: name,
    organisationId: organisationId,
    supplierId: supplierId,
    metadata: metadataFields,
    factoryStatus: "live",
  };

  try {
    const response = yield axios.post(requestURL, factoryObj, { headers });
    if (response && response.status === 200 && response.data) {
      if (response.data.success) {
        yield put(setFactoryData(response.data.results));
        // Refresh the factory list after successful creation
        yield put(
          getListOfAllFactories({
            limit: 999,
            offset: 0,
          })
        );
      }
    }
  } catch (error) {
    console.error("Failed to create factory:", error);
    if (error?.response?.status === 401) {
      yield put(logout());
    }
  }
}

function* getSignedUrlWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const { key } = action.payload;

    const response = yield axios.get(API.FACTORIES.GET_SIGNED_URL, {
      params: { key },
      headers: {
        Authorization: authToken,
      },
    });
    if (response.data.success) {
      yield put(setSignedUrl({ key, url: response.data.url }));
      //console.log("SIGNED URL", response.data.url);
      // Open URL in new tab
      window.open(response.data.url, "_blank");
    }
  } catch (error) {
    console.error("Failed to get signed URL:", error);
  }
}

function* getDueActionCerticatesDataWorker(action) {
  try {
    const authToken = yield select(selectAuthToken);
    const orgainsationId = yield select(selectOrganisationId);
    const requestURL = API.FACTORIES.GET_DUE_ACTION_CERTIFICATES_DATA.replace(
      "<ORGANISATION_ID>",
      orgainsationId
    );

    const headers = {
      Authorization: authToken,
      "Content-Type": "application/json",
    };
    const response = yield axios.get(requestURL, {
      headers,
    });
    if (response.data.success) {
      yield put(setDueActionCerticatesData(response.data.results));
    }
  } catch (error) {
    console.error("Failed to get due action certificates data:", error);
  }
}

function* watchFactorySaga() {
  yield takeLatest(
    [getListOfAllFactories.type, getSearchResultOfFactories.type],
    getListOfFactoriesWorker
  );
}

function* watchFactoryByIdSaga() {
  yield takeLatest(getFactoryById.type, getFactoryByIdWorker);
}

function* watchUpdateFactoryDataSaga() {
  yield takeLatest(updateFactoryData.type, updateFactoryDataWorker);
}

function* watchCreateFactorySaga() {
  yield takeLatest(createFactory.type, createFactoryWorker);
}

function* watchFetchSAQFactoriesSaga() {
  yield takeLatest(fetchSAQFactories.type, fetchSAQFactoriesWorker);
}

function* watchCreateNewFactoryFromSAQSaga() {
  yield takeLatest(createNewFactoryFromSAQ.type, createNewFactoryFromSAQWorker);
}

function* watchUploadFactoryFileSaga() {
  yield takeLatest(uploadFactoryFile.type, uploadFactoryFileWorker);
}

function* watchGetSignedUrl() {
  yield takeLatest("factory/getSignedUrl", getSignedUrlWorker);
}
function* watchGetDueActionCerticatesDataSaga() {
  yield takeLatest(
    getDueActionCerticatesData.type,
    getDueActionCerticatesDataWorker
  );
}

export default function* rootSaga() {
  yield all([
    watchFactorySaga(),
    watchFactoryByIdSaga(),
    watchUpdateFactoryDataSaga(),
    watchCreateFactorySaga(),
    watchFetchSAQFactoriesSaga(),
    watchCreateNewFactoryFromSAQSaga(),
    watchUploadFactoryFileSaga(),
    watchGetSignedUrl(),
    watchGetDueActionCerticatesDataSaga(),
  ]);
}
