import React, { useEffect, useState, useCallback } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

import {
  Box,
  Typography,
  Button,
  Divider,
  Drawer,
  Skeleton,
} from "@mui/material";
import TopBreadcrumbs from "../topBreadcrumbs.component";
import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { selectOrganisationId, selectUserInfo } from "../../selectors/login.selector";
import {
  selectSearchCount,
  selectSearchResultsSupplierList,
  selectSupplierCache,
  selectSupplierList,
  selectTotalCount,
} from "../../selectors/supplier.selector";
import { selectLoadingState } from "../../selectors/misc.selector";
import CreateSupplierForm from "../createSupplier.component";

const SupplierTableDataGrid = () => {
  const dispatch = useDispatch();
  const suppliersList = useSelector(selectSupplierList);
  const isLoading = useSelector(selectLoadingState);
  const userInfo = useSelector(selectUserInfo);
  const navigateToSupplierDetails = (supplierId) => {
    dispatch(getSupplierById(supplierId));
    navigate(`${location.pathname}/${supplierId}`);
  };
  const organisationId = useSelector(selectOrganisationId);
  const rows = suppliersList.map((supplier) => {
    return {
      id: supplier?.supplierId,
      supplierName: supplier?.name,
      supplierIdFromMetadata: supplier?.metadata?.uniqueSupplierId || "–",
      country: supplier?.country,
      totalFactoryCount: supplier?.factoryData?.totalFactories || "-",
      tier: supplier?.metadata.tier || "-",
      tier1: supplier?.factoryData?.tier1 || "-",
      tier2: supplier?.factoryData?.tier2 || "-",
      tier1Factories: supplier?.factoryData?.tier1Factories || "-",
      tier2Factories: supplier?.factoryData?.tier2Factories || "-",
      parentCompanyName: supplier?.metadata?.parentCompanyName || "-",
      actionAlert: supplier?.actionAlert,
      primaryContact: supplier?.metadata?.primarySupplierContactPerson?.name || "-",
      primaryContactEmail: supplier?.metadata?.primarySupplierContactPerson?.email || "-",
    };
  });
  
  const copyToClipboard = () => {
    navigator.clipboard.writeText("https://q-auditsense-ref-supplier-1086719075712.us-central1.run.app/" + organisationId)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const columns = [
    { field: "supplierName", headerName: "Supplier Name", flex: 1, minWidth: 180, align: "left", headerAlign: "left" },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      minWidth: 120,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "tier",
      headerName: "Type",
      flex: 1,
      minWidth: 100,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "primaryContact",
      headerName: "Primary Contact",
      flex: 1,
      minWidth: 160,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "primaryContactEmail",
      headerName: "Primary Contact Email",
      flex: 1,
      minWidth: 190,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "totalFactoryCount",
      headerName: "Total Facility Count",
      type: "number",
      flex: 1,
      minWidth: 80,
      align: "left",
      headerAlign: "left",
    },
    // {
    //   field: "tier1",
    //   headerName: "Tier 1",
    //   type: "number",
    //   flex: 1,
    //   minWidth: 120,
    //   align: "left",
    //   headerAlign: "left",
    // },
    // {
    //   field: "tier2",
    //   headerName: "Tier 2",
    //   type: "number",
    //   flex: 1,
    //   minWidth: 120,
    //   align: "left",
    //   headerAlign: "left",
    // },
    {
      field: "tier1Factories",
      headerName: "Tier 1 Facilities",
      flex: 1,
      minWidth: 260,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        // Check if we have tier1Factories as an array
        const factories = params.value;
        
        if (!factories || factories === "-" || factories.length === 0) {
          return "";
        }
        
        // If we have factories, display them as links
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {factories.map((factory) => (
              <Box key={factory.factoryId} sx={{ display: "flex", alignItems: "center" }}>
                <a
                  href={`/suppliers/${params.row.id}/factory/${factory.factoryId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#4C5ED9",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  {factory.name}
                </a>
                <img
                  style={{ paddingLeft: 8 }}
                  src="/ne_arrow.svg"
                  alt="arrow icon"
                />
              </Box>
            ))}
          </Box>
        );
      }
    },
    {
      field: "tier2Factories",
      headerName: "Tier 2 Facilities",
      flex: 1,
      minWidth: 260,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => {
        // Check if we have tier2Factories as an array
        const factories = params.value;
        
        if (!factories || factories === "-" || factories.length === 0) {
          return "";
        }
        
        // If we have factories, display them as links
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {factories.map((factory) => (
              <Box key={factory.factoryId} sx={{ display: "flex", alignItems: "center" }}>
                <a
                  href={`/suppliers/${params.row.id}/factory/${factory.factoryId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#4C5ED9",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  {factory.name}
                </a>
                <img
                  style={{ paddingLeft: 8 }}
                  src="/ne_arrow.svg"
                  alt="arrow icon"
                />
              </Box>
            ))}
          </Box>
        );
      }
    },
    // {
    //   field: "actionAlert",
    //   headerName: "Action Alert",
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (params) => (
    //     <Chip
    //       label={params.row.actionAlert}
    //       sx={{
    //         borderRadius: "16px",
    //         fontWeight: 500,
    //         fontSize: 12,
    //         backgroundColor:
    //           params.row.actionAlert === "Yes" ? "#FECDCA" : "#F2F4F7",
    //         color: params.row.actionAlert === "Yes" ? "#B42318" : "default",
    //         cursor: "pointer",
    //       }}
    //     />
    //   ),
    // },
    {
      field: ".",
      headerName: "",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            // console.log("params.row.supplierId", params.row);
            navigateToSupplierDetails(params.row.id);
          }}
          sx={{
            backgroundColor: "#6172F3",
            color: "white",
            "&:hover": {
              backgroundColor: "#5667e2",
              fontWeight: "bold",
            },
            textTransform: "none",
            width: "79px",
            height: "40px",
            borderRadius: "8px",
          }}
          size="small"
          disabled={userInfo?.role === "sourcing" || userInfo?.role === "reporting"}
        >
          Details
        </Button>
      ),
    },
  ];


  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const totalCount = useSelector(selectTotalCount);
  const searchResults = useSelector(selectSearchResultsSupplierList);
  const searchCount = useSelector(selectSearchCount);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSuppliers, setFilteredSuppliers] = useState([]);
  const [count, setCount] = useState(totalCount);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const supplierCache = useSelector((state) =>
    selectSupplierCache(state, page)
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm !== "") {
      setCount(searchCount);
    } else {
      setCount(totalCount);
    }
  }, [totalCount, searchCount]);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredSuppliers(searchResults);
    } else {
      setFilteredSuppliers(suppliersList);
    }
  }, []);

  useEffect(() => {
    if (supplierCache) {
      setFilteredSuppliers(supplierCache);
    } else {
      const limit = 8;
      const offset = (page - 1) * limit;
      dispatch(getListOfAllSuppliers({ page, limit, offset }));
    }
  }, [page, supplierCache, dispatch]);

  const location = useLocation();

  return (
    <Box sx={{ p: 3 }}>
      <TopBreadcrumbs />
      <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
        <div>
          <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
            Suppliers
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
          >
            Manage your Suppliers here
          </Typography>
        </div>
        <div>
        <Button
            variant="outlined"
            onClick={copyToClipboard}
            sx={{
              color: "#6172F3",
              "&:hover": {
                color: "white",
                backgroundColor: "#5667e2",
              },
              textTransform: "none",
              width: "230px",
              height: "40px",
              borderRadius: "8px",
              mr: "24px",
            }}
          >
            Supplier Presourcing Form
            <img
              style={{ paddingLeft: 8 }}
              src="/ne_arrow.svg"
              alt="arrow icon"
            />
          </Button>
        <Button
          variant="outlined"
          onClick={() => { setIsDrawerOpen(true) }}
          sx={{
            color: "#6172F3",
            "&:hover": {
              color: "white",
              backgroundColor: "#5667e2",
            },
            textTransform: "none",
            width: "200px",
            height: "40px",
            borderRadius: "8px",
            mr: "48px",
          }}
        >
          Create New Supplier
        </Button>
      </div>
      </div>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
        {/* <TextField
          label="Search supplier"
          value={searchTerm}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src="./search.svg" alt="Search" />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>
      <div style={{ width: "100%", padding: "24px 48px" }}>
        <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
          {isLoading ? (
            <Box sx={{ width: "100%" }}>
              {[...Array(10)].map((_, index) => (
                <Skeleton
                  key={index}
                  height={60}
                  animation="wave"
                  sx={{ my: 1 }}
                />
              ))}
            </Box>
          ) : (
          <DataGridPro
            {...dataGridFormat}
            disableColumnSelector
            disableDensitySelector
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            getRowHeight={() => "auto"}
            sx={{
              borderRadius: "8px",
              padding: "16px",
              "& .MuiDataGrid-cell": {
                padding: "8px",
              },
              "& .MuiDataGrid-row": {
                maxHeight: "none !important",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f0f0f0", // Light gray background
                color: "#333", // Dark text color
                fontWeight: "bold",
              },
              "& .MuiDataGrid-columnHeader": {
                padding: "16px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
          )}
        </div>
      </div>
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => { setIsDrawerOpen(false) }}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            maxHeight: "98%",
            width: "90%",
            margin: "0 auto",
            display: "flex",
            justifySelf: "center",
          },
        }}
      >
        <CreateSupplierForm handleDrawerClose={handleDrawerClose} />
      </Drawer>
    </Box>
  );
};

export default SupplierTableDataGrid;
