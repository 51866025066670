import { createSlice } from "@reduxjs/toolkit";
import { logout } from "./login.reducer";
const initialState = {
  factories: [],
  saqFactories: [],
  searchResults: [],
  searchCount: null,
  factoryCache: {},
  count: null,
  factoryPageTab: "status",
  factoryData: null,
  uploadProgress: {},
  uploadStatus: {},
  dueActionCerticatesData: [],
};

const factorySlice = createSlice({
  name: "factory",
  initialState,
  reducers: {
    getListOfAllFactories: (state) => {},
    getSearchResultOfFactories: (state) => {},
    getFactoryById: (state) => {},
    updateFactoryData: (state, action) => {},
    createFactory: (state, action) => {},
    setListOfAllFactories: (state, action) => {
      state.factories = action.payload.factories || state.factories;
      state.searchResults = action.payload.searchResults || state.searchResults;
      state.count = action.payload.factoryCount || state.count;
      state.searchCount = action.payload.searchCount || state.searchCount;

      state.searchCount = action.payload.searchCount || state.searchCount;
    },
    setFactoriesDataCache: (state, action) => {
      state.factoryCache[action.payload.page] = action.payload.results;
    },
    setSAQFactories: (state, action) => {
      state.saqFactories = action.payload;
    },
    fetchSAQFactories: (state) => {},
    clearFactories: (state) => {
      state.factories = [];
      state.count = null;
    },
    setFactoryPageTab: (state, action) => {
      state.factoryPageTab = action.payload;
    },
    setFactoryData: (state, action) => {
      state.factoryData = action.payload;
      // state.updateStatus = 'succeeded'; // Indicate successful update
      // state.updateError = null;
    },
    // updateFactoryDataRequest: (state) => {
    //   state.updateStatus = 'loading';
    //   state.updateError = null;
    // },
    // updateFactoryDataFailure: (state, action) => {
    //   state.updateStatus = 'failed';
    //   state.updateError = action.payload; // Capture error message
    // },
    createNewFactoryFromSAQ: (state) => {
      state.loading = true;
      state.error = null;
    },
    setFactoryCreationResult: (state, action) => {
      const { success, data, error } = action.payload;
      state.loading = false;
      console.log("Factory creation result", { success, data, error });

      if (success) {
        // Add the new factory to factories list
        //state.factories = [...state.factories, data.newFactory];
        state.factories = state.factories
          ? [...state.factories, data.newFactory]
          : [data.newFactory];

        // // Safely update SAQ factories
        // state.saqFactories = state.saqFactories.map(saq =>
        //   saq._id === data.saqId
        //     ? { ...saq,
        //       assignedToFactory: data.newFactory.factoryId,
        //       metadata: {
        //       ...saq.metadata,
        //       supplierName: data.newFactory.metadata.supplierName
        //     }
        //   }
        //   : saq
        // );
        if (state.saqFactories && Array.isArray(state.saqFactories)) {
          state.saqFactories = state.saqFactories.map((saq) =>
            saq._id === data.saqId
              ? {
                  ...saq,
                  assignedToFactory: data.newFactory._id,
                  metadata: {
                    ...saq.metadata,
                    supplierName: data.newFactory.metadata?.supplierName,
                  },
                }
              : saq
          );
        }
      } else {
        state.error = error;
      }
    },

    uploadFactoryFile: (state, action) => {},
    setUploadFactoryFiles: (state, action) => {
      const { fieldKey, files } = action.payload;
      if (state.factoryData) {
        // Ensure the field exists and is an array
        const currentFiles = state.factoryData[fieldKey] || [];
        state.factoryData[fieldKey] = Array.isArray(currentFiles)
          ? [...currentFiles, ...files]
          : files;
      }
    },
    setSignedUrl: (state, action) => {
      state.signedUrls = {
        ...state.signedUrls,
        [action.payload.key]: action.payload.url,
      };
    },
    updateUploadProgress: (state, action) => {
      const { fileId, progress } = action.payload;
      state.uploadProgress[fileId] = progress;
    },

    updateUploadStatus: (state, action) => {
      const { fileId, status } = action.payload;
      state.uploadStatus[fileId] = status;
    },
    getDueActionCerticatesData: (state) => {},
    setDueActionCerticatesData: (state, action) => {
      state.dueActionCerticatesData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => initialState);
  },
});

export const {
  getListOfAllFactories,
  getSearchResultOfFactories,
  setListOfAllFactories,
  setFactoriesDataCache,
  setSAQFactories,
  fetchSAQFactories,
  clearFactories,
  setFactoryPageTab,
  getFactoryById,
  setFactoryData,
  updateFactoryData,
  createFactory,
  createNewFactoryFromSAQ,
  setFactoryCreationResult,
  uploadFactoryFile,
  setUploadFactoryFiles,
  setSignedUrl,
  getDueActionCerticatesData,
  setDueActionCerticatesData,

  // updateFactoryDataRequest,
  // updateFactoryDataFailure,
} = factorySlice.actions;

export default factorySlice.reducer;
