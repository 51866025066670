import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
  IconButton,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { selectFactoryData } from "../../selectors/factory.selector";
import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import { selectEditModeState } from "../../selectors/misc.selector";
import FactoryInformationEditModal from "./EditModals/editFactoryInformation.component";
import FacilityInformationDrawer from "./facilityInformationDrawer.component";
import FacilityTierMapDrawer from "./facilityTierMapDrawer.component";
import { getFactoryById } from "../../reducers/factory.reducer";
import { ComponentStyles, FacilityStyles, AuditStyles } from "../../styles";

const FacilityInformation = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditingInternal, setIsEditingInternal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTierMapDrawerOpen, setIsTierMapDrawerOpen] = useState(false);

  const editMode = useSelector(selectEditModeState);
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );

  useEffect(() => {
    const loadFactoryData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        if (!selectedFactoryData) {
          await dispatch(
            getFactoryById({
              supplierId: params.supplierId,
              factoryId: params.factoryId,
            })
          );
        } else {
          setFactoryData(selectedFactoryData);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadFactoryData();
  }, [selectedFactoryData, params.supplierId, params.factoryId, dispatch]);

  useEffect(() => {
    if (selectedFactoryData) {
      setFactoryData(selectedFactoryData);
    }
  }, [selectedFactoryData]);

  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const toggleTierMapDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsTierMapDrawerOpen(open);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!factoryData) {
    return <div>No facility data available.</div>;

  }


  console.log("factoryData:", factoryData);
  return (
    <>
      <Card style={FacilityStyles.containerCard} sx={{ minHeight: "305px" }}>
        <CardContent>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Grid item sx={{ alignContent: "center" }}>
              <Typography sx={FacilityStyles.headerOfTheCard}>
                General Facility Information
              </Typography>
              {editMode && (
                <EditIcon
                  fontSize={"2"}
                  sx={ComponentStyles.editButton}
                  onClick={toggleEditInternal}
                />
              )}
            </Grid>
            <Grid item>
              {/* <Button
                variant="outlined"
                onClick={toggleDrawer(true)}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color:"white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "150px",
                  height: "40px",
                  borderRadius: "8px",
                  mr: 1
                }}
              >
                More Information
              </Button> */}

              <Button
                variant="outlined"
                onClick={toggleTierMapDrawer(true)}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  width: "100px",
                  height: "40px",
                  borderRadius: "8px",
                  mr: "-24px",
                }}
              >
                Tier Map
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Grid item>
              <Typography>
                <span style={ComponentStyles.fieldName}>Suppliers:</span>{" "}
                <Link
                  to={`/suppliers/${factoryData?.supplierId}`}
                  style={FacilityStyles.linkText}
                >
                  {factoryData?.supplierName || supplierName || "-"}
                  <img
                    style={{ paddingLeft: 8 }}
                    src="/ne_arrow.svg"
                    alt="arrow icon"
                  />
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={ComponentStyles.fieldName}> Tier</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.tier?.split(' ')[1] || '-'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>
                Product Categories
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.productCategories || "-"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <span style={ComponentStyles.fieldName}>Facility ID:</span>{" "}
                <span style={ComponentStyles.fieldValue}>
                  {factoryData?.metadata?.factoryId || "-"}
                </span>
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>
                Compliance Status
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.complianceStatus || "-"}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>
                Supply Chain Status
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.supplyChainStatus || "-"}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>
                {" "}
                Vendor Rating
              </Typography>
              <Box
                sx={{
                  display: "inline-block",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  backgroundColor: (() => {
                    const rating =
                      factoryData?.latestAudit?.risk?.auditRatingAfterCAP?.toLowerCase() ||
                      factoryData?.latestAudit?.risk?.auditRating?.toLowerCase() ||
                      factoryData?.metadata?.vendorRating?.toLowerCase();
                    if (rating?.includes("green")) {
                      return AuditStyles.auditRatingColors.green;
                    } else if (rating?.includes("yellow")) {
                      return AuditStyles.auditRatingColors.yellow;
                    } else if (rating?.includes("red")) {
                      return AuditStyles.auditRatingColors.red;
                    } else if (rating?.includes("orange")) {
                      return AuditStyles.auditRatingColors.orange;
                    } else {
                      return "transparent";
                    }
                  })(),
                  color: "black",
                  wordBreak: "break-word",
                }}
              >
                <Typography
                  sx={{
                    ...ComponentStyles.fieldName,
                    color: "black",
                  }}
                >
                  {
                  factoryData?.latestAudit?.risk?.auditRatingAfterCAP || factoryData?.latestAudit?.risk?.auditRating ||
                    factoryData?.metadata?.vendorRating ||
                    "-"}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <Typography sx={ComponentStyles.fieldName}>
                {" "}
                Facility Address
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.addressLine1 || "-"}
              </Typography>
            </Grid>
          </Grid>

          {/* Primary Contact Person */}
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            sx={{ mb: 3 }}
          >
            <Grid item xs={4} sx={{ pr: 1 }}>
              <Typography sx={ComponentStyles.fieldName}>
                Primary Contact Person
              </Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.primaryFacilityContactPerson?.name ||
                  "-"}
              </Typography>
            </Grid>
            <Grid item xs={2} sx={{ pr: 1 }}>
              <Typography sx={ComponentStyles.fieldName}>Position</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.primaryFacilityContactPerson?.position ||
                  "-"}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ pr: 1 }}>
              <Typography sx={ComponentStyles.fieldName}>Email</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.primaryFacilityContactPerson?.email || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3} sx={{ pr: 1 }}>
              <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
              <Typography
                sx={{
                  ...ComponentStyles.fieldValue,
                  wordBreak: "break-word",
                }}
              >
                {factoryData?.metadata?.primaryFacilityContactPerson?.phone || "-"}
              </Typography>
            </Grid>
          </Grid>

          {/* Accordion for Additional Contacts */}
          <Accordion sx={{ mb: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="additional-contacts-content"
              id="additional-contacts-header"
            >
              <Typography sx={ComponentStyles.fieldName}>
                Click to see more contact
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* SC Contact Person */}
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                sx={{ mb: 3 }}
              >
                <Grid item xs={3.3} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>
                    SC Contact Person
                  </Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.socialComplianceContactPerson
                      ?.name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>
                    Position
                  </Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.socialComplianceContactPerson?.position || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>Email</Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.socialComplianceContactPerson?.email ||
                      "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.socialComplianceContactPerson?.phone ||
                      "-"}
                  </Typography>
                </Grid>
              </Grid>

              {/* MS Contact Person */}
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                sx={{ mb: 3 }}
              >
                <Grid item xs={3.3} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>
                    MS Contact Person
                  </Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.materialsSustainabilityContactPerson
                      ?.name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>
                    Position
                  </Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.materialsSustainabilityContactPerson?.position || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>Email</Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.materialsSustainabilityContactPerson?.email ||
                      "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.materialsSustainabilityContactPerson?.phone ||
                      "-"}
                  </Typography>
                </Grid>
              </Grid>

              {/* EHS Contact Person */}
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                sx={{ mb: 3 }}
              >
                <Grid item xs={3.3} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>
                    EHS Contact Person
                  </Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.ehsContactPerson?.name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={2} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>
                    Position
                  </Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.ehsContactPerson?.position || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>Email</Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.ehsContactPerson?.email || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ pr: 1 }}>
                  <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
                  <Typography
                    sx={{
                      ...ComponentStyles.fieldValue,
                      wordBreak: "break-word",
                    }}
                  >
                    {factoryData?.metadata?.ehsContactPerson?.phone || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
      <FacilityInformationDrawer
        factoryData={factoryData}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />
      <FactoryInformationEditModal
        open={isEditingInternal}
        onClose={() => setIsEditingInternal(false)}
        internalData={{ ...factoryData, supplierName }}
      />
      <FacilityTierMapDrawer
        factoryData={factoryData}
        isDrawerOpen={isTierMapDrawerOpen}
        toggleDrawer={toggleTierMapDrawer}
      />
    </>
  );
};

export default FacilityInformation;
