import moment from "moment";

export const getValidData = (data) => {
  return data === "-" || data == null ? "" : data;
};


export const checkInternalEmail = (email) => {
  return email.includes("esger") || email.includes("elm");
};

const computeDueInDays = (date) => {
  return moment(date).diff(moment(), "days");
};

