import React, { useEffect, useState } from "react";
import { Grid, Box, Divider, Typography, Card, Tabs, Tab } from "@mui/material";
import { useSelector } from "react-redux";

import TopBreadcrumbs from "../components/topBreadcrumbs.component";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import { PageStyles } from "../styles";
import SupplierMetaDataGrid from "../components/InsightDetails/supplierMetaDataGrid.component";
import FacilityMetaDataGrid from "../components/InsightDetails/facilityMetaDataGrid.component";
import InsightDashboard from "../components/InsightDetails/insightDashboard.component";
import RiskAnalytics from "../components/InsightDetails/riskAnalytics.component";
import { selectUserInfo } from "../selectors/login.selector";

const Insights = () => {
  const [selectedTab, setSelectedTab] = useState("dataAnalytics");
  const userInfo = useSelector(selectUserInfo);
  const isReportingTeam = userInfo?.role === "reporting";

  const handleTabChange = (event, newValue) => {
    // Prevent reporting team from selecting restricted tabs
    if (
      isReportingTeam &&
      (newValue === "supplierDataTable" || newValue === "siteDataTable")
    ) {
      return;
    }
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box sx={{ flex: 1, overflowY: "hidden" }}>
        <Box
          sx={{
            ...PageStyles.containerBoxExceptNavbar,
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: "white",
          }}
        >
          <Box sx={PageStyles.firstSectionWithBreadcrumbsAndName}>
            <TopBreadcrumbs />
            <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
              Insight
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
            >
              Insight for your supply chain
            </Typography>
          </Box>
          <Divider />
        </Box>

        <Box sx={{ flex: 1, pt: 3, px: 5 }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="insight tabs"
            variant="fullWidth"
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
                flexGrow: 1,
                maxWidth: "none",
              },
              "& .Mui-selected": {
                backgroundColor: "rgba(13, 71, 161, 0.04)", // 0D47A1 with 4% opacity
              },
            }}
          >
            <Tab label="Data Analytics" value="dataAnalytics" />
            <Tab label="Risk" value="risk" />
            <Tab
              label="Supplier Data Table"
              value="supplierDataTable"
              disabled={isReportingTeam}
              sx={
                isReportingTeam
                  ? {
                      opacity: 0.5,
                      cursor: "not-allowed",
                      "&:hover": {
                        color: "text.primary",
                      },
                    }
                  : {}
              }
            />
            <Tab
              label="Facility Data Table"
              value="siteDataTable"
              disabled={isReportingTeam}
              sx={
                isReportingTeam
                  ? {
                      opacity: 0.5,
                      cursor: "not-allowed",
                      "&:hover": {
                        color: "text.primary",
                      },
                    }
                  : {}
              }
            />
          </Tabs>

          <Box>{selectedTab === "dataAnalytics" && <InsightDashboard />}</Box>
          <Box sx={{ mt: 5 }}>
            {selectedTab === "risk" && <RiskAnalytics />}
            {selectedTab === "supplierDataTable" && <SupplierMetaDataGrid />}
            {selectedTab === "siteDataTable" && <FacilityMetaDataGrid />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Insights;
