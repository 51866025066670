import React, { useState, useEffect, } from 'react';
import { TextField, Button, List, ListItem, ListItemText, IconButton, Box, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo } from "../selectors/login.selector";
import { selectFactoryData } from "../selectors/factory.selector";
import { updateFactoryData } from "../reducers/factory.reducer";
import { updateSupplierData } from '../reducers/supplier.reducer';
import axios from 'axios';
import { selectSupplierData } from '../selectors/supplier.selector';

const CommentSection = ({ source, type }) => {
    const userInfo = useSelector(selectUserInfo);
    const factoryInfo = useSelector(selectFactoryData);
    const supplierInfo = useSelector(selectSupplierData);
    const dispatch = useDispatch();

    const [comment, setComment] = useState('');
    const [comments, setComments] = useState(() => {
        const selectedInfo = source === "supplier" ? supplierInfo : factoryInfo;
        return Array.isArray(selectedInfo?.metadata?.internalStatusNotes)
            ? selectedInfo.metadata.internalStatusNotes
            : [{ author: "Previously Migrated Note", comment: selectedInfo?.metadata?.internalStatusNotes, createdAt: "" }];
    });

    useEffect(() => {
        if (source === 'supplier') {
            setComments(Array.isArray(supplierInfo?.metadata?.internalStatusNotes) ? supplierInfo?.metadata?.internalStatusNotes : [{ "author": "Previously Migrated Note", "comment": supplierInfo?.metadata?.internalStatusNotes, "createdAt": "" }]);
        } else {
            setComments(Array.isArray(factoryInfo?.metadata?.internalStatusNotes) ? factoryInfo?.metadata?.internalStatusNotes : [{ "author": "Previously Migrated Note", "comment": factoryInfo?.metadata?.internalStatusNotes, "createdAt": "" }]);
        }
    }, [supplierInfo?.metadata?.internalStatusNotes, factoryInfo?.metadata?.internalStatusNotes]);

    const handlePostComment = () => {
        if (source === 'supplier') {
            if (!comment) return;

            const currentDate = new Date();
            const isoString = currentDate.toISOString();
            const changes = { "internalStatusNotes": [{ "comment": comment, "author": userInfo.name, "createdAt": isoString }, ...comments] }

            if (Object.keys(changes).length > 0) {
                dispatch(
                    updateSupplierData({
                        supplierId: supplierInfo?._id,
                        changes,
                    })
                );
            }

            setComment('');
        } else {
            if (!comment) return;

            const currentDate = new Date();
            const isoString = currentDate.toISOString();
            const changes = { "metadata.internalStatusNotes": [{ "comment": comment, "author": userInfo.name, "createdAt": isoString }, ...comments] }

            if (Object.keys(changes).length > 0) {
                dispatch(
                    updateFactoryData({
                        organisationId: factoryInfo?.organisationId,
                        supplierId: factoryInfo?.supplierId,
                        factoryId: factoryInfo?._id,
                        changes,
                    })
                );
            }
            setComment('');
        }
    };

    return (
        <Box sx={{ width: "100%", margin: 'auto', pl: 2, pr: 2, fontFamily: "Inter, sans-serif" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Box
                        sx={{
                            height: 220,
                            width: '100%',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            border: '1px solid #ccc',
                            borderRadius: 1,
                            p: 1,
                            bgcolor: '#fafafa',
                        }}
                    >
                        <List>
                            {comments.map((commentItem, index) => (
                                <ListItem key={index} alignItems="flex-start" sx={{ paddingTop: "0px" }}>
                                    {commentItem.author === "Previously Migrated Note" ? <ListItemText
                                        primary={commentItem.comment}
                                        secondary={`${commentItem.author}`}
                                    /> : <ListItemText
                                        primary={commentItem.comment}
                                        secondary={`${commentItem.author} (${new Date(commentItem.createdAt).toLocaleString()})`}
                                    />}
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Grid>
                {type !== 'view' && (
                    <Grid item xs={12} md={4}>
                        <TextField
                            label="Your comment"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={5}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePostComment}
                            disabled={!comment}
                            sx={{ mt: 2 }}
                        >
                            Post
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default CommentSection;