import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Box,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import ErrorIcon from "@mui/icons-material/Error";
import NoteIcon from "@mui/icons-material/Note";
import CustomChipLabel from "./customChipLabel.component";
import AuditReport from "./auditReport.component";
import AuditReportLeftSideNavigationBar from "./auditReportDetailsLeftSideNavigationBar.component";
import AuditReportTabs from "./auditReportTabs.component";
import AuditReportDetails from "../../pages/auditReportDetails.page";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCapDetailsById,
  setSelectedAuditIssueId,
} from "../../reducers/audit.reducer";
import { selectAuditIssueCAPData, selectAuditData } from "../../selectors/audit.selector";
import DeleteIcon from "@mui/icons-material/Delete";
import { selectEditModeState } from "../../selectors/misc.selector";
const AuditsTable = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const handleClick = (id) => {
    dispatch(setSelectedAuditIssueId(id));
  };
  const handleDelete = (capDetailsId) => {
    dispatch(
      deleteCapDetailsById({
        organisationId: params.organisationId,
        supplierId: params.supplierId,
        factoryId: params.factoryId,
        auditId: params.auditId,
        capDetailsId: capDetailsId,
      })
    );
  };
  const editMode = useSelector(selectEditModeState);
  const issuesData = useSelector(selectAuditIssueCAPData);
  
  const selectedAuditData = useSelector(selectAuditData);
  const auditIsProcessed = selectedAuditData?.metadata?.severityCategories ? true : false;

  return (
    <>
      {auditIsProcessed ? (
        <Box
          elevation={1}
          sx={{
            p: 3,
            borderTop: "0px",
            px: 5,
          }}
        >
          <Table aria-label="issues table">
            <TableHead>
              <TableRow>
                <TableCell>Violation Type</TableCell>
                <TableCell>Non-conformance</TableCell>
                <TableCell sx={{ width: "12px" }}></TableCell>
                <TableCell sx={{ p: 0 }}>Category</TableCell>
                <TableCell sx={{ p: 0 }}>Severity</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {issuesData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={
                    index === issuesData.length - 1
                      ? { "&:last-child td, &:last-child th": { border: 0 } }
                      : {}
                  }
                >
                  <TableCell>{row.clientCodeTitle}</TableCell>
                  <TableCell
                    sx={{
                      wordBreak: "break-word",
                      mr: 0,
                      pr: 1,
                      maxWidth: "470px",
                    }}
                  >
                    {row.issue}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "16px",
                      cursor: "pointer",
                      paddingRight: "40px",
                      opacity: 0.5,
                    }}
                    onClick={() => {
                      handleClick(row.id);
                    }}
                  >
                    <IconButton
                      sx={{
                        color: "rgba(0, 0, 0, 0.6)",
                        padding: 0,
                        margin: 0,
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      <OpenInFullIcon className="icon" />
                      <Typography variant="caption" sx={{ ml: 1 }}>
                        See detail
                      </Typography>
                    </IconButton>
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    {row.subType}
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    <CustomChipLabel severity={row.severity} />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <CustomChipLabel cap={row.status} capLabel={row.status} />
                      {row.status == "Open" && (
                        <span
                          style={{
                            marginLeft: 8,
                            color: row.dueInDays <= 0 ? "red" : "inherit",
                          }}
                        >
                          {row.dueInDays <= 0
                            ? `Past Due ${-row.dueInDays?.toString()} Days`
                            : `Due in ${row.dueInDays?.toString()} Days`}
                        </span>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ p: 0 }}>
                    {editMode && (
                      <Button onClick={() => handleDelete(row.id)}>
                        <DeleteIcon sx={{ color: "gray" }} />
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {issuesData.length === 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
          <Typography sx={{
              fontWeight: 500,
              fontSize: 16,
              color: "#8B8D97",
            }}>
            No issues found
          </Typography>
        </Box>
      )}
        </Box>
      ) : (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="body1">
            Finding and CAP information will be available after audit is processed
          </Typography>
        </Box>
      )}

      {/* {selectedIssueId && <AuditReportDetails />} */}
    </>
  );
};

export default AuditsTable;
