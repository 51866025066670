import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Divider,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import { selectFactoryIssuesData } from "../../selectors/factory.selector";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  selectAuditIssuesData,
  selectAuditData,
} from "../../selectors/audit.selector";
import { updateAuditData } from "../../reducers/audit.reducer";
import { updateFactoryData } from "../../reducers/factory.reducer";
import { AuditStyles, ComponentStyles } from "../../styles";
const AuditIssuesTable = () => {
  const issuesData = useSelector(selectAuditIssuesData);
  const selectedAuditData = useSelector(selectAuditData);
  const auditIsProcessed = selectedAuditData?.metadata?.severityCategories
    ? true
    : false;

  const [filteredIssues, setFilteredIssues] = useState(
    issuesData.detailedIssues || []
  );
  useEffect(() => {
    setFilteredIssues(issuesData.detailedIssues || []);
  }, [issuesData.detailedIssues]);
  const [isSorted, setIsSorted] = useState(false);
  const handleSort = () => {
    const sortedData = [...filteredIssues].sort((a, b) => {
      if (isSorted) {
        return a.category.localeCompare(b.category);
      } else {
        return b.category.localeCompare(a.category);
      }
    });
    setFilteredIssues(sortedData);
    setIsSorted(!isSorted);
  };

  const handleFilter = (key) => {
    const filteredData = issuesData.detailedIssues.filter(
      (issue) => issue[key] > 0
    );
    setFilteredIssues(filteredData);
  };

  console.log("detailedIssues", issuesData.detailedIssues);

  const calculateAuditScore = (detailedIssues) => {
    const scoreDeductions = {
      Minor: 1,
      Moderate: 2,
      Major: 5,
      Critical: 30,
      ZT: 50,
    };
    let totalDeduction = 0;
    detailedIssues.forEach((issue) => {
      const deductionPerIssue = scoreDeductions[issue.category] || 0;
      const totalIssues = issue.open;
      totalDeduction += deductionPerIssue * totalIssues;
    });
    const score = 100 - totalDeduction;

    // Calculate grade based on score
    let grade;
    if (score >= 91) {
      grade = "Green (A)";
    } else if (score >= 71) {
      grade = "Yellow (B)";
    } else if (score >= 51) {
      grade = "Orange (C)";
    } else {
      grade = "Red (D)";
    }
    return {
      score: score.toString(),
      grade,
    };
  };

  const auditData = useSelector(selectAuditData);
  const dispatch = useDispatch();
  useEffect(() => {
    const recalculatedauditResult = calculateAuditScore(issuesData.detailedIssues);
    // Only dispatch if the calculated score is different from the current score
    if (auditData && recalculatedauditResult.score !== auditData?.risk?.auditScore) {
      dispatch(
        updateAuditData({
          organisationId: auditData?.organisationId,
          supplierId: auditData?.supplierId,
          factoryId: auditData?.factoryId,
          auditId: auditData?._id,
          changes: {
            auditScore: recalculatedauditResult.score,
            auditRating: recalculatedauditResult.grade
          },
        })
      );
    }
  }, [issuesData.detailedIssues, dispatch]);

  return (
    <Paper elevation={1} sx={AuditStyles.issuesTableContainerPaper}>
      {auditIsProcessed ? (
        <Box sx={{ height: 330, overflow: "auto" }}>
          <Table stickyHeader>
            <TableHead sx={{ zIndex: 1 }}>
              <TableCell
                sx={{
                  ...AuditStyles.issuesTableCell,
                  px: 0,
                }}
              ></TableCell>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>Total</Typography>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>
                  Closed
                </Typography>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>Open</Typography>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Typography sx={ComponentStyles.headingsName}>
                  Past Due
                </Typography>
              </TableCell>
            </TableHead>
            <TableRow>
              <TableCell sx={AuditStyles.issuesTableCell}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography sx={ComponentStyles.headingsName}></Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCellWithSolidRightBorder}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography sx={ComponentStyles.headingsName}>
                    {issuesData.total}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCellWithDottedRightBorder}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography sx={ComponentStyles.headingsName}>
                    {issuesData.issuesSummary.totalClosed}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={AuditStyles.issuesTableCellWithDottedRightBorder}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography sx={ComponentStyles.headingsName}>
                    {issuesData.issuesSummary.open}
                  </Typography>
                </Box>
              </TableCell>

              <TableCell sx={AuditStyles.issuesTableCell}>
                <Box sx={ComponentStyles.alignBoxItemsCenter}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 15,
                      color: "#344054",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {issuesData.issuesSummary.pastDue}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>

            <TableBody>
              {filteredIssues.map((issue, index) => (
                <TableRow
                  key={index}
                  sx={{ cursor: "default", height: "40px" }}
                >
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: issue.color,
                      maxWidth: "150px",
                      pr: 0,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      cursor: "default",
                      borderBottom: "none",
                      py: 0.5,
                    }}
                  >
                    {issue.category.length > 15 ? (
                      <Tooltip
                        title={issue.category}
                        placement="top"
                        sx={{ width: "32px", color: issue.color }}
                      >
                        <span>{issue.category}</span>
                      </Tooltip>
                    ) : (
                      issue.category
                    )}
                  </TableCell>

                  <TableCell
                    sx={{
                      ...AuditStyles.issuesTableCellWithSolidRightBorder,
                      borderBottom: "none",
                      py: 0.5,
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography
                        sx={{ ...ComponentStyles.headingsName, fontSize: 14 }}
                      >
                        {issue.open + issue.totalClosed || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...AuditStyles.issuesTableCellWithDottedRightBorder,
                      borderBottom: "none",
                      py: 0.5,
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography
                        sx={{ ...ComponentStyles.headingsName, fontSize: 14 }}
                      >
                        {issue.totalClosed || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      ...AuditStyles.issuesTableCellWithDottedRightBorder,
                      borderBottom: "none",
                      py: 0.5,
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography
                        sx={{ ...ComponentStyles.headingsName, fontSize: 14 }}
                      >
                        {issue.open || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: 500,
                      fontSize: 13,
                      borderBottom: "none",
                      py: 0.5,
                    }}
                  >
                    <Box sx={ComponentStyles.alignBoxItemsCenter}>
                      <Typography
                        sx={{ ...ComponentStyles.headingsName, fontSize: 14 }}
                      >
                        {issue.pastDue || "0"}
                      </Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      ) : (
        <Typography sx={{ p: 10, textAlign: "center" }}>
          The findings table will be avaliabled after the audit is processed.
        </Typography>
      )}
    </Paper>
  );
};

export default AuditIssuesTable;
