import React from "react";
import { Box, Typography, Table, TableBody, TableCell, TableRow, Divider } from "@mui/material";
import { ComponentStyles } from "../styles";

const OpenCAPCard = ({ capDetails = [] }) => {
  return (
    <Box
      sx={{
        py: 2,
        px: 4,
        border: "1px solid #EAECF0",
        borderRadius: "7px",
        border: "1px solid #D0D5DD",
        background: "#FFF",
        mb: 4,
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: 600 }}>
        Open CAP
      </Typography>
      <Divider sx={{ my: "10px" }} />
      <Table>
        <TableBody>
          {capDetails.length > 0 ? (
            capDetails.map((cap, index) => (
              <TableRow key={index}>
                <TableCell sx={{ borderBottom: "none", pl: 0 }}>
                  <Typography sx={ComponentStyles.fieldName}>Status</Typography>
                  <Box
                    sx={{
                      color: "#FFF",
                      textTransform: "none",
                      padding: "3px 8px",
                      fontSize: "12px",
                      fontWeight: 500,
                      borderRadius: "6px",
                      border: "1px solid #D0D5DD",
                      background:
                        cap.dueInDays > 30
                          ? "#FFCC00" // Yellow
                          : cap.dueInDays > 0
                          ? "#DC6803" // Orange
                          : "#B42318", // Red
                      width: "fit-content",
                    }}
                  >
                    {cap.dueInDays > 0
                      ? `In ${Math.ceil(cap.dueInDays)} days`
                      : `Past Due ${Math.ceil(-cap.dueInDays)} days`}
                  </Box>
                </TableCell>
                <TableCell sx={{ ...ComponentStyles.fieldName, borderBottom: "none" }}>
                  <Typography sx={ComponentStyles.fieldName}>Severity</Typography>
                  <Typography sx={ComponentStyles.fieldValue}>{cap.severity || 'N/A'}</Typography>
                </TableCell>
                <TableCell sx={{ ...ComponentStyles.fieldName, borderBottom: "none" }}>
                  <Typography sx={ComponentStyles.fieldName}>Findings</Typography>
                  <Typography sx={ComponentStyles.fieldValue}>
                  {cap.issueSummary 
                    ? (cap.issueSummary.length < 70 
                        ? cap.issueSummary 
                        : `${cap.issueSummary.substring(0, 70)}...`) 
                    : 'No issues reported'}
                  </Typography>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                No actions needed.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
};

export default OpenCAPCard;