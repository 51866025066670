import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Chip,
  Box,
  TextField,
  Paper,
  Select,
  MenuItem,
  FormControl,
  Fab,
  InputLabel,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomChipLabel from "./../customChipLabel.component";
import { getValidData, checkInternalEmail } from "../../../utils/lib";
import { updateAuditData } from "../../../reducers/audit.reducer";
// import { selectAuditIssueDetails } from "../../../selectors/audit.selector";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { setSaveStatus } from "../../../reducers/misc.reducer";
import { selectEmail } from "../../../selectors/login.selector";

const EditAuditReport = ({ data }) => {
  const params = useParams();

  const initData = {
    capDetailsSeverity: getValidData(data?.severity),
    capDetailsTimeline: getValidData(data?.timeline),
    capDetailsRatingExplanation: getValidData(data?.ratingExplanation),
    capDetailsImmediateCAP:
      getValidData(data?.capRecommendations?.immediateCAP) || "",
    capDetailsLongTermPreventativeAction:
      getValidData(data?.capRecommendations?.longTermPreventativeAction) || "",
    capDetailsTargetDeadline: getValidData(data?.targetDeadline),
    capDetailsCompletionDate: getValidData(data?.completionDate),
    capDetailsStatus: getValidData(data?.status),
    capDetailsManufacturerComment: getValidData(data?.manufacturerComment),
    capDetailsVerificationNote: getValidData(data?.verificationNote),
    capDetailsResponsiblePerson: getValidData(data?.responsiblePerson),
    capDetailsNote: getValidData(data?.note),
    capDetailsIssueDetail: getValidData(data?.issueDetail),
    capDetailsRepeatedIssue: getValidData(data?.repeatedIssue) || "No",
    capDetailsType: getValidData(data?.type),
    capDetailsSubType: getValidData(data?.subType),
    capDetailsLastUpdatedBy: getValidData(data?.lastUpdatedBy),
    capDetailsLastUpdatedDate: getValidData(data?.lastUpdatedDate),
    capDetailsAuditToolRequirement: getValidData(data?.clientCode), // 4i audit tool content
    auditBasis: getValidData(data?.auditBasis), // basis
    capDetailsRootCauseResponse: getValidData(data?.rootCauseResponse),
    capDetailsImmediateCorrectiveActionPlanFacilityInput: getValidData(
      data?.immediateCorrectiveActionPlanFacilityInput
    ),
    capDetailsLongTermPreventativeActionPlanFacilityInput: getValidData(
      data?.longTermPreventativeActionPlanFacilityInput
    ),
    capDetailsFindingId: getValidData(data?.clientCodeTitle),
    capDetailsRootCause: getValidData(data?.rootCause),
  };

  const userEmail = useSelector(selectEmail);
  const isInternalUser = checkInternalEmail(userEmail);
  // const isInternalUser = false;

  const [formData, setFormData] = useState(initData);

  // Store initial data to compare against
  const [initialData, setInitialData] = useState({});
  const [somethingUpdated, setSomethingUpdated] = useState(false);

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [data]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSomethingUpdated(true);
    setFormData({
      ...formData,
      [name]: value,
    });
    dispatch(setSaveStatus("updated"));
  };
  const handleDateChange = (newValue, name) => {
    setSomethingUpdated(true);
    setFormData({
      ...formData,
      [name]: dayjs(newValue).format("YYYY-MM-DD"),
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateAuditData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes,
          issueId: data.id,
        })
      );
      dispatch(setSaveStatus(null));
      setSomethingUpdated(null);
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <form onSubmit={handleSubmit}>
        <Box sx={{ p: 2 }}>
          {/* Header Section */}
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                }}
                gutterBottom
              >
                Finding
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "24px",
                  fontWeight: 400,
                  mt: 2,
                  mb: 3,
                }}
              >
                {data.issueSummary}
              </Typography>
            </Grid>
            <Grid>
              <Fab
                variant="extended"
                color="primary"
                type="submit"
                sx={{
                  backgroundColor: "#585aeb",
                  borderRadius: "8px",
                  textTransform: "none",
                }}
                disabled={!somethingUpdated}
              >
                Save Changes
              </Fab>
            </Grid>
          </Grid>

          {/* Details Section */}
          {!isInternalUser && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Finding ID
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {formData.capDetailsFindingId}
                </Typography>
              </Grid>
              {/* <Grid item xs={3}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Category
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {formData.capDetailsType}
                </Typography>
              </Grid> */}
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Violation Category
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {formData.capDetailsSubType}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "14px",
                    fontWeight: 700,
                  }}
                >
                  Repeated Finding
                </Typography>

                <Chip
                  label={formData.capDetailsRepeatedIssue}
                  sx={{
                    fontSize: "14px",
                    color: "#344054",
                    fontWeight: 500,
                    textAlign: "center",
                    borderRadius: "6px",
                    border: "1px solid #D0D5DD",
                    background: "#FFF",
                    padding: "4px 10px",
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ my: 2 }}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "14px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Finding Detail
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {formData.capDetailsIssueDetail}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "14px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Audit Code or Legal Reference
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {formData.auditBasis}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "14px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Reformation Audit Tool Requirement
                </Typography>
                <Typography
                  sx={{
                    color: "#45464E",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {formData.capDetailsAuditToolRequirement}
                </Typography>
              </Grid>
            </Grid>
          )}

          {/* Only Internal Users Can Edit */}
          {isInternalUser && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    color: "orange",
                    fontSize: "14px",
                    fontWeight: 700,
                    mb: 1,
                  }}
                >
                  Only internal user can edit
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  multiline
                  label="Finding ID"
                  name="capDetailsFindingId"
                  variant="outlined"
                  value={formData.capDetailsFindingId}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  multiline
                  label="Category"
                  name="capDetailsType"
                  variant="outlined"
                  value={formData.capDetailsType}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  multiline
                  label="Vioation Category"
                  name="capDetailsSubType"
                  variant="outlined"
                  value={formData.capDetailsSubType}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  multiline
                  label="Repeat Finding"
                  name="capDetailsRepeatedIssue"
                  variant="outlined"
                  value={formData.capDetailsRepeatedIssue}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ my: 2 }}>
                <TextField
                  fullWidth
                  multiline
                  label="Finding Detail"
                  name="capDetailsIssueDetail"
                  variant="outlined"
                  value={formData.capDetailsIssueDetail}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  multiline
                  label="Audit Code or Legal Reference"
                  name="auditBasis"
                  variant="outlined"
                  value={formData.auditBasis}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  multiline
                  label="Reformation Audit Tool Requirement"
                  name="capDetailsAuditToolRequirement"
                  variant="outlined"
                  value={formData.capDetailsAuditToolRequirement}
                  onChange={handleChange}
                  margin="normal"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        borderColor: "orange",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          )}


          {/* Finding Category */}
          <Grid container spacing={2} sx={{ my: 2 }}>
            <Grid item xs={3}>
              <FormControl fullWidth variant="outlined" margin="normal" disabled={!isInternalUser}>
                <InputLabel id="demo-simple-select-label">
                  Severity
                </InputLabel>
                <Select
                  label="Severity"
                  name="capDetailsSeverity"
                  value={formData.capDetailsSeverity}
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px", // Set the border-radius here
                      },
                    },
                  }}
                >
                  <MenuItem value="ZT">ZT</MenuItem>
                  <MenuItem value="Critical">Critical</MenuItem>
                  <MenuItem value="Major">Major</MenuItem>
                  <MenuItem value="Moderate">Moderate</MenuItem>
                  <MenuItem value="Minor">Minor</MenuItem>
                  <MenuItem value="Irrelevant">Irrelevant</MenuItem>
                  <MenuItem value="Unsure">Unsure</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Timeframe */}
            <Grid item xs={2}>
              <FormControl fullWidth variant="outlined" margin="normal" disabled={!isInternalUser}>
                <InputLabel id="timeframe"> Recommended Timeline</InputLabel>
                <Select
                  label="Timeframe"
                  name="capDetailsTimeline"
                  value={formData.capDetailsTimeline}
                  onChange={handleChange}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px", // Set the border-radius here
                      },
                    },
                  }}
                >
                  <MenuItem value="Immediate">Immediate</MenuItem>
                  <MenuItem value="0-1 months">0-1 month</MenuItem>
                  <MenuItem value="0-2 months">0-2 months</MenuItem>
                  <MenuItem value="0-3 months">0-3 months</MenuItem>
                  <MenuItem value="0-6 months">0-6 months</MenuItem>
                  <MenuItem value="No timeline">No timeline</MenuItem>
                </Select>
              </FormControl> 
            </Grid>

            {/* Rating Explanation */}
            <Grid item xs={7}>
              <TextField
                fullWidth
                multiline
                label="Rating Explanation"
                name="capDetailsRatingExplanation"
                variant="outlined"
                value={formData.capDetailsRatingExplanation} // Use formData here
                onChange={handleChange}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px", // Set the border-radius here
                    },
                  },
                }}
                disabled={!isInternalUser}
              />
            </Grid>

            {/* Internal Note */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Internal Note"
                name="capDetailsNote"
                variant="outlined"
                value={formData.capDetailsNote} // Use formData here
                onChange={handleChange}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px", // Set the border-radius here
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* CAP Recommendation Section */}
            <Grid item xs={6} sx={{ pr: 4 }}>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                  my: 4,
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  hyphens: "auto",
                }}
              >
                CAP Recommendations
              </Typography>

              <TextField
                fullWidth
                label="Immediate Corrective Action Plan"
                name="capDetailsImmediateCAP"
                variant="outlined"
                value={formData.capDetailsImmediateCAP} // Use formData here
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px", // Set the border-radius here
                    },
                  },
                }}
                disabled={!isInternalUser}
              />

              <TextField
                fullWidth
                label="Long Term Preventative Action"
                name="capDetailsLongTermPreventativeAction"
                variant="outlined"
                value={formData.capDetailsLongTermPreventativeAction} // Use formData here
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px", // Set the border-radius here
                    },
                  },
                }}
                disabled={!isInternalUser}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                  my: 4,
                }}
              >
                Facility Input
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6} sx={{ my: 0 }}>
                  <TextField
                    fullWidth
                    label="Accountable Person"
                    name="capDetailsResponsiblePerson"
                    variant="outlined"
                    value={formData.capDetailsResponsiblePerson}
                    onChange={handleChange}
                    margin="normal"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderRadius: "8px",
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6} sx={{ mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Target Completion Date"
                      value={
                        dayjs(formData.capDetailsTargetDeadline).isValid()
                          ? dayjs(formData.capDetailsTargetDeadline)
                          : null
                      }
                      onChange={(newValue) =>
                        handleDateChange(newValue, "capDetailsTargetDeadline")
                      }
                      renderInput={(params) => <TextField {...params} />}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <TextField
                fullWidth
                label="Root Cause Response"
                name="capDetailsRootCauseResponse"
                variant="outlined"
                value={formData.capDetailsRootCauseResponse}
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />

              <TextField
                fullWidth
                label="Immediate Corrective Action"
                name="capDetailsImmediateCorrectiveActionPlanFacilityInput"
                variant="outlined"
                value={
                  formData.capDetailsImmediateCorrectiveActionPlanFacilityInput
                }
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />

              <TextField
                fullWidth
                label="Long Term Preventative Action"
                name="capDetailsLongTermPreventativeActionPlanFacilityInput"
                variant="outlined"
                value={
                  formData.capDetailsLongTermPreventativeActionPlanFacilityInput
                }
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />

              <Typography
                sx={{
                  color: "#475467",
                  fontSize: "30px",
                  fontWeight: 500,
                  textDecorationLine: "underline",
                  my: 3,
                }}
              >
                CAP Update
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined" margin="normal">
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      label="Status"
                      name="capDetailsStatus"
                      value={formData.capDetailsStatus}
                      onChange={handleChange}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    >
                      <MenuItem value="Open">Open</MenuItem>
                      <MenuItem value="Closed">Closed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sx={{ mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Actual Completion Date"
                      value={
                        dayjs(formData.capDetailsCompletionDate).isValid()
                          ? dayjs(formData.capDetailsCompletionDate)
                          : null
                      }
                      onChange={(newValue) =>
                        handleDateChange(newValue, "capDetailsCompletionDate")
                      }
                      renderInput={(params) => <TextField {...params} />}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px",
                          },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <TextField
                fullWidth
                label="Verification Note"
                name="capDetailsVerificationNote"
                variant="outlined"
                value={formData.capDetailsVerificationNote}
                onChange={handleChange}
                multiline
                maxRows={6}
                margin="normal"
                sx={{
                  mb: 10,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "8px",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          {/* TODO: The "last updated by" should show the team member that changed the content*/}
          {/* <Typography
            sx={{
              color: "#000",
              textAlign: "right",
              fontSize: "14px",
              fontWeight: 500,
              lineHeight: "24px",
            }}
          >
            Last Updated by {formData.capDetailsLastUpdatedBy} - {formData.capDetailsLastUpdatedDate}
          </Typography> */}
        </Box>
      </form>
    </Container>
  );
};

export default EditAuditReport;
