import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Grid,
    IconButton,
    Typography,
    Snackbar
  } from '@mui/material';
import { Alert } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import _ from 'lodash';

import { selectSupplierData } from '../../selectors/supplier.selector';
import { updateSupplierData } from '../../reducers/supplier.reducer';

import { deepCloneWithDates, getChangedPaths, getValue } from '../../utils/lib';
import { supplierDocumentationSections, languages } from '../../configs/supplierDataSections'; // You'll need to create this
import RenderFormFields from '../renderFormFields';

const SupplierDocumentationTab = () => {
    const dispatch = useDispatch();
    const supplierData = useSelector(selectSupplierData);
    const [formData, setFormData] = useState({});
    const [initialData, setInitialData] = useState({});
    const [expandedSections, setExpandedSections] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [uploadDialogOpen, setUploadDialogOpen] = useState({ open: false, fieldKey: null });
  
    useEffect(() => {
        if (supplierData) {
          // Deep clone supplierData for formData and initialData separately
          const processedData = deepCloneWithDates(supplierData);
          setFormData(processedData);
    
          const initialDataClone = deepCloneWithDates(supplierData);
          setInitialData(initialDataClone);
    
          // Initialize all sections as expanded
          const allExpanded = supplierDocumentationSections.reduce((acc, section) => {
            acc[section.name] = true;
            return acc;
          }, {});
          setExpandedSections(allExpanded);
        }
    }, [supplierData]);

    const handleChange = (name, value) => {
        setFormData((prevData) => {
          const newData = _.cloneDeep(prevData);

          // Convert empty strings to null
          let updatedValue = value;
          if (value === '') {
              updatedValue = null;
          }
          _.set(newData, name, updatedValue);
    
          return newData;
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const changes = getChangedPaths(formData, initialData);

        console.log('Detected Changes:', changes);

        if (!_.isEmpty(changes)) {
            dispatch(
                updateSupplierData({
                organisationId: supplierData.organisationId,
                supplierId: supplierData._id,
                changes,
                })
            );
            setSnackbarMessage('Changes saved successfully');
            setSnackbarSeverity('success');
        } else {
            setSnackbarMessage('No changes detected');
            setSnackbarSeverity('info');
        }
        setSnackbarOpen(true);
    };
    
    const handleFileClick = (key) => {
        // Implement file click handler similar to facility
        window.open(key, '_blank');
    };

    const toggleSection = (sectionName) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionName]: !prev[sectionName]
        }));
    };
  
    const renderSection = (sectionName, fields) => (
        <Card sx={{ mb: 2, border: '1px solid #e0e0e0' }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">{sectionName}</Typography>
                    <IconButton onClick={() => toggleSection(sectionName)}>
                        {expandedSections[sectionName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={expandedSections[sectionName]}>
                <Grid container spacing={2}>
                        {fields.map((field, index) => {
                            const val = getValue(formData, field.dbMapping);
                            return (
                                <Grid item xs={12} sm={field.gridSize?.sm || 6} md={field.gridSize?.md || 3} key={`${sectionName}-${field.dbMapping || index}`}>
                                    {RenderFormFields({
                                        field,
                                        value: val,
                                        handleChange,
                                        formData,
                                        dispatch,
                                        languages,
                                        handleFileClick,
                                        uploadDialogOpen,
                                        setUploadDialogOpen,
                                        factoryData: supplierData, // Using supplierData here
                                        isSupplierLevel: true // Flag to indicate this is supplier level
                                    })}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Collapse>
            </CardContent>
        </Card>
    );

    return (
        <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    sx={{
                        backgroundColor: '#585aeb',
                        '&:hover': {
                            backgroundColor: '#4648c4',
                        },
                    }}
                >
                    Save Changes
                </Button>
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2, height: 'calc(100vh - 60px)' }}>
                {supplierDocumentationSections.map((section) => (
                    <div key={`${section.name}-${supplierData?._id}`}>
                        {renderSection(section.name, section.fields)}
                    </div>
                ))}
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={() => setSnackbarOpen(false)} 
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default SupplierDocumentationTab;