import React, { useState } from "react";
import { Card, CardContent, Typography, Grid, Box, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSupplierData } from "../selectors/supplier.selector";
import { selectFactoryData } from "../selectors/factory.selector";
import { ComponentStyles, FacilityStyles } from "../styles";
import InternalNotesEditModal from "./FacilityDetails/EditModals/editNotes.component"; //

const ConfidentialBusinessInformation = ({
  title,
  data
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const notes = data?.metadata?.internalStatusNotes;

  const handleOpenModal = () => {
    setIsEditModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsEditModalOpen(false);
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "305px", // Matches the height of the adjacent card
        height: "100%", // Ensures it takes full height of the container
        position: "relative", // For absolute positioning of the Edit button
        paddingX: 2, // Adds padding to the left and right sides
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
        border: "1px solid rgba(234, 236, 240, 1)",
        borderRadius: "12px",
      }}
    >
            <Box
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
        }}
      >
        <Button
          variant="outlined"
          onClick={handleOpenModal}
          sx={{
            color: "#6172F3",
            "&:hover": {
              color:"white",
              backgroundColor: "#5667e2",
            },
            textTransform: "none",
            width: "70px",
            height: "40px",
            borderRadius: "8px",
            mr: 1
          }}
        >
          Edit
        </Button>
      </Box>
      <CardContent sx={{ flexGrow: 1, paddingLeft: 3, paddingRight: 3 }}>
        <Typography variant="subtitle1" fontWeight="bold" sx={{ mb: 2 }}>
          {title}
        </Typography>
        {/* Scrollable text container */}
        <Grid 
          container 
          spacing={1} 
          sx={{ maxHeight: "350px", overflowY: "auto", paddingRight: 1 }} // Makes the text section scrollable
        >
          <Grid item xs={12}>
            <Typography
             sx={ComponentStyles.fieldValue}
            >
              {notes}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: "#45464E",
                fontSize: 16,
                fontWeight: 500,
                overflow: "hidden", // Ensures text does not overflow the box
                whiteSpace: "pre-line", // Adds line breaks where `\n` is present
                textOverflow: "ellipsis", // Adds ellipsis if the text is too long
                wordWrap: "break-word", // Ensures words break correctly to fit the space
                maxHeight: "460px", // Set a maximum height if needed
              }}
            >
              {notes}
            </Typography>
          </Grid>
        </Grid> */}
      </CardContent>

      {/* Render the edit modal */}
      <InternalNotesEditModal
        open={isEditModalOpen}
        onClose={handleCloseModal}
        internalData={data} // Pass the details to the modal as internalData
      />
    </Card>
  );
};

export default ConfidentialBusinessInformation;