import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Box,
    Typography,
    Grid,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Card,
    CardContent,
    Chip,
    Autocomplete,
    IconButton,
    Collapse,
    Link,
    Snackbar,
    Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { createNewFactoryFromSAQ, updateFactoryData } from '../../reducers/factory.reducer'
// import { selectFactoryDropdownData } from "../../selectors/factory.selector";
import { selectSignedUrls } from '../../selectors/factory.selector';
import _ from 'lodash';

const ENABLE_ASSIGN_EXISTING_FACTORY = false;

// Custom hook to track previous value
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

const QuestionnaireDetailView = ({ open, onClose, saqData, factoryDropdownData, supplierDropdownData, organisationId }) => {
    const dispatch = useDispatch();
    const signedUrls = useSelector(selectSignedUrls);
    const [selectedFactory, setSelectedFactory] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [expandedSections, setExpandedSections] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [feedback, setFeedback] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    const isGlobalLoading = useSelector(state => state.factory.loading);
    const prevLoading = usePrevious(isGlobalLoading);

    const [matchedFactory, setMatchedFactory] = useState(null);
    const [matchedSupplier, setMatchedSupplier] = useState(null);

    useEffect(() => {
        if (saqData?.name) {
            // Find if a factory with the same name exists in factoryDropdownData
            const existingFactory = factoryDropdownData.find(
                factory => factory.factoryId === saqData.assignedToFactory
            );

            if (existingFactory) {
                setMatchedFactory(existingFactory);

                // Find the supplier for this factory
                const relatedSupplier = supplierDropdownData.find(
                    supplier => supplier.supplierId === existingFactory.supplierId
                );
                if (relatedSupplier) {
                    setMatchedSupplier(relatedSupplier);
                }
            }
        }
    }, [saqData, factoryDropdownData, supplierDropdownData]);

    console.log("MATCHED FACTORY", matchedFactory);

    // Reset states when dialog opens
    useEffect(() => {
        if (open) {
            setSelectedFactory(null);
            setSelectedSupplier(null);
            setIsLoading(false);
            setFeedback({ open: false, message: '', severity: 'success' });
        }
    }, [open]);

    // Monitor selection changes (if needed)
    useEffect(() => {
        // You can add any logic needed when selections change
        console.log("Selections changed:", { selectedFactory, selectedSupplier });
    }, [selectedFactory, selectedSupplier]);


    useEffect(() => {
        // Check if we've just finished loading (prev was true, current is false)
        if (prevLoading && !isGlobalLoading) {
            //console.log("Factory creation completed");
            setFeedback({
                open: true,
                message: 'Facility created successfully!',
                severity: 'success'
            });
            setIsLoading(false);
        }
    }, [isGlobalLoading, prevLoading]);

    useEffect(() => {
    }, [selectedFactory, factoryDropdownData]);

    useEffect(() => {
        // Initialize all sections as expanded
        const allExpanded = saqSections.reduce((acc, section) => {
            acc[section.name] = true;
            return acc;
        }, {});
        setExpandedSections(allExpanded);
    }, []);

    const handleConfirmDialogOpen = () => {
        setOpenConfirmDialog(true);
      };
      
    const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
    };
    
    const handleConfirmedUpdate = async () => {
    setIsLoading(true);
    handleConfirmDialogClose();
    try {
        const changes = prepareUpdateData(saqData);
        await dispatch(updateFactoryData({
            organisationId,
            supplierId: selectedFactory.supplierId, // Assuming this is available in saqData
            factoryId: selectedFactory.factoryId,
            changes: changes
        })).unwrap();

        // Update SAQ with assignedToFactory
        const updatedSAQ = {
            ...saqData,
            assignedToFactory: selectedFactory.factoryId  // Set the factoryId in the SAQ
        };
        
        // Call the backend to update the SAQ
        await dispatch(updateFactoryData(updatedSAQ)).unwrap();

        onClose();
    } catch (error) {
        console.error("Error updating factory:", error);
    } finally {
        setIsLoading(false);
    }
    };

    const handleFactoryChange = (event) => {
        const selectedFactoryId = event.target.value;
        if (selectedFactoryId === 'new') {
            //console.log("Create new factory option selected");
            setSelectedFactory('new');  // If a new factory is being created
        } else {
            const selectedFactoryObject = factoryDropdownData.find(factory => factory.factoryId === selectedFactoryId);
            setSelectedFactory(selectedFactoryObject);  // Store the whole factory object
            //console.log("Selected factory object:", selectedFactoryObject);
        }
    };

    const handleSupplierChange = (event) => {
        const selectedSupplierId = event.target.value;
        if (selectedSupplierId === 'new') {
            setSelectedSupplier('new');
        } else {
            const selectedSupplierObject = supplierDropdownData.find(
                supplier => supplier.supplierId === selectedSupplierId
            );
            setSelectedSupplier(selectedSupplierObject);
        }
    };

    const prepareUpdateData = (saqData) => {
        // Exclude fields that shouldn't be updated
        const excludeFields = ['_id', 'factoryId', 'createdAt', 'updatedAt', 'supplierId'];
        
        const changes = Object.entries(saqData).reduce((acc, [key, value]) => {
          if (!excludeFields.includes(key)) {
            acc[key] = value;
          }
          return acc;
        }, {});
      
        // Ensure factoryStatus is set to 'live'
        changes.factoryStatus = 'live';
      
        return changes;
    };

    const handleAssignSAQ = async () => {
        if (!selectedFactory || !selectedSupplier) return;
        setIsLoading(true);
        try {
            if (selectedFactory === 'new') {
                //console.log("Dispatching action for creating new factory from SAQ");
                //await dispatch(createNewFactoryFromSAQ({ organisationId, factoryId: saqData.factoryId })).unwrap();
                dispatch(createNewFactoryFromSAQ({
                    organisationId,
                    factoryId: saqData.factoryId,
                    supplierName: selectedSupplier === 'new' ? 
                        saqData.metadata.supplierName : // Use original name for new supplier
                    selectedSupplier.name,          // Use selected supplier's name for existing supplier
                    metadata: {
                        country: saqData.metadata.country,
                    },
                    existingSupplierId: selectedSupplier !== 'new' ? selectedSupplier.supplierId : undefined,
                    tier: saqData.metadata.tier.slice(-1),
                }));
                  //onClose(); // Close the dialog after creating
            } else if (ENABLE_ASSIGN_EXISTING_FACTORY && selectedFactory) {
                // Handle assigning to existing factory
                //console.log("Assigning to existing factory:", selectedFactory.factoryId);
                handleConfirmDialogOpen();
            }
        } catch (error) {
            console.error("Error creating/assigning factory:", error);
            setFeedback({
                open: true,
                message: 'Failed to create facility. Please try again.',
                severity: 'error'
            });
            // Handle error (e.g., show error message)
        } finally {
            setIsLoading(false);
        }
    };

    const toggleSection = (sectionName) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionName]: !prev[sectionName]
        }));
    };


    const handleFileClick = (key) => {
        if (signedUrls[key]) {
            window.open(signedUrls[key], '_blank');
        } else {
            dispatch({ type: 'factory/getSignedUrl', payload: { key } });
        }
    };

    const getValue = (obj, path, defaultValue = '') => {
        if (Array.isArray(path)) {
            for (const p of path) {
              const value = _.get(obj, p);
              if (value !== undefined && value !== null && !(typeof value === 'object' && Object.keys(value).length === 0)) {
                return value;
              }
            }
            return defaultValue;
          }
        
        const value = _.get(obj, path);
      
        if (value === undefined || value === null) {
          return defaultValue;
        } else if (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0) {
          // Return defaultValue if value is an empty object
          return defaultValue;
        } else if (Array.isArray(value) && value.length === 0) {
          // Return defaultValue if value is an empty array
          return defaultValue;
        } else {
          return value;
        }
      };

      const handleCloseFeedback = (event, reason) => {
        if (reason === 'clickaway') return;
        setFeedback(prev => ({ ...prev, open: false }));
    };

    const renderField = (field) => {
        
        const value = getValue(saqData, field.dbMapping);

        switch (field.fieldType) {
            case 'string':
            case 'number':
                return (
                    <TextField
                        fullWidth
                        size="small"
                        label={field.fieldLabel}
                        value={value}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            sx: {
                                cursor: 'default',
                                '&:hover': {
                                    cursor: 'default',
                                    backgroundColor: 'transparent'
                                },
                                '& .MuiInputBase-input': {
                                    cursor: 'default',
                                    '&:focus': {
                                        backgroundColor: 'transparent',
                                        cursor: 'default'
                                    }
                                },
                                '& fieldset': {
                                    borderColor: '#e0e0e0 !important',
                                    '&:hover': {
                                        borderColor: '#e0e0e0 !important'
                                    }
                                }
                            }
                        }}
                        sx={{
                            pointerEvents: 'none',
                            backgroundColor: 'transparent',
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: '#e0e0e0'
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#e0e0e0'
                                }
                            }
                        }}
                        margin="dense"
                        variant="outlined"
                    />
                );
            case 'dropdown':
            case 'dropdown multi':
                return (
                    <TextField
                        fullWidth
                        size="small"
                        label={field.fieldLabel}
                        value={Array.isArray(value) ? value.join(', ') : value || ''}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            sx: {
                                cursor: 'default',
                                '&:hover': {
                                    cursor: 'default',
                                    backgroundColor: 'transparent'
                                },
                                '& .MuiInputBase-input': {
                                    cursor: 'default',
                                    '&:focus': {
                                        backgroundColor: 'transparent',
                                        cursor: 'default'
                                    }
                                },
                                '& fieldset': {
                                    borderColor: '#e0e0e0 !important',
                                    '&:hover': {
                                        borderColor: '#e0e0e0 !important'
                                    }
                                }
                            }
                        }}
                        sx={{
                            pointerEvents: 'none',
                            backgroundColor: 'transparent',
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: '#e0e0e0'
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#e0e0e0'
                                }
                            }
                        }}
                        margin="dense"
                        variant="outlined"
                    />
                );
            case 'bool':
                return (
                    <TextField
                        fullWidth
                        size="small"
                        label={field.fieldLabel}
                        value={value ? 'Yes' : 'No'}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            sx: {
                                cursor: 'default',
                                '&:hover': {
                                    cursor: 'default',
                                    backgroundColor: 'transparent'
                                },
                                '& .MuiInputBase-input': {
                                    cursor: 'default',
                                    '&:focus': {
                                        backgroundColor: 'transparent',
                                        cursor: 'default'
                                    }
                                },
                                '& fieldset': {
                                    borderColor: '#e0e0e0 !important',
                                    '&:hover': {
                                        borderColor: '#e0e0e0 !important'
                                    }
                                }
                            }
                        }}
                        sx={{
                            pointerEvents: 'none',
                            backgroundColor: 'transparent',
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: '#e0e0e0'
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#e0e0e0'
                                }
                            }
                        }}
                        margin="dense"
                        variant="outlined"
                    />
                );
            case 'date':
                return (
                    <TextField
                        fullWidth
                        size="small"
                        label={field.fieldLabel}
                        value={value}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            sx: {
                                cursor: 'default',
                                '&:hover': {
                                    cursor: 'default',
                                    backgroundColor: 'transparent'
                                },
                                '& .MuiInputBase-input': {
                                    cursor: 'default',
                                    '&:focus': {
                                        backgroundColor: 'transparent',
                                        cursor: 'default'
                                    }
                                },
                                '& fieldset': {
                                    borderColor: '#e0e0e0 !important',
                                    '&:hover': {
                                        borderColor: '#e0e0e0 !important'
                                    }
                                }
                            }
                        }}
                        sx={{
                            pointerEvents: 'none',
                            backgroundColor: 'transparent',
                            '& .MuiOutlinedInput-root': {
                                '&:hover fieldset': {
                                    borderColor: '#e0e0e0'
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#e0e0e0'
                                }
                            }
                        }}
                        margin="dense"
                        variant="outlined"
                    />
                );
            case 'file':
                const renderFileLink = (file) => {
                    const handleClick = (e) => {
                        e.preventDefault();
                        if (signedUrls[file.key]) {
                            window.open(signedUrls[file.key], '_blank');
                        } else {
                            dispatch({ type: 'factory/getSignedUrl', payload: { key: file.key } });
                        }
                    };
    
                    const truncateText = (text, maxLength) => {
                        if (text.length <= maxLength) return text;
                        return text.substring(0, maxLength) + '...';
                    };
                
                    return (
                        <Link
                            onClick={(e) => {
                                e.preventDefault();
                                handleFileClick(file.key);
                            }}
                            //href={file.url}
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                            key={file.key}
                            sx={{
                                textDecoration: 'none',
                                color: 'primary.main',
                                '&:hover': {
                                    textDecoration: 'underline'
                                },
                                display: 'block',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '100%',
                                fontSize: 'body1.fontSize',
                                fontFamily: 'body1.fontFamily'
                            }}
                            title={file.name} // Show full name on hover
                        >
                            {file.name ? truncateText(file.name, 25) : 'View File'}
                        </Link>
                    );
                };      
                const currentFiles = value;
                //console.log("CURRENT FILES", currentFiles);    
                return (
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                    <FormControl fullWidth size="small" margin="dense">
                        <InputLabel 
                            shrink 
                            sx={{ 
                                backgroundColor: 'white',
                                px: 0.5
                            }}
                        >
                        {field.fieldLabel}
                        </InputLabel>
                        <Box
                        component="div"
                            sx={{
                                border: '1px solid #e0e0e0',
                                borderRadius: 1,
                                p: 1.5,
                                minHeight: '40px',
                                backgroundColor: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1
                            }}
                            >
                        <Box 
                            component="div"
                            sx={{ 
                                display: 'flex', 
                                flexDirection: 'column', 
                                gap: 1 
                            }}
                        >{currentFiles ? (
                            Array.isArray(currentFiles) ? (
                                currentFiles.map(renderFileLink)
                            ) : typeof currentFiles === 'string' ? (
                                renderFileLink({ url: currentFiles, name: 'View File' })
                            ) : (
                                renderFileLink(currentFiles)
                            )
                        ) : (
                            <Typography 
                                variant="body2" 
                                sx={{ 
                                    color: 'text.secondary',
                                    fontStyle: 'italic'
                                }}
                            >
                                No file uploaded
                            </Typography>
                        )}
                        </Box>
                    </Box>
                </FormControl>
            </Box>
                );
            //     return (
            //         <Box>
            // <Typography variant="caption">{field.fieldLabel}</Typography>
            // {value ? (
            //     <Link href={value} target="_blank" rel="noopener noreferrer">
            //     View File
            //     </Link>
            // ) : (
            //     <Typography variant="body2">No file uploaded</Typography>
            // )}
            // </Box>
            //     );
            default:
                return null;
        }
      };

    const renderSection = (sectionName, fields) => (
        <Card sx={{ mb: 2, boxShadow: 'none', border: '1px solid #e0e0e0' }}>
            <CardContent sx={{ p: 0 }}>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between', 
                        alignItems: 'center', 
                        p: 2, 
                        borderBottom: expandedSections[sectionName] ? '1px solid #e0e0e0' : 'none'
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{sectionName}</Typography>
                    <IconButton onClick={() => toggleSection(sectionName)}>
                        {expandedSections[sectionName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={expandedSections[sectionName]}>
                    <Box sx={{ p: 2 }}>
                        <Grid container spacing={2}>
                            {fields.map((field) => (
                                <Grid item xs={12} sm={field.gridSize?.sm || 6} md={field.gridSize?.md || 4} key={`${sectionName}-${field.dbMapping}`}>
                                    {renderField(field)}
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Collapse>
            </CardContent>
        </Card>
    );

    const saqSections = [
        {
          name: 'Facility Basic Information',
          fields: [
            { fieldLabel: 'Supplier Name (English)', dbMapping: 'metadata.supplierName', fieldType: 'string' },
            { fieldLabel: 'Facility Name (English)', dbMapping: 'name', fieldType: 'string' },
            { fieldLabel: 'Facility Legal Name (Local Language, per Business License)', dbMapping: 'metadata.facilityLegalName', fieldType: 'string' },
            { fieldLabel: 'Facility Street Line 1', dbMapping: 'metadata.addressLine1', fieldType: 'string' },
            { fieldLabel: 'Facility Street Line 2', dbMapping: 'metadata.addressLine2', fieldType: 'string' },
            { fieldLabel: 'Facility Country', dbMapping: 'metadata.country', fieldType: 'string' },
            { fieldLabel: 'State', dbMapping: 'metadata.state', fieldType: 'string' },
            { fieldLabel: 'City', dbMapping: 'metadata.city', fieldType: 'string' },
          ]
        },
        {
          name: 'Agent Information',
          fields: [
            { fieldLabel: 'Agent Contact Name', dbMapping: 'metadata.agentContact.name', fieldType: 'string' },
            { fieldLabel: 'Agent Email', dbMapping: 'metadata.agentContact.email', fieldType: 'string' },
            { fieldLabel: 'Agent HQ Address', dbMapping: 'metadata.agentContact.address', fieldType: 'string' },
            { fieldLabel: 'Agent Business Name', dbMapping: 'metadata.agentContact.businessName', fieldType: 'string' },
            { fieldLabel: 'Agent HQ Street Line 1', dbMapping: 'metadata.agentContact.streetLine1', fieldType: 'string' },
            { fieldLabel: 'Agent HQ Street Line 2', dbMapping: 'metadata.agentContact.streetLine2', fieldType: 'string' },
            { fieldLabel: 'Agent HQ City', dbMapping: 'metadata.agentContact.city', fieldType: 'string' },
            { fieldLabel: 'Agent HQ State', dbMapping: 'metadata.agentContact.state', fieldType: 'string' },
            { fieldLabel: 'Agent HQ Country', dbMapping: 'metadata.agentContact.country', fieldType: 'string' },
            { fieldLabel: 'Agent Phone Number', dbMapping: 'metadata.agentContact.phone', fieldType: 'string' },
            { fieldLabel: 'Agent Spoken Languages', dbMapping: 'metadata.agentContact.spokenLanguages', fieldType: 'string' },
          ]
        },
        {
          name: 'Facility Contact Information',
          fields: [
            { fieldLabel: 'Primary Facility Contact Name', dbMapping: 'metadata.primaryFacilityContactPerson.name', fieldType: 'string' },
            { fieldLabel: 'Primary Facility Contact Title', dbMapping: 'metadata.primaryFacilityContactPerson.position', fieldType: 'string' },
            { fieldLabel: 'Primary Facility Contact Email', dbMapping: 'metadata.primaryFacilityContactPerson.email', fieldType: 'string' },
            { fieldLabel: 'Primary Facility Contact Phone', dbMapping: 'metadata.primaryFacilityContactPerson.phone', fieldType: 'string' },
            { fieldLabel: 'Primary Facility Contact Spoken Languages', dbMapping: 'metadata.primaryFacilityContactPerson.spokenLanguages', fieldType: 'string' },
            { fieldLabel: 'Social Compliance Contact Name', dbMapping: 'metadata.socialComplianceContactPerson.name', fieldType: 'string' },
            { fieldLabel: 'Social Compliance Email', dbMapping: 'metadata.socialComplianceContactPerson.email', fieldType: 'string' },
            { fieldLabel: 'Social Compliance Phone Number', dbMapping: 'metadata.socialComplianceContactPerson.phone', fieldType: 'string' },
            { fieldLabel: 'Social Compliance Spoken Languages', dbMapping: 'metadata.socialComplianceContactPerson.spokenLanguages', fieldType: 'string' },
            { fieldLabel: 'Environmental Health & Safety (EHS) Compliance Contact', dbMapping: 'metadata.ehsContactPerson.name', fieldType: 'string' },
            { fieldLabel: 'EHS Email', dbMapping: 'metadata.ehsContactPerson.email', fieldType: 'string' },
            { fieldLabel: 'EHS Phone Number', dbMapping: 'metadata.ehsContactPerson.phone', fieldType: 'string' },
            { fieldLabel: 'EHS Spoken Languages', dbMapping: 'metadata.ehsContactPerson.spokenLanguages', fieldType: 'string' },
            { fieldLabel: 'Materials/Sustainability Contact', dbMapping: 'metadata.materialsSustainabilityContactPerson.name', fieldType: 'string' },
            { fieldLabel: 'Materials/Sustainability Email', dbMapping: 'metadata.materialsSustainabilityContactPerson.email', fieldType: 'string' },
            { fieldLabel: 'Materials/Sustainability Phone Number', dbMapping: 'metadata.materialsSustainabilityContactPerson.phone', fieldType: 'string' },
            { fieldLabel: 'Materials/Sustainability Spoken Languages', dbMapping: 'metadata.materialsSustainabilityContactPerson.spokenLanguages', fieldType: 'string' },
          ]
        },
        {
          name: 'Production Information',
          fields: [
            { fieldLabel: 'Facility Tier', dbMapping: 'tier', fieldType: 'dropdown' },
            { fieldLabel: 'Main Production processes at facility (i.e. cutting, sewing, finishing, etc.)', dbMapping: 'metadata.processInfo.productionProcesses', fieldType: 'dropdown multi' },
            { fieldLabel: 'OS ID', dbMapping: 'metadata.osId', fieldType: 'string' },
            { fieldLabel: 'Wordly Higg ID', dbMapping: 'metadata.wordlyHiggId', fieldType: 'string' },
            { fieldLabel: 'Who are your existing clients (brands/retailers)?', dbMapping: 'metadata.existingClients', fieldType: 'string' },
            { fieldLabel: 'Facility Affiliation', dbMapping: 'metadata.facilityAffiliation', fieldType: 'dropdown' },
            { fieldLabel: 'Minimum Order Quantity', dbMapping: 'metadata.minOrderQty', fieldType: 'number' },
            { fieldLabel: 'Average Lead time', dbMapping: 'metadata.avgLeadTime', fieldType: 'number' },
            { fieldLabel: 'Total Number of Machines', dbMapping: 'metadata.machineCount', fieldType: 'number' },
            { fieldLabel: 'Peak Season', dbMapping: 'metadata.productionSeasonalityPeak', fieldType: 'dropdown multi' },
            { fieldLabel: 'Low Season', dbMapping: 'metadata.productionSeasonalityLow', fieldType: 'dropdown multi' },
            { fieldLabel: 'Avg Units Produced/Week', dbMapping: 'metadata.avgUnitsProducedPerWeek', fieldType: 'number' },
            { fieldLabel: 'Average Annual Production Volume (in meters, units, or kilograms)?', dbMapping: 'metadata.avgAnnualBusinessVolume', fieldType: 'string' },
          ]
        },
        {
          name: 'Social Compliance Information',
          fields: [
            { fieldLabel: 'Garment Registration / Business License #', dbMapping: 'metadata.businessLicenseInfo.licenseNo', fieldType: 'string' },
            { fieldLabel: 'Registration / License Expiration Date', dbMapping: 'metadata.businessLicenseInfo.licenseExpDate', fieldType: 'date' },
            { fieldLabel: 'Registration/License Upload', dbMapping: 'metadata.businessLicenseInfo.fileUpload', fieldType: 'file' },
            { fieldLabel: 'Workers\' Comp Certificate (WCC)', dbMapping: 'metadata.wcc.fileUpload', fieldType: 'file' },
            { fieldLabel: 'WCC Expiration Date (US only)', dbMapping: 'metadata.wcc.wccExpDate', fieldType: 'date' },
            { fieldLabel: 'NDA Signed Document', dbMapping: 'metadata.nda.fileUpload', fieldType: 'file' },
            { fieldLabel: 'NDA Signed?', dbMapping: 'metadata.nda.ndaCheck', fieldType: 'bool' },
            { fieldLabel: 'Dormitories/Employee Housing Present?', dbMapping: 'metadata.housing.employeeHousingCheck', fieldType: 'bool' },
            { fieldLabel: 'Do workers pay for housing?', dbMapping: 'metadata.housing.doWorkersPayForHousing', fieldType: 'bool' },
            { fieldLabel: 'If so, how much?', dbMapping: 'metadata.housing.howMuchDoWorkersPayForHousing', fieldType: 'string' },
            { fieldLabel: 'Are there Multiple Tenants?', dbMapping: 'metadata.tenants.multipleTenantsCheck', fieldType: 'bool' },
            { fieldLabel: 'If so, Do they operate under the same business License?', dbMapping: 'metadata.tenants.multipleTenantsDoTheyOperateUnderSameBusinessLicense', fieldType: 'bool' },
            { fieldLabel: 'Does the facility actively* use the Higg FSLM to track and manage their social responsibility impact?', dbMapping: 'metadata.higg.higgFLSMCheck', fieldType: 'bool' },
            //{ fieldLabel: 'If yes to using the FSLM, please share your latest FSLM/FEM module with Reformation.', dbMapping: 'metadata.higg.fileUpload', fieldType: 'file' },
            //{ fieldLabel: 'Current FSLM Score', dbMapping: 'metadata.higg.higgFSLMScore', fieldType: 'string' },
            //{ fieldLabel: 'Latest FSLM Module Completed', dbMapping: 'metadata.higg.higgFSLMModule', fieldType: 'string' },
            { fieldLabel: 'Time Recording System', dbMapping: 'metadata.timeRecordingSystem', fieldType: 'number' },
            { fieldLabel: 'If "Other", please describe system', dbMapping: 'metadata.timeRecordingSystemOther', fieldType: 'number' },

            { fieldLabel: '# of Social Assessments facility receives each year', dbMapping: 'metadata.socialAssessmentInfo.socialAssessmentCount', fieldType: 'number' },
            { fieldLabel: 'Types of Social Assessments', dbMapping: 'metadata.socialAssessmentInfo.socialAssessmentTypes', fieldType: 'dropdown multi' },
            { fieldLabel: 'Date of last social audit / assessment', dbMapping: 'metadata.socialAssessmentInfo.lastAssessmentDate', fieldType: 'string' },
            { fieldLabel: 'Type of last audit / assessment', dbMapping: 'metadata.socialAssessmentInfo.lastSocialAssessmentType', fieldType: 'string' },
            { fieldLabel: 'Name of audit firm that conducted the last audit / assessment', dbMapping: 'metadata.socialAssessmentInfo.lastAssessmentFirm', fieldType: 'string' },
            { fieldLabel: 'Do you have your own Code of Conduct?', dbMapping: 'metadata.coc.hasOwnCoc', fieldType: 'bool' },
            { fieldLabel: 'Own COC Upload', dbMapping: 'metadata.coc.ownCoc.fileUpload', fieldType: 'file' },
            { fieldLabel: 'Have you adopted or assigned a code of conduct (CoC) and labor and environmental standards, or agreed to and signed a code of conduct of any of your buyers?', dbMapping: 'metadata.coc.cocCheck', fieldType: 'dropdown' },
            { fieldLabel: 'What COC have you signed/aligned with?', dbMapping: 'metadata.coc.whichCOC', fieldType: 'dropdown multi' },
            { fieldLabel: 'What partner/client/brand COC have you signed?', dbMapping: 'metadata.coc.signedPartnerCOC', fieldType: 'string' },            
            { fieldLabel: 'If \'NO\', are you ready and willing to sign a code of conduct on workplace standards?', dbMapping: 'metadata.coc.ifNoCocWillingToSign', fieldType: 'dropdown' },
            { fieldLabel: 'Signed COC Upload', dbMapping: 'metadata.coc.fileUpload', fieldType: 'file' },
            //{ fieldLabel: 'Do all workers get paid at least the legal minimum wage?', dbMapping: 'metadata.facilityChecks.legalMinWage', fieldType: 'dropdown' },
            { fieldLabel: 'Do you or have you worked with any Fair Labor Association (FLA) Accredited brands?', dbMapping: 'metadata.fla.flaCheck', fieldType: 'dropdown' },
            { fieldLabel: 'If \'Yes\', please specify which brands', dbMapping: 'metadata.fla.flaBrands', fieldType: 'string' },
          ]
        },
        {
          name: 'Employee/Worker Information',
          fields: [
            { fieldLabel: 'Total Number of Employees', dbMapping: 'metadata.workerCountTotal', fieldType: 'number' },
            { fieldLabel: 'Total Number of Production Employees', dbMapping: 'metadata.workerCountProduction', fieldType: 'number' },
            { fieldLabel: 'Number of Male workers', dbMapping: 'metadata.workerGenderMale', fieldType: 'number' },
            { fieldLabel: 'Number of Female workers', dbMapping: 'metadata.workerGenderFemale', fieldType: 'number' },
            { fieldLabel: 'Number of Local/ Domestic Migrant Workers', dbMapping: 'metadata.domesticMigrantWorkers', fieldType: 'number' },
            { fieldLabel: 'Number of Foreign Migrant Workers', dbMapping: 'metadata.foreignMigrantWorkers', fieldType: 'number' },
            { fieldLabel: 'Main language(s) spoken by Employees/Workers', dbMapping: 'metadata.employeePrimarilySpokenLanguage', fieldType: 'string' },
            { fieldLabel: 'Are workers hired through labor brokers/staffing agencies?', dbMapping: 'metadata.employeeInfo.workedHiredThroughBrokersOrAgenciesCheck', fieldType: 'dropdown' },
            { fieldLabel: 'Do workers pay fees to the brokers/staffing agencies?', dbMapping: 'metadata.employeeInfo.agencyWorkersFeesPaidCheck', fieldType: 'dropdown' },
            { fieldLabel: 'Do employees retain their own documents?', dbMapping: 'metadata.employeeInfo.doEmployeesRetainTheirDocuments', fieldType: 'dropdown' },
            { fieldLabel: 'If \'NO\', who retains documents and where are they maintained?', dbMapping: 'metadata.employeeInfo.whoRetainsEmployeeDocuments', fieldType: 'string' },
            { fieldLabel: 'Age of youngest worker at facility', dbMapping: 'metadata.employeeInfo.ageOfYoungestWorker', fieldType: 'number' },
          ]
        },
        {
          name: 'Freedom of Association',
          fields: [
            { fieldLabel: 'Is there a union in the facility?', dbMapping: 'metadata.freedomOfAssoc.unionCheck', fieldType: 'dropdown' },
            { fieldLabel: 'If \'YES\', name of Union', dbMapping: 'metadata.freedomOfAssoc.unionName', fieldType: 'string' },
            { fieldLabel: 'Do employees pay union dues?', dbMapping: 'metadata.freedomOfAssoc.employeesPayUnionDuesCheck', fieldType: 'dropdown' },
            { fieldLabel: 'If \'YES\', how much & how often are dues deducted?', dbMapping: 'metadata.freedomOfAssoc.howMuchHowOftenDues', fieldType: 'string' },
            { fieldLabel: 'Is there a Collective Bargaining Agreement (CBA) in place?', dbMapping: 'metadata.freedomOfAssoc.cbaCheck', fieldType: 'string' },
            { fieldLabel: 'If \'YES\', CBA valid period', dbMapping: 'metadata.freedomOfAssoc.cbaPeriod', fieldType: 'string' },
            { fieldLabel: "Are there worker's committees present in your facility?", dbMapping: 'metadata.freedomOfAssoc.workerCommitteeCheck', fieldType: 'bool' },
        ]
        },
        {
            name: 'Subcontractor Information',
            fields: [
                { fieldLabel: 'Does the Facility employ subcontractors for any part of the production process?', dbMapping: 'metadata.subcontractors.subcontractorsCheck', fieldType: 'dropdown' },
                { fieldLabel: 'If \'Yes\', provide details', dbMapping: 'metadata.subcontractors.details', fieldType: 'string' },
            ]
        },
        {
            name: 'Environmental Management',
            fields: [
              { fieldLabel: 'Do you have a comprehensive environmental management system in place?', dbMapping: 'metadata.envMgmt.envMgmtSystemCheck', fieldType: 'dropdown' },
              { fieldLabel: 'Is your facility in compliance with all federal, state, and local environmental permitting requirements?', dbMapping: 'metadata.envMgmt.envPermitComplianceCheck', fieldType: 'dropdown' },
              { fieldLabel: 'Wastewater Discharge Permit', dbMapping: 'metadata.envMgmt.wastewaterDischargePermitCheck', fieldType: 'dropdown' },
              { fieldLabel: 'Wastewater Discharge Permit Expiration Date', dbMapping: 'metadata.envMgmt.wastewaterDischargePermitExpDate', fieldType: 'date' },
              { fieldLabel: 'Wastewater Discharge Permit Upload', dbMapping: 'metadata.envMgmt.wastewaterDischargePermitUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'Stormwater Discharge Permit', dbMapping: 'metadata.envMgmt.stormwaterDischargePermitCheck', fieldType: 'dropdown' },
              { fieldLabel: 'Stormwater Discharge Permit Expiration Date:', dbMapping: 'metadata.envMgmt.stormwaterDischargePermitExpDate', fieldType: 'date' },
              { fieldLabel: 'Stormwater Discharge Permit Upload:', dbMapping: 'metadata.envMgmt.stormwaterDischargePermitUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'Waste Generation and Handling Permit', dbMapping: 'metadata.envMgmt.wasteGenerationHandlingPermitCheck', fieldType: 'dropdown' },
              { fieldLabel: 'Waste Generation and Handling Permit Expiration Date', dbMapping: 'metadata.envMgmt.wasteGenerationHandlingPermitExpDate', fieldType: 'date' },
              { fieldLabel: 'Waste Generation and Handling Permit Upload:', dbMapping: 'metadata.envMgmt.wasteGenerationHandlingPermitUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'Air Permit', dbMapping: 'metadata.envMgmt.airPermitCheck', fieldType: 'dropdown' },
              { fieldLabel: 'Air Permit Expiration Date', dbMapping: 'metadata.envMgmt.airPermitExpDate', fieldType: 'date' },
              { fieldLabel: 'Air Permit Upload:', dbMapping: 'metadata.envMgmt.airPermitUpload.fileUpload', fieldType: 'file' },
            ]
          },
          {
            name: 'Environmental Compliance',
            fields: [
              { fieldLabel: 'Do you have a program/system to review and monitor environmental permit status and renewal?', dbMapping: 'metadata.envCompliance.envPermitStatusProgramCheck', fieldType: 'dropdown' },
              { fieldLabel: 'If \'Yes\', please describe the process & provide SOP (if available)', dbMapping: 'metadata.envCompliance.envPermitStatusSOPUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'Does your facility have a means to stay up-to-date with legal requirements regarding environmental compliance (i.e. emissions, water, energy, chemicals, etc.)?', dbMapping: 'metadata.envCompliance.envComplianceLegalRequirementsMeansCheck', fieldType: 'dropdown' },
              { fieldLabel: 'If \'Yes\', describe the process & provide SOP (if available)', dbMapping: 'metadata.envCompliance.envComplianceLegalRequirementsSOPUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'Do you have a stormwater management plan?', dbMapping: 'metadata.envCompliance.stormwaterMgmtPlanCheck', fieldType: 'dropdown' },
              { fieldLabel: 'Do you have an onsite wastewater treatment system?', dbMapping: 'metadata.envCompliance.wastewaterTreatmentSystemOnsiteCheck', fieldType: 'dropdown' },
              { fieldLabel: 'Wastewater Treatment Type', dbMapping: 'metadata.envCompliance.wastewaterTreatmentType', fieldType: 'dropdown' },
              { fieldLabel: 'Do you have a chemical management plan?', dbMapping: 'metadata.envCompliance.chemicalMgmtPlanCheck', fieldType: 'dropdown' },
              { fieldLabel: 'Do you have an emergency spill response plan?', dbMapping: 'metadata.envCompliance.emergencySpillResponsePlanCheck', fieldType: 'dropdown' },
              { fieldLabel: 'Is a Chemical Inventory List maintained for all production & operational chemicals used onsite?', dbMapping: 'metadata.envCompliance.chemicalInventoryList', fieldType: 'dropdown' },
              { fieldLabel: 'If \'Yes\', please provide the latest inventory report', dbMapping: 'metadata.envCompliance.chemicalInventoryListUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'Is the chemical inventory list compliant with the facility Manufacturer Restricted Substance List (MRSL)?', dbMapping: 'metadata.envCompliance.chemicalInventoryListCompliance', fieldType: 'dropdown' },
              { fieldLabel: 'How often is the compliance of the chemical inventory internally verified?', dbMapping: 'metadata.envCompliance.chemicalInventoryInternalVerificationFrequency', fieldType: 'dropdown' },
              { fieldLabel: 'Chemical Inventory Compliance Type', dbMapping: 'metadata.envCompliance.chemicalInventoryComplianceType', fieldType: 'dropdown multi' },
              { fieldLabel: 'Does the facility actively* use the Higg FEM to track and manage their environmental impact?', dbMapping: 'metadata.higg.higgFEMCheck', fieldType: 'dropdown' },
              { fieldLabel: 'If yes to using the FEM, please share your latest FEM module with Reformation.', dbMapping: 'metadata.higg.higgFEMPleaseShareModule', fieldType: 'bool' },
              { fieldLabel: 'Has the facility actively participated in any resource effiency or clean chemistry programs in the last two years? (i.e., Apparel Impact Institute\'s Clean by Design and Carbon Leadership Program, Bluesign, ZDHC\'s Roadmap to Zero, etc.)', dbMapping: 'metadata.envCompliance.resourceEfficiencyProgramCheck', fieldType: 'dropdown' },
              { fieldLabel: 'If yes to participating in resource efficiency program(s), please briefly describe the program(s) and when it occurred.', dbMapping: 'metadata.envCompliance.resourceEfficiencyProgramDescription', fieldType: 'string' },
            ]
          },
          {
            name: 'Current Certifications/Programs',
            fields: [
              { fieldLabel: 'BSCI', dbMapping: 'metadata.certifications.bsciCheck', fieldType: 'dropdown' },
              { fieldLabel: 'BSCI Expiration Date:', dbMapping: 'metadata.certifications.bsciExpDate', fieldType: 'date' },
              { fieldLabel: 'BSCI Certificate:', dbMapping: 'metadata.certifications.bsciUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'ICTI', dbMapping: 'metadata.certifications.ictiCheck', fieldType: 'dropdown' },
              { fieldLabel: 'ICTI Expiration Date:', dbMapping: 'metadata.certifications.ictiExpDate', fieldType: 'date' },
              { fieldLabel: 'ICTI Certificate:', dbMapping: 'metadata.certifications.ictiUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'WRAP', dbMapping: 'metadata.certifications.wrapCheck', fieldType: 'dropdown' },
              { fieldLabel: 'WRAP Expiration Date:', dbMapping: 'metadata.certifications.wrapExpDate', fieldType: 'date' },
              { fieldLabel: 'WRAP Certificate:', dbMapping: 'metadata.certifications.wrapUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'SA8000', dbMapping: 'metadata.certifications.sa8000Check', fieldType: 'dropdown' },
              { fieldLabel: 'SA8000 Expiration Date:', dbMapping: 'metadata.certifications.sa8000ExpDate', fieldType: 'date' },
              { fieldLabel: 'SA8000 Certificate:', dbMapping: 'metadata.certifications.sa8000Upload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'SMETA', dbMapping: 'metadata.certifications.smetaCheck', fieldType: 'dropdown' },
              { fieldLabel: 'SMETA Expiration Date:', dbMapping: 'metadata.certifications.smetaExpDate', fieldType: 'date' },
              { fieldLabel: 'SMETA Certificate:', dbMapping: 'metadata.certifications.smetaUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'Better Work', dbMapping: 'metadata.certifications.betterWorkCheck', fieldType: 'dropdown' },
              { fieldLabel: 'Better Work Expiration Date:', dbMapping: 'metadata.certifications.betterWorkExpDate', fieldType: 'date' },
              { fieldLabel: 'Better Work Certificate:', dbMapping: 'metadata.certifications.betterWorkUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'BAP', dbMapping: 'metadata.certifications.bapCheck', fieldType: 'dropdown' },
              { fieldLabel: 'BAP Expiration Date:', dbMapping: 'metadata.certifications.bapExpDate', fieldType: 'date' },
              { fieldLabel: 'BAP Certificate:', dbMapping: 'metadata.certifications.bapUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'ISO 9001', dbMapping: 'metadata.certifications.iso9001Check', fieldType: 'dropdown' },
              { fieldLabel: 'ISO 9001 Expiration Date:', dbMapping: 'metadata.certifications.iso9001ExpDate', fieldType: 'date' },
              { fieldLabel: 'ISO 9001 Certificate:', dbMapping: 'metadata.certifications.iso9001Upload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'ISO 14001', dbMapping: 'metadata.certifications.iso14001Check', fieldType: 'dropdown' },
              { fieldLabel: 'ISO 14001 Expiration Date:', dbMapping: 'metadata.certifications.iso14001ExpDate', fieldType: 'date' },
              { fieldLabel: 'ISO 14001 Certificate', dbMapping: 'metadata.certifications.iso14001Upload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'ISO 18001', dbMapping: 'metadata.certifications.iso18001Check', fieldType: 'dropdown' },
              { fieldLabel: 'ISO 18001 Expiration Date:', dbMapping: 'metadata.certifications.iso18001ExpDate', fieldType: 'date' },
              { fieldLabel: 'ISO 18001 Certificate:', dbMapping: 'metadata.certifications.iso18001Upload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'BLUESIGN® system', dbMapping: 'metadata.certifications.bluesignCheck', fieldType: 'dropdown' },
              { fieldLabel: 'BLUESIGN® system Expiration Date:', dbMapping: 'metadata.certifications.bluesignExpDate', fieldType: 'date' },
              { fieldLabel: 'BLUESIGN® system Certificate:', dbMapping: 'metadata.certifications.bluesignUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'OEKO-TEX® Standard 100', dbMapping: 'metadata.certifications.okeotexStandard100Check', fieldType: 'dropdown' },
              { fieldLabel: 'OEKO-TEX® Standard 100 Expiration Date:', dbMapping: 'metadata.certifications.okeotexStandard100ExpDate', fieldType: 'date' },
              { fieldLabel: 'OEKO-TEX® Standard 100 Certificate:', dbMapping: 'metadata.certifications.okeotexStandard100Upload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'OEKO-TEX® Made In Green', dbMapping: 'metadata.certifications.okeotexMadeInGreenCheck', fieldType: 'dropdown' },
              { fieldLabel: 'OEKO-TEX® Made In Green Expiration Date:', dbMapping: 'metadata.certifications.okeotexMadeInGreenExpDate', fieldType: 'date' },
              { fieldLabel: 'OEKO-TEX® Made In Green Certificate:', dbMapping: 'metadata.certifications.okeotexMadeInGreenUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'OEKO-TEX® Detox to Zero', dbMapping: 'metadata.certifications.okeotexDetoxToZeroCheck', fieldType: 'dropdown' },
              { fieldLabel: 'OEKO-TEX® Detox to Zero Expiration Date:', dbMapping: 'metadata.certifications.okeotexDetoxToZeroExpDate', fieldType: 'date' },
              { fieldLabel: 'OEKO-TEX® Detox to Zero Certificate:', dbMapping: 'metadata.certifications.okeotexDetoxToZeroUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'OEKO-TEX® STeP', dbMapping: 'metadata.certifications.okeotexStepCheck', fieldType: 'dropdown' },
              { fieldLabel: 'OEKO-TEX® STeP Expiration Date:', dbMapping: 'metadata.certifications.okeotexStepExpDate', fieldType: 'date' },
              { fieldLabel: 'OEKO-TEX® STeP Certificate:', dbMapping: 'metadata.certifications.okeotexStepUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'OEKO-TEX® Eco-Passport', dbMapping: 'metadata.certifications.okeotexEcoPassportCheck', fieldType: 'dropdown' },
              { fieldLabel: 'OEKO-TEX® Eco-Passport Expiration Date:', dbMapping: 'metadata.certifications.okeotexEcoPassportExpDate', fieldType: 'date' },
              { fieldLabel: 'OEKO-TEX® Eco-Passport Certificate:', dbMapping: 'metadata.certifications.okeotexEcoPassportUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'ZDHC In-Check Report', dbMapping: 'metadata.certifications.zdhcReportCheck', fieldType: 'dropdown' },
              { fieldLabel: 'ZDHC In-Check Report:', dbMapping: 'metadata.certifications.zdhcReportUpload.fileUpload', fieldType: 'file' },
              { fieldLabel: 'ZDHC In-Check Report Expiration Date', dbMapping: 'metadata.certifications.zdhcReportExpDate', fieldType: 'date' },
            ]
          }
        ]
    
    return (
        <>
        <Dialog 
            open={open} 
            onClose={() => {
                //console.log("Dialog close triggered", { isLoading });
                !isLoading && onClose()
            }}
            maxWidth="lg" 
            fullWidth 
            disableEscapeKeyDown={isLoading}
        >
            <DialogContent sx={{ p: 3 }}>
                <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>Intake Form Details</Typography>
                
                <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'left', mb: 2, width: '80%' }}>
                    {/* Factory dropdown */}
                    <FormControl sx={{ flex: 1 }}>
                        <Select
                          value={selectedFactory?.factoryId || (selectedFactory === 'new' ? 'new' : '')}
                        onChange={handleFactoryChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Facility' }}
                        sx={{
                            borderRadius: 2,
                            backgroundColor: '#f5f5f5',
                            '& .MuiSelect-select': {
                                p: 1.5,
                            }
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            },
                        }}
                    >
                    <MenuItem value="" disabled>Select Facility</MenuItem>
                    <MenuItem value="new">Add as new facility</MenuItem>
                    {ENABLE_ASSIGN_EXISTING_FACTORY && factoryDropdownData && factoryDropdownData.length > 0 ? (
                        factoryDropdownData.map((factory) => (
                            <MenuItem key={factory.factoryId} value={factory.factoryId}>
                                {factory.name}
                            </MenuItem>
                        ))
                    ) : null}
                </Select>
                </FormControl>
                {/* Supplier dropdown */}
                <FormControl sx={{ flex: 1 }}>
                        <Select
                            value={selectedSupplier?.supplierId || (selectedSupplier === 'new' ? 'new' : '')}
                            onChange={handleSupplierChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Select Supplier' }}
                            sx={{
                                borderRadius: 2,
                                backgroundColor: '#f5f5f5',
                                '& .MuiSelect-select': {
                                    p: 1.5,
                                }
                            }}
                        >
                            <MenuItem value="" disabled>Select Supplier</MenuItem>
                            <MenuItem value="new">Add a new supplier</MenuItem>
                            {supplierDropdownData?.map((supplier) => (
                                <MenuItem key={supplier.supplierId} value={supplier.supplierId}>
                                    {supplier.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                {selectedFactory === 'new' && matchedFactory && (
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="body1" sx={{ mb: 1, fontWeight: 'bold' }}>
                            Facility has been created with the current pre-sourcing form.
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <a href={`/suppliers/${matchedFactory.supplierId}/factory/${matchedFactory.factoryId}`} target="_blank" rel="noopener noreferrer" style={{ color: '#4C5ED9', textDecoration: 'none', fontWeight: 'bold', fontFamily: 'Inter' }}>
                                {matchedFactory.name}
                            </a>
                            <img
                                style={{ paddingLeft: 8 }}
                                src="/ne_arrow.svg"
                                alt="arrow icon"
                            />
                        </Box>
                    </Box>
                )}
                <Box sx={{ mb: 3 }}>
                    <Button
                        variant="contained"
                        onClick={handleAssignSAQ}
                        disabled={!selectedFactory || !selectedSupplier || isLoading || isGlobalLoading || (selectedFactory === 'new' && matchedFactory)}
                        sx={{
                            backgroundColor: "#6172F3",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#5667e2",
                              fontWeight: "bold",
                            },
                            textTransform: "none",
                            minWidth: "79px",
                            height: "40px",
                            borderRadius: "8px",
                          }}
                    >
                        {(isLoading || isGlobalLoading) ? 'Processing...' : (selectedFactory === 'new' ? 'Create New Facility' : 'Create New Facility')}
                    </Button>
                </Box>
                
                {/* Note */}
                <Box 
                    sx={{ 
                        mb: 1,
                        p: 0,
                        //backgroundColor: '#F8F9FA',
                        //borderRadius: 1,
                        //border: '1px solid #E9ECEF'
                    }}
                >
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: '#6C757D',
                            fontStyle: 'italic'
                        }}
                    >
                        These are the responses submitted by the facility.
                    </Typography>
                </Box>


                {/* SAQ sections */}
                <Box sx={{ maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}>
                    {saqSections.map((section) => renderSection(section.name, section.fields))}
                </Box>
            </DialogContent>
            {/* Close button at the bottom, outside of scrollable area */}
            <DialogActions>
                <Button
                //variant="contained"
                onClick={onClose}
                sx={{
                    backgroundColor: "#6172F3",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#5667e2",
                      fontWeight: "bold",
                    },
                    textTransform: "none",
                    minWidth: "79px",
                    height: "40px",
                    borderRadius: "8px",
                  }}
                  size="small"
                >
                Close
                </Button>
            </DialogActions>
            {/* Confirmation dialog for updating existing factory */}
            {ENABLE_ASSIGN_EXISTING_FACTORY && (
            <Dialog
                open={openConfirmDialog}
                onClose={handleConfirmDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle id="alert-dialog-title">{"Confirm Update"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to update the existing factory with this SAQ data? This action will overwrite existing data.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDialogClose} color="primary">
                    Cancel
                    </Button>
                    <Button onClick={handleConfirmedUpdate} color="primary" autoFocus>
                    Confirm
                    </Button>
                </DialogActions>
                </Dialog>
            )}
        </Dialog>

        <Snackbar
        open={feedback.open}
        autoHideDuration={1500}
        onClose={handleCloseFeedback}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ zIndex: 9999 }}
        >
        <Alert
            onClose={handleCloseFeedback}
            severity={feedback.severity}
            sx={{ width: '100%' }}
        >
            {feedback.message}
        </Alert>
        </Snackbar>
        </>
    );
};

export default QuestionnaireDetailView;