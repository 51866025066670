import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
  Divider,
} from "@mui/material";

import { selectFactoryData } from "../../selectors/factory.selector";
import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import FacilityInformationDrawer from "./facilityInformationDrawer.component";
import FacilityTierMapDrawer from "./facilityTierMapDrawer.component";
import { getFactoryById } from "../../reducers/factory.reducer";
import { ComponentStyles, FacilityStyles } from "../../styles";
import FacilityStatusCard from "./facilityStatusCard.component";
import FacilityIRSSCard from "./facilityIRSSCard.component";
import FacilityLatestAuditInfo from "./facilityLatestAuditInfo.component";

const FacilityInformation = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTierMapDrawerOpen, setIsTierMapDrawerOpen] = useState(false);

  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );

  useEffect(() => {
    const loadFactoryData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        if (!selectedFactoryData) {
          await dispatch(
            getFactoryById({
              supplierId: params.supplierId,
              factoryId: params.factoryId,
            })
          );
        } else {
          setFactoryData(selectedFactoryData);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadFactoryData();
  }, [selectedFactoryData, params.supplierId, params.factoryId, dispatch]);

  useEffect(() => {
    if (selectedFactoryData) {
      setFactoryData(selectedFactoryData);
    }
  }, [selectedFactoryData]);

  const toggleTierMapDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsTierMapDrawerOpen(open);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!factoryData) {
    return <div>No facility data available.</div>;
  }

  // Extract fields (use placeholders if not found):
  const tier = factoryData?.metadata?.tier?.split(" ")[1] || "-";
  const facilityType = factoryData?.metadata?.processInfo?.supplierType || "-";
  const address = factoryData?.metadata?.addressLine1 || "-";
  const facilityIdVal = factoryData?.metadata?.factoryId || "-";
  const reporting = factoryData?.metadata?.doNotReportCheck === true ? "Yes" : "No";
  const domesticOrInternational = factoryData?.metadata?.domesticOrInternational || "-";
  const deMinimisCheck = factoryData?.metadata?.deMinimis?.deMinimisCheck || "-";
  const deMinimisYear = factoryData?.metadata?.deMinimis?.deMinimisYear || "-";
  const subcontractorUsed = factoryData?.metadata?.processInfo?.productionContractType?.includes("Subcontractor") ? "Yes" : "No";
  const subcontractorDetails = factoryData?.metadata?.processInfo?.subcontractorDetails || "-";

  const contactName = factoryData?.metadata?.primaryFacilityContactPerson?.name || "-";
  const contactPosition = factoryData?.metadata?.primaryFacilityContactPerson?.position || "-";
  const contactPhone = factoryData?.metadata?.primaryFacilityContactPerson?.phone || "-";
  const contactEmail = factoryData?.metadata?.primaryFacilityContactPerson?.email || "-";

  const agentName = factoryData?.metadata?.agentContact?.name || "-";
  const agentEmail = factoryData?.metadata?.agentContact?.email || "-";
  const agentPhone = factoryData?.metadata?.agentContact?.phone || "-";
  const agentAddress = factoryData?.metadata?.agentContact?.address || "-";

  const scContact = factoryData?.metadata?.supplyChainContact || "-";

  return (
    <Box sx={{ pb: 2, mb: 4, backgroundColor: "#FFF", px: 4, pt: 2, borderRadius: "12px", boxShadow: 1 }}>
      <Box sx={{ mb: 4 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
          <Typography sx={FacilityStyles.headerOfTheCard}>
            Facility Basic Information
          </Typography>
          <Button
            variant="outlined"
            onClick={toggleTierMapDrawer(true)}
            sx={{
              color: "#6172F3",
              "&:hover": {
                color: "white",
                backgroundColor: "#5667e2",
              },
              textTransform: "none",
              width: "100px",
              height: "40px",
              borderRadius: "8px",
              ml: 2,
            }}
          >
            Tier Map
          </Button>
        </Box>

          {/* First row: Approved Suppliers, Tier, Facility Type, Address, Facility ID */}
          <Grid container spacing={10} sx={{ mb: 3 }}>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Approved Suppliers:</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                <Link to={`/suppliers/${factoryData?.supplierId}`} style={FacilityStyles.linkText}>
                  {factoryData?.supplierName || supplierName || "-"}
                  <img style={{ paddingLeft: 8 }} src="/ne_arrow.svg" alt="arrow icon" />
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Tier</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{tier}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Facility Type</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{facilityType}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={ComponentStyles.fieldName}>Address</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{address}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Facility ID</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{facilityIdVal}</Typography>
            </Grid>
          </Grid>

          {/* Second row: Reporting, Domestic/International, DeMinimis?, If DeMinimis year, Subcontractor Used, Subcontractor Details */}
          <Grid container spacing={10} sx={{ mb: 3 }}>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Reporting</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{reporting}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Domestic/International</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{domesticOrInternational}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>DeMinimis?</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{deMinimisCheck}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>If DeMinimis, year</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{deMinimisYear}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Subcontractor Used</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{subcontractorUsed}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Subcontractor Details</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{subcontractorDetails}</Typography>
            </Grid>
          </Grid>

          {/* Third row: Contact Name, Position, Phone, Email */}
          <Grid container spacing={10} sx={{ mb: 3 }}>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Contact Name</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{contactName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Position</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{contactPosition}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{contactPhone}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Email</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{contactEmail}</Typography>
            </Grid>
          </Grid>

          {/* Fourth row: Agent Contact Name, Agent Email, Agent Phone Number, Agent Address */}
          <Grid container spacing={10} sx={{ mb: 3 }}>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Agent Contact Name</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{agentName}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Agent Email</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{agentEmail}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Agent Phone Number</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{agentPhone}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={ComponentStyles.fieldName}>Agent Address</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{agentAddress}</Typography>
            </Grid>
          </Grid>
          { scContact && (
          <Grid container spacing={10} sx={{ mb: 3 }}>
            <Grid item xs={2}>
              <Typography sx={ComponentStyles.fieldName}>Supply Chain Contact</Typography>
              <Typography sx={ComponentStyles.fieldValue}>{scContact}</Typography>
            </Grid>
          </Grid>
          )}
      </Box>

      <Grid container spacing={2}>
        {/* Right column top: Facility Status and IRSS stacked */}
        <Grid item xs={12} md={12}>
          <Grid container spacing={0} direction="column">
            <Grid item>
              <FacilityStatusCard factoryData={factoryData} />
            </Grid>
            <Grid item>
              <FacilityIRSSCard factoryData={factoryData} />
            </Grid>
          </Grid>
        </Grid>

        {/* Full width below: Latest Audit Info */}
        <Grid item xs={12}>
          <FacilityLatestAuditInfo factoryData={factoryData} />
        </Grid>
      </Grid>

      <FacilityInformationDrawer
        factoryData={factoryData}
        isDrawerOpen={false} // currently not used?
        toggleDrawer={() => {}}
      />
      <FacilityTierMapDrawer
        factoryData={factoryData}
        isDrawerOpen={isTierMapDrawerOpen}
        toggleDrawer={toggleTierMapDrawer}
      />
    </Box>
  );
};

export default FacilityInformation;