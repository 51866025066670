import React, { useState, useEffect, useRef } from 'react';
import { Button, List, ListItem, ListItemText, Box, Grid, TextField, MenuItem, ClickAwayListener, Popper, Paper } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { selectUserInfo } from "../selectors/login.selector";
import { selectFactoryData } from "../selectors/factory.selector";
import { updateFactoryData } from "../reducers/factory.reducer";
import { updateSupplierData } from '../reducers/supplier.reducer';
import { selectUsers } from "../selectors/user.selector";
import { getUsersByOrganisationId } from "../reducers/user.reducer";
import { selectSupplierData } from '../selectors/supplier.selector';
import { sendEmailToMentioned } from '../reducers/email.reducer';

const CommentSection = ({ source, type }) => {
    const userInfo = useSelector(selectUserInfo);
    const factoryInfo = useSelector(selectFactoryData);
    const supplierInfo = useSelector(selectSupplierData);
    const dispatch = useDispatch();

    const [comment, setComment] = useState('');
    const [comments, setComments] = useState(() => {
        const selectedInfo = source === "supplier" ? supplierInfo : factoryInfo;
        return Array.isArray(selectedInfo?.metadata?.internalStatusNotes)
            ? selectedInfo.metadata.internalStatusNotes
            : [{ author: "Previously Migrated Note", comment: selectedInfo?.metadata?.internalStatusNotes, createdAt: "" }];
    });

    const users = useSelector(selectUsers);
    const [mentionUsers, setMentionUsers] = useState([]);

    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [mentionQuery, setMentionQuery] = useState('');
    const [cursorPosition, setCursorPosition] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [mentionedUsers, setMentionedUsers] = useState([]);
    const textFieldRef = useRef(null);
    const dropdownRef = useRef(null);

    useEffect(() => {
        dispatch(getUsersByOrganisationId());
    }, [dispatch]);

    useEffect(() => {
        setMentionUsers(users.map(user => ({ id: user._id, name: `${user.firstName} ${user.lastName}`, email: user.email })));
    }, [users]);

    useEffect(() => {
        if (source === 'supplier') {
            setComments(Array.isArray(supplierInfo?.metadata?.internalStatusNotes) ? supplierInfo?.metadata?.internalStatusNotes : [{ "author": "Previously Migrated Note", "comment": supplierInfo?.metadata?.internalStatusNotes, "createdAt": "" }]);
        } else {
            setComments(Array.isArray(factoryInfo?.metadata?.internalStatusNotes) ? factoryInfo?.metadata?.internalStatusNotes : [{ "author": "Previously Migrated Note", "comment": factoryInfo?.metadata?.internalStatusNotes, "createdAt": "" }]);
        }
    }, [supplierInfo?.metadata?.internalStatusNotes, factoryInfo?.metadata?.internalStatusNotes]);

    const handleCommentChange = (e) => {
        const value = e.target.value;
        const selectionStart = e.target.selectionStart;
        setComment(value);

        setMentionedUsers(prevMentionedUsers =>
            prevMentionedUsers.filter(user => value.includes(`@${user.name}`))
        );

        const lastAtIndex = value.lastIndexOf('@', selectionStart - 1);
        if (lastAtIndex !== -1 && (lastAtIndex === 0 || /\s/.test(value.charAt(lastAtIndex - 1)))) {
            const query = value.slice(lastAtIndex + 1, selectionStart);
            setMentionQuery(query);
            const filtered = mentionUsers.filter(user =>
                user.name.toLowerCase().startsWith(query.toLowerCase()) &&
                !mentionedUsers.some(mentioned => mentioned.id === user.id)
            );
            setFilteredUsers(filtered);
            setShowDropdown(true);
            setCursorPosition(lastAtIndex);
        } else {
            setShowDropdown(false);
            setMentionQuery('');
            setFilteredUsers([]);
            setSelectedIndex(0);
        }
    };

    const handleUserSelect = (user) => {
        const textarea = textFieldRef.current;
        const value = comment;
        const selectionEnd = textarea.selectionEnd;
        const mentionText = `@${user.name} `;
        const before = value.substring(0, cursorPosition);
        const after = value.substring(selectionEnd);
        const newValue = before + mentionText + after;
        setComment(newValue);
        setShowDropdown(false);
        setMentionQuery('');
        setFilteredUsers([]);
        setSelectedIndex(0);

        setMentionedUsers(prevMentionedUsers => {
            if (!prevMentionedUsers.some(u => u.id === user.id)) {
                return [...prevMentionedUsers, user];
            }
            return prevMentionedUsers;
        });

        const newCursorPosition = before.length + mentionText.length;
        setTimeout(() => {
            textarea.setSelectionRange(newCursorPosition, newCursorPosition);
            textarea.focus();
        }, 0);
    };

    const handleKeyDown = (e) => {
        console.log(mentionedUsers)
        if (showDropdown && filteredUsers.length > 0) {
            if (e.key === 'ArrowDown') {
                e.preventDefault();
                setSelectedIndex((prevIndex) => (prevIndex + 1) % filteredUsers.length);
            } else if (e.key === 'ArrowUp') {
                e.preventDefault();
                setSelectedIndex((prevIndex) => (prevIndex - 1 + filteredUsers.length) % filteredUsers.length);
            } else if (e.key === 'Enter' || e.key === 'Tab') {
                e.preventDefault();
                handleUserSelect(filteredUsers[selectedIndex]);
            } else if (e.key === 'Escape') {
                setShowDropdown(false);
            }
        }
    };

    const handlePostComment = () => {
        if (source === 'supplier') {
            if (!comment) return;

            const currentDate = new Date();
            const isoString = currentDate.toISOString();
            const changes = { "internalStatusNotes": [{ "comment": comment, "author": userInfo.name, "createdAt": isoString }, ...comments] }

            if (Object.keys(changes).length > 0) {
                dispatch(
                    updateSupplierData({
                        supplierId: supplierInfo?._id,
                        changes,
                    })
                );
            }

            setComment('');
        } else {
            if (!comment) return;

            const currentDate = new Date();
            const isoString = currentDate.toISOString();
            const changes = { "metadata.internalStatusNotes": [{ "comment": comment, "author": userInfo.name, "createdAt": isoString }, ...comments] }

            if (Object.keys(changes).length > 0) {
                dispatch(
                    updateFactoryData({
                        organisationId: factoryInfo?.organisationId,
                        supplierId: factoryInfo?.supplierId,
                        factoryId: factoryInfo?._id,
                        changes,
                    })
                );
            }
            setComment('');
        }

        if (mentionedUsers.length > 0) {
            const mentionedEmails = mentionedUsers.map(user => user.email);
            console.log(JSON.stringify({
                organisationId: source === 'supplier' ? supplierInfo?.organisationId : factoryInfo?.organisationId,
                supplierId: supplierInfo?._id,
                factoryId: factoryInfo?._id,
                supplierName: supplierInfo?.name,
                factoryName: factoryInfo?.name,
                mentionedEmails,
                commentAuthor: userInfo.name,
                comment
            }));
            dispatch(sendEmailToMentioned({
                organisationId: source === 'supplier' ? supplierInfo?.organisationId : factoryInfo?.organisationId,
                supplierId: supplierInfo?._id,
                factoryId: factoryInfo?._id,
                supplierName: supplierInfo?.name,
                factoryName: factoryInfo?.name,
                mentionedEmails,
                commentAuthor: userInfo.name,
                comment
            }));
        }
        setMentionedUsers([]);
    };

    const handleClickAway = (event) => {
        if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
            return;
        }
        setShowDropdown(false);
    };

    return (
        <Box sx={{ width: '100%', margin: 'auto', pl: 2, pr: 2, fontFamily: 'Inter, sans-serif' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Box
                        sx={{
                            height: 220,
                            width: '100%',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            border: '1px solid #ccc',
                            borderRadius: 1,
                            p: 1,
                            bgcolor: '#fafafa',
                        }}
                    >
                        <List>
                            {comments.map((commentItem, index) => (
                                <ListItem key={index} alignItems="flex-start" sx={{ paddingTop: '0px' }}>
                                    <ListItemText
                                        primary={commentItem.comment}
                                        secondary={
                                            commentItem.author === 'Previously Migrated Note'
                                                ? `${commentItem.author}`
                                                : `${commentItem.author} (${new Date(commentItem.createdAt).toLocaleString()})`
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Grid>
                {type !== 'view' && (
                    <Grid item xs={12} md={4}>
                        <ClickAwayListener onClickAway={handleClickAway}>
                            <Box>
                                <TextField
                                    label="Your comment"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    value={comment}
                                    onChange={handleCommentChange}
                                    onKeyDown={handleKeyDown}
                                    inputRef={textFieldRef}
                                />
                                {showDropdown && (
                                    <Popper open anchorEl={textFieldRef.current} style={{ zIndex: 1500 }}>
                                        <Paper ref={dropdownRef} style={{ maxHeight: 200, overflowY: 'auto' }}>
                                            {filteredUsers.map((user, index) => (
                                                <MenuItem
                                                    key={user.id}
                                                    selected={index === selectedIndex}
                                                    onClick={() => handleUserSelect(user)}
                                                >
                                                    {user.name}
                                                </MenuItem>
                                            ))}
                                        </Paper>
                                    </Popper>
                                )}
                            </Box>
                        </ClickAwayListener>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePostComment}
                            disabled={!comment}
                            sx={{ mt: 2 }}
                        >
                            Post
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default CommentSection;