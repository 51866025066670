import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button, Snackbar } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import RenderFormFields from "../renderFormFields"; // Adjust path as needed
import CommentSection from "../commentSection.component"; // Adjust path as needed
import { Alert } from "@mui/material";
import { updateAuditData } from "../../reducers/audit.reducer"; 
import { selectAuditData } from "../../selectors/audit.selector";
import { getChangedPaths, getValue } from "../../utils/lib";

const IRSSTab = ({ facilityName, supplierName }) => {
  const dispatch = useDispatch();
  const selectedAuditData = useSelector(selectAuditData);

  console.log("IRSSTab rendered. Selected audit data:", selectedAuditData);

  // Clone auditData.metadata into state
  const initialData = selectedAuditData?.metadata ? _.cloneDeep(selectedAuditData.metadata) : {};
  const [irssFormData, setIrssFormData] = useState(initialData);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    console.log("useEffect triggered because selectedAuditData changed");
    const newInitial = selectedAuditData?.metadata ? _.cloneDeep(selectedAuditData.metadata) : {};
    console.log("New initial data for IRSS form:", newInitial);
    setIrssFormData(newInitial);
  }, [selectedAuditData]);

  const handleIRSSChange = (name, value) => {
    console.log("handleIRSSChange called. name:", name, "value:", value);
    const newData = _.cloneDeep(irssFormData);
    _.set(newData, name, value);
    console.log("Updated irssFormData:", newData);
    setIrssFormData(newData);
  };

  const handleSubmit = () => {
    console.log("handleSubmit called.");
    const currentInitial = selectedAuditData?.metadata || {};
    const changes = getChangedPaths(irssFormData, currentInitial);
    console.log("Computed changes:", changes);

    if (Object.keys(changes).length > 0) {
      console.log("Dispatching updateAuditData with changes:", changes);
      dispatch(
        updateAuditData({
          organisationId: selectedAuditData?.organisationId,
          supplierId: selectedAuditData?.supplierId,
          factoryId: selectedAuditData?.factoryId,
          auditId: selectedAuditData?._id,
          changes,
        })
      );
      setSnackbarMessage('Changes saved successfully');
      setSnackbarSeverity('success');
    } else {
      console.log("No changes detected, not dispatching updateAuditData.");
      setSnackbarMessage('No changes detected');
      setSnackbarSeverity('info');
    }
    setSnackbarOpen(true);
  };

  const irssInfoFields = [
    { fieldLabel: 'Audit Type', dbMapping: 'auditTypeFrequency', fieldType: 'read-only' },
    { fieldLabel: 'Facility Name', dbMapping: '', fieldType: 'read-only' },
    { fieldLabel: 'Supplier Name', dbMapping: '', fieldType: 'read-only' },
    { fieldLabel: 'IRSS Status', dbMapping: 'irss.status', fieldType: 'dropdown', dropdownOptions: ['Waiting to be enrolled', 'Currently enrolled', 'Program ending soon', 'Program ended', 'Program cancelled'] },
    { fieldLabel: 'IRSS Details', dbMapping: 'irss.details', fieldType: 'string' },
    { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
    { fieldLabel: 'IRSS Start Date', dbMapping: 'irss.startDate', fieldType: 'date' },
    { fieldLabel: 'IRSS End Date', dbMapping: 'irss.endDate', fieldType: 'date' },
    { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
    { fieldLabel: 'IRSS Consultant Name', dbMapping: 'irss.consultantName', fieldType: 'string' },
    { fieldLabel: 'IRSS Consultant Email', dbMapping: 'irss.consultantEmail', fieldType: 'string' },
    { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
    { fieldLabel: 'IRSS Cost ($)', dbMapping: 'irss.cost', fieldType: 'string' },
    { fieldLabel: '% by Reformation (Ex. 20 for 20%)', dbMapping: 'irss.percentByCustomer', fieldType: 'string' },
    { fieldLabel: '% by Facility (Auto-calculated)', dbMapping: 'irss.percentByFacility', fieldType: 'read-only' },
  ];

  const postIRSSFields = [
    { fieldLabel: 'Post IRSS Rating', dbMapping: 'irss.postIRSSRating', fieldType: 'dropdown', dropdownOptions: ['Green (A)', 'Yellow (B)', 'Orange (C)', 'Red (D)'] },
    { fieldLabel: 'Recommended Next Audit Date', dbMapping: 'irss.nextAuditDate', fieldType: 'date-month' },
  ];

  const renderFieldsSection = (title, fields) => {
    console.log(`Rendering fields section: ${title}`);
    return (
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: 16, color: "#344054", mb: 2 }}>
          {title}
        </Typography>
        <Grid container spacing={3}>
          {fields.map((field, index) => {
            let fieldValue = '-';
            if (field.fieldType === 'read-only') {
              if (field.fieldLabel === 'Facility Name') {
                fieldValue = facilityName || '-';
              } else if (field.fieldLabel === 'Supplier Name') {
                fieldValue = supplierName || '-';
              } else if (field.dbMapping) {
                fieldValue = getValue(irssFormData, field.dbMapping, '-');
              } else {
                fieldValue = '-';
              }
            } else {
              fieldValue = getValue(irssFormData, field.dbMapping, '-');
            }

            return (
              <Grid item xs={4} key={index}>
                {RenderFormFields({
                  field,
                  value: fieldValue,
                  handleChange: (dbMapping, newVal) => {
                    console.log("Field changed:", dbMapping, "newVal:", newVal);
                    if (dbMapping) handleIRSSChange(dbMapping, newVal);
                  },
                  formData: irssFormData,
                  signedUrls: {},
                  dispatch: () => {},
                  languages: [],
                  handleFileClick: () => {},
                  factoryData: null,
                  uploadDialogOpen: { open: false, fieldKey: null },
                  setUploadDialogOpen: () => {}
                })}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  return (
    <Box sx={{ p: 4, position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          startIcon={<SaveIcon />}
          sx={{
            backgroundColor: '#585aeb',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#4648c4',
            },
          }}
        >
          SAVE CHANGES
        </Button>
      </Box>

      <Typography variant="body1" sx={{ color: 'red', mb: 2, textAlign: 'left' }}>
        Please save changes before moving to comments section.
      </Typography>

      {renderFieldsSection("IRSS Info", irssInfoFields)}
      {renderFieldsSection("Post IRSS", postIRSSFields)}

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" sx={{ fontWeight: 700, fontSize: 16, color: "#344054", mb: 2 }}>
          Comment
        </Typography>
        <CommentSection source="audit" />
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => {
          console.log("Snackbar closed.");
          setSnackbarOpen(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default IRSSTab;