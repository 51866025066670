import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import { ComponentStyles } from "../../styles";
import OpenCAPCard from "../openCapCard.component";
import SimpleActionCard from "../simpleActionCard.component";
import moment from "moment";
import { updateFactoryData } from "../../reducers/factory.reducer";

const computeDueInDays = (date) => {
  //console.log("date", date);
  return moment(date).diff(moment(), "days");
};

const FactoryActionNeeded = ({ 
  capDetails = [], 
  certificateDetails, 
  rating, 
  irssDetails, 
  upcomingAuditDate, 
  nextAssessmentWindowDateStart,
  nextAssessmentWindowDateEnd,
  triggersObject,
  auditNotConfirmedMessage,
  factoryId, 
  supplierId  }) => {

  const irssEnrollmentTriggered = triggersObject.irssEnrollmentTriggered;
  const irssProgramEndTriggered = triggersObject.irssProgramEndTriggered;
  const certificationTriggered = triggersObject.certificationTriggered;
  const auditTriggered = triggersObject.auditTriggered;
  const auditNotConfirmedTriggered = triggersObject.auditNotConfirmedTriggered;
  const capTriggered = triggersObject.capTriggered;
  const wccTriggered = triggersObject.wccTriggered;
  const licenseTriggered = triggersObject.licenseTriggered;

  // console.log("irssEnrollmentTriggered", irssEnrollmentTriggered);
  // console.log("irssProgramEndTriggered", irssProgramEndTriggered);
  // console.log("certificationTriggered", certificationTriggered);
  // console.log("auditTriggered", auditTriggered);
  // console.log("capTriggered", capTriggered);
  
  const [irssStatus, setIrssStatus] = useState(irssDetails?.status || "Waiting to be enrolled");
  useEffect(() => {
    if (irssDetails?.status) {
      setIrssStatus(irssDetails.status);  // Update the state when irssDetails changes
    }
  }, [irssDetails]);  // Only run this when irssDetails is updated

  const dispatch = useDispatch();
  const handleStatusChange = (newStatus) => {
    setIrssStatus(newStatus);
    // Dispatch the action to update the factory data with the new IRSS status
    dispatch(
      updateFactoryData({
        supplierId, 
        factoryId, 
        changes: { 
          "irssStatus": newStatus 
        }
      })
    );
  };

  
  return (
    <>
      <Typography sx={{ fontWeight: 700, fontSize: 18, color: "#000", my: 3 }}>
        Action Needed
      </Typography>

      <Grid container spacing={2}>
        {/* IRSS Card */}
        {(irssEnrollmentTriggered || irssProgramEndTriggered) && (
          <Grid item xs={12} md={6}>
            <SimpleActionCard
              type="irss"
              title="IRSS"
              rows={[
                { dueInDays: irssStatus, alert: "The facility will enroll in the IRSS program" },
              ]}
              handleIrssChange={handleStatusChange}
              irssEnrollmentTriggered={irssEnrollmentTriggered}
              irssProgramEndTriggered={irssProgramEndTriggered}
              irssProgramEndDate={irssDetails?.programEndDate}
            />
          </Grid>
        )}

        {/* Certificate and License Card */}
        {certificationTriggered && (() => {
          const rows = [];
          if (wccTriggered) {
            rows.push({
              dueInDays: computeDueInDays(certificateDetails.wcc?.wccExpDate),
              alert: "WCC"
            });
          }
          if (licenseTriggered) {
            rows.push({
              dueInDays: computeDueInDays(certificateDetails.businessLicenseInfo?.licenseExpDate),
              alert: "Business license"
            });
          }
          return (
            <Grid item xs={12} md={6}>
              <SimpleActionCard
                type="license"
                title="Certificate and License"
                rows={rows}
                triggered={certificationTriggered}
              />
            </Grid>
          );
        })()}

        {/* Upcoming Audit*/}
        {auditTriggered && (
          <Grid item xs={12} md={6}>
            <SimpleActionCard
              type="upcomingAudit"
              title="Upcoming Audit"
              rows={[
                { dueInDays: computeDueInDays(upcomingAuditDate), alert: moment(upcomingAuditDate).format('MMM. D, YYYY') },
              ]}
              triggered={auditTriggered}
            />
          </Grid>
        )}

        {/* Audit Not Confirmed */}
        {auditNotConfirmedTriggered && (
          <Grid item xs={12} md={6}>
            <SimpleActionCard
              type="auditNotConfirmed"
              title="No Confirmed Audit Date"
              rows={[
                {
                  dueInDays: computeDueInDays(nextAssessmentWindowDateStart), alert: auditNotConfirmedMessage,
                },
              ]}
              triggered={auditNotConfirmedTriggered}
            />
          </Grid>
        )}

        {/* CAP Card */}
        {capTriggered && (
          <Grid item xs={12} md={12}>
            <OpenCAPCard capDetails={capDetails} triggered={capTriggered} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FactoryActionNeeded;