import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Divider,
  Dialog,
  DialogContentText,
  DialogTitle,
  DialogContent,
  DialogActions,
  Skeleton,
} from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Tooltip, Chip } from "@mui/material";
import {
  fetchSAQSuppliers,
  deleteSAQSupplier,
} from "../../reducers/supplier.reducer";
import { selectSAQSuppliers } from "../../selectors/supplier.selector";
import { selectSupplierDropdownData } from "../../selectors/supplier.selector";
import { selectOrganisationId } from "../../selectors/login.selector";
import SupplierQuestionnaireDetailView from "./supplierQuestionnaireDetails.component";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { loading as setLoading } from "../../reducers/misc.reducer";
import { selectLoadingState } from "../../selectors/misc.selector";

const SupplierQuestionnaireTable = () => {
  const dispatch = useDispatch();
  const saqSuppliers = useSelector(selectSAQSuppliers);
  const supplierDropdownData = useSelector(selectSupplierDropdownData) || [];

  const organisationId = useSelector(selectOrganisationId);
  const [selectedSAQ, setSelectedSAQ] = useState(null);
  const [rows, setRows] = useState([]);

  const isLoading = useSelector(selectLoadingState);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    supplierId: null,
  });

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(fetchSAQSuppliers());
  }, [dispatch, organisationId]);

  useEffect(() => {
    if (saqSuppliers && saqSuppliers.length > 0) {
      dispatch(setLoading(false));
    }
  }, [saqSuppliers, dispatch]);

  useEffect(() => {
    // When saqSuppliers changes, update the rows
    const updatedRows = saqSuppliers.map((supplier) => ({
      id: supplier.supplierId,
      createdAt: supplier.createdAt,
      name: supplier.name,
      country: supplier.metadata?.country,
      parentCompany: supplier.metadata?.parentCompanyName,
      tier: supplier.metadata?.tier,
      assignedToSupplier: supplier.assignedToSupplier,
      supplyChainContact: supplier.metadata?.supplyChainContact,
      supplyChainTeam: supplier.metadata?.supplyChainTeam,
      onboardingStatus: supplier.metadata?.onboardingStatus,
      ndaStatus: {
        hasFile: supplier.metadata?.nda?.fileUpload?.length > 0,
        isChecked: supplier.metadata?.nda?.ndaCheck === true
      },
      spgStatus: {
        hasFile: supplier.metadata?.spg?.fileUpload?.length > 0,
        isChecked: supplier.metadata?.spg?.spgCheck === true
      },
      saqContent: "",
    }));
    setRows(updatedRows);
  }, [saqSuppliers]);

  const DeleteConfirmationDialog = () => {
    const supplierToDelete = saqSuppliers.find(
        (s) => s.supplierId === deleteConfirmation.supplierId
      );  
      const documentStatus = [
        {
          label: "NDA",
          uploaded: supplierToDelete?.metadata?.nda?.fileUpload?.length > 0,
          checked: supplierToDelete?.metadata?.nda?.ndaCheck === true
        },
        {
          label: "SPG",
          uploaded: supplierToDelete?.metadata?.spg?.fileUpload?.length > 0,
          checked: supplierToDelete?.metadata?.spg?.spgCheck === true
        },
      ];
      return (
        <Dialog
          open={deleteConfirmation.open}
          onClose={() => setDeleteConfirmation({ open: false, supplierId: null })}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle
            sx={{
              fontSize: "1.125rem",
              fontWeight: 600,
              color: "#101828",
              borderBottom: "1px solid #EAECF0",
              pb: 2,
            }}
          >
            Confirm Delete
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <DialogContentText sx={{ color: "#344054", mb: 3 }}>
              Are you sure you want to delete this supplier presourcing form? This action
              cannot be undone.
            </DialogContentText>
            <Box
              sx={{
                p: 3,
                bgcolor: "#F9FAFB",
                border: "1px solid #EAECF0",
                borderRadius: "12px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: "#101828",
                  fontWeight: 600,
                  mb: 2,
                }}
              >
                Supplier Details
              </Typography>
  
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
                <Box>
                  <Typography
                    sx={{ fontSize: "0.75rem", color: "#667085", mb: 0.5 }}
                  >
                    Time Received
                  </Typography>
                  <Typography sx={{ fontSize: "0.875rem", color: "#101828" }}>
                    {supplierToDelete?.createdAt
                      ? moment(supplierToDelete.createdAt).format(
                          "MMM DD, YYYY, h:mm A"
                        )
                      : "-"}
                  </Typography>
                </Box>
  
                {/* Existing facility details */}
                <Box>
                  <Typography
                    sx={{ fontSize: "0.75rem", color: "#667085", mb: 0.5 }}
                  >
                    Supplier Name
                  </Typography>
                  <Typography sx={{ fontSize: "0.875rem", color: "#101828" }}>
                    {supplierToDelete?.name || "-"}
                  </Typography>
                </Box>
  
                <Box>
                  <Typography
                    sx={{ fontSize: "0.75rem", color: "#667085", mb: 0.5 }}
                  >
                    Location
                  </Typography>
                  <Typography sx={{ fontSize: "0.875rem", color: "#101828" }}>
                    {supplierToDelete?.metadata?.country || "-"}
                  </Typography>
                </Box>
  
                <Box>
                  <Typography
                    sx={{ fontSize: "0.75rem", color: "#667085", mb: 0.5 }}
                  >
                    Contact
                  </Typography>
                  <Typography sx={{ fontSize: "0.875rem", color: "#101828" }}>
                    {supplierToDelete?.metadata?.supplyChainContact || "-"}
                    {supplierToDelete?.metadata?.supplyChainContactEmail
                      ? ` (${supplierToDelete.metadata.supplyChainContactEmail})`
                      : ""}
                  </Typography>
                </Box>
  
                {/* Document upload status */}
                <Box>
                  <Typography
                    sx={{ fontSize: "0.75rem", color: "#667085", mb: 0.5 }}
                  >
                    Documents Uploaded
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                    {documentStatus.map((doc) => (
                      <Chip
                        key={doc.label}
                        label={doc.label}
                        size="small"
                        icon={
                          !doc.uploaded ? (
                            <CancelIcon sx={{ fontSize: "16px" }} />
                          ) : (
                            <CheckCircleIcon sx={{ fontSize: "16px" }} />
                          )
                        }
                        sx={{
                          backgroundColor: !doc.uploaded ? "#FEF3F2" : 
                                          doc.checked ? "#ECFDF3" : "#FFF8E6",
                          color: !doc.uploaded ? "#B42318" : 
                                 doc.checked ? "#027A48" : "#B54708",
                          "& .MuiChip-icon": {
                            color: !doc.uploaded ? "#F04438" : 
                                   doc.checked ? "#12B76A" : "#FDB022",
                          },
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 3, borderTop: "1px solid #EAECF0" }}>
            <Button
              onClick={() =>
                setDeleteConfirmation({ open: false, supplierId: null })
              }
              sx={{
                color: "#344054",
                fontSize: "0.875rem",
                fontWeight: 500,
                "&:hover": {
                  backgroundColor: "#F2F4F7",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                dispatch(
                  deleteSAQSupplier({ supplierId: deleteConfirmation.supplierId })
                );
                setDeleteConfirmation({ open: false, supplierId: null });
              }}
              sx={{
                backgroundColor: "#D92D20",
                color: "white",
                fontSize: "0.875rem",
                fontWeight: 500,
                "&:hover": {
                  backgroundColor: "#B42318",
                },
              }}
              variant="contained"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      );
    };

  // Define table columns
  const columns = [
    {
        field: "createdAt",
        headerName: "Time Received",
        width: 200,
        renderCell: (params) => {
          // Use moment to format the date
          return moment(params.value).format("MMM DD, YYYY, h:mm A"); // e.g., "Oct 16, 2024"
        },
    },
    {
      field: "name",
      headerName: "Supplier Name",
      width: 200,
    },
    {
      field: "country",
      headerName: "Country",
      width: 150,
    },
    {
      field: "supplyChainTeam",
      headerName: "Supply Chain Team",
      width: 150,
    },
    {
      field: "supplyChainContact",
      headerName: "Supply Chain Contact",
      width: 180,
    },
    {
      field: "onboardingStatus",
      headerName: "Onboarding Status",
      width: 150,
    },
    {
        field: "ndaStatus",
        headerName: "NDA",
        width: 50,
        renderCell: (params) => {
          if (!params.value.hasFile) {
            return <CancelIcon sx={{ color: "#d32f2f" }} />;
          }
          return params.value.isChecked ? (
            <CheckCircleIcon sx={{ color: "#4CAF50" }} />
          ) : (
            <CheckCircleIcon sx={{ color: "#FFC107" }} />  // Yellow for uploaded but not checked
          );
        }
    },
    {
        field: "spgStatus",
        headerName: "SPG",
        width: 50,
        renderCell: (params) => {
          if (!params.value.hasFile) {
            return <CancelIcon sx={{ color: "#d32f2f" }} />;
          }
          return params.value.isChecked ? (
            <CheckCircleIcon sx={{ color: "#4CAF50" }} />
          ) : (
            <CheckCircleIcon sx={{ color: "#FFC107" }} />  // Yellow for uploaded but not checked
          );
        }
    },
    // {
    //   field: "parentCompany",
    //   headerName: "Parent Company Name",
    //   width: 200,
    // },
    {
      field: "tier",
      headerName: "Type",
      width: 130,
    },
    {
        field: "assignedToSupplier",
        headerName: "Assigned to Supplier",
        width: 220,
        renderCell: (params) => {
          // Find the supplier in supplierDropdownData by supplier ID
          const assignedSupplier = supplierDropdownData.find(
            (supplier) => supplier.supplierId === params.value
          );
          // Construct the link if the supplier exists
          if (assignedSupplier) {
            const supplierId = assignedSupplier.supplierId;
            const link = `/suppliers/${supplierId}`;
  
            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <a
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#4C5ED9",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  {assignedSupplier.name}
                </a>
                <img
                  style={{ paddingLeft: 8 }}
                  src="/ne_arrow.svg"
                  alt="arrow icon"
                />
              </Box>
            );
          }
  
          return "-";
        },
      },
      {
        field: "saqContent",
        headerName: "Presourcing Form Content",
        width: 320,
        renderCell: (params) => (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#4C5ED9",
                color: "white",
                width: "75px",
                borderRadius: "8px",
                padding: "8px 16px",
                fontWeight: 500,
                fontSize: "0.875rem",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#3B4BBA",
                },
              }}
              onClick={() => {
                const fullSupplierData = saqSuppliers.find(
                  (supplier) => supplier.supplierId === params.row.id
                );
                setSelectedSAQ(fullSupplierData);
              }}
            >
              View
            </Button>
            {!params.row.assignedToSupplier && (
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#D92D20",
                  width: "75px",
                  color: "#D92D20",
                  borderRadius: "8px",
                  padding: "8px 16px",
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#FEE4E2",
                    borderColor: "#D92D20",
                  },
                }}
                onClick={() => {
                  setDeleteConfirmation({
                    open: true,
                    supplierId: params.row.id,
                  });
                }}
              >
                Delete
              </Button>
            )}
          </Box>
        ),
      },
    ];

    return (
        <Box>
          <Box sx={{ height: 500, width: "100%" }}>
            {isLoading ? (
              <Box sx={{ width: "100%" }}>
                {[...Array(10)].map((_, index) => (
                  <Skeleton
                    key={index}
                    height={60}
                    animation="wave"
                    sx={{ my: 1 }}
                  />
                ))}
              </Box>
            ) : (
              <DataGridPro
                rows={rows}
                columns={columns}
                pageSize={5}
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#F9FAFB", // Light gray for header background
                    color: "#667085", // Text color for header
                    fontWeight: "800", // Bold text
                    fontSize: "0.875rem",
                  },
                  "& .MuiDataGrid-cell": {
                    fontSize: "0.875rem",
                    color: "#101828",
                  },
                  "& .MuiDataGrid-root": {
                    border: "none",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    borderTop: "none",
                  },
                }}
                components={{ Toolbar: GridToolbar }}
                disableSelectionOnClick
                sortModel={[
                  {
                    field: "createdAt", // Use the field that represents "Time Received"
                    sort: "desc", // or 'desc' for descending order
                  },
                ]}
              />
            )}
          </Box>
          <DeleteConfirmationDialog />
          {selectedSAQ && (
            <SupplierQuestionnaireDetailView
              open={Boolean(selectedSAQ)}
              onClose={() => setSelectedSAQ(null)}
              saqData={selectedSAQ}
              supplierDropdownData={supplierDropdownData}
              organisationId={organisationId}
            />
          )}
        </Box>
      );
    };

export default SupplierQuestionnaireTable;