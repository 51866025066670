import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Grid,
    IconButton,
    Typography,
    Snackbar
  } from '@mui/material';
import { Alert } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import _ from 'lodash';
import moment from 'moment';

import { selectFactoryData, selectSignedUrls } from '../../selectors/factory.selector';
import { updateFactoryData } from '../../reducers/factory.reducer';

import { deepCloneWithDates, getChangedPaths, getValue } from '../../utils/lib';
import { environmentalManagementDataSections, languages } from '../../configs/facilityDataSections';
import RenderFormFields from '../renderFormFields';


const EnvironmentalManagementDataTab = () => {
    const dispatch = useDispatch();
    const factoryData = useSelector(selectFactoryData);
    const signedUrls = useSelector(selectSignedUrls);
    const [formData, setFormData] = useState({});
    const [initialData, setInitialData] = useState({});
    const [expandedSections, setExpandedSections] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [uploadDialogOpen, setUploadDialogOpen] = useState({ open: false, fieldKey: null });
  
    useEffect(() => {
        if (factoryData) {
          // Deep clone factoryData for formData and initialData separately
          const processedData = deepCloneWithDates(factoryData);
          setFormData(processedData);
    
          const initialDataClone = deepCloneWithDates(factoryData);
          setInitialData(initialDataClone);
    
          // Initialize all sections as expanded
          const allExpanded = environmentalManagementDataSections.reduce((acc, section) => {
            acc[section.name] = true;
            return acc;
          }, {});
          setExpandedSections(allExpanded);
        }
      }, [factoryData]);

    const handleChange = (name, value) => {
        setFormData((prevData) => {
          const newData = _.cloneDeep(prevData);

            // Convert empty strings to null
            let updatedValue = value;
            if (value === '') {
                updatedValue = null;
            }
            _.set(newData, name, updatedValue);
    
          // Special handling for complianceStatus change
          if (name === 'metadata.complianceStatus') {
            handleComplianceStatusChange(newData, updatedValue);
          }
    
          return newData;
        });
      };
    
    const handleComplianceStatusChange = (data, value) => {
        const complianceDatePath = 'metadata.miscDates.complianceAuthorizationDate';

        if (value === 'AUTHORIZED') {
            const pacificTime = moment().utc().subtract(7, 'hours');
            _.set(data, complianceDatePath, pacificTime.format('YYYY-MM-DD'));
        } else {
            _.set(data, complianceDatePath, null);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const changes = getChangedPaths(formData, initialData);

        console.log('Detected Changes:', changes);

        if (!_.isEmpty(changes)) {
            dispatch(
                updateFactoryData({
                organisationId: factoryData.organisationId,
                supplierId: factoryData.supplierId,
                factoryId: factoryData._id,
                changes,
                })
            );
            setSnackbarMessage('Changes saved successfully');
            setSnackbarSeverity('success');
        } else {
            setSnackbarMessage('No changes detected');
            setSnackbarSeverity('info');
        }
        setSnackbarOpen(true);
    };
    
    const handleFileClick = (key) => {
        if (signedUrls[key]) {
            window.open(signedUrls[key], '_blank');
        } else {
            dispatch({ type: 'factory/getSignedUrl', payload: { key } });
        }
    };

    const toggleSection = (sectionName) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionName]: !prev[sectionName]
        }));
    };
  
    const renderSection = (sectionName, fields) => (
        <Card sx={{ mb: 2, border: '1px solid #e0e0e0' }}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">{sectionName}</Typography>
                    <IconButton onClick={() => toggleSection(sectionName)}>
                        {expandedSections[sectionName] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Box>
                <Collapse in={expandedSections[sectionName]}>
                <Grid container spacing={2}>
                        {fields.map((field, index) => {
                            const val = getValue(formData, field.dbMapping);
                            return (
                                <Grid item xs={12} sm={field.gridSize?.sm || 6} md={field.gridSize?.md || 3} key={`${sectionName}-${field.dbMapping || index}`}>
                                    {RenderFormFields({
                                        field,
                                        value: val,
                                        handleChange,
                                        formData,
                                        signedUrls,
                                        dispatch,
                                        languages,
                                        handleFileClick,
                                        uploadDialogOpen,
                                        setUploadDialogOpen,
                                        // We no longer need formData/factoryData in the render fields since we rely only on value.
                                        factoryData, // If needed by file upload logic to pass org/supplier/facilityId
                                    })}
                                </Grid>
                            );
                        })}
                    </Grid>
                </Collapse>
            </CardContent>
        </Card>
    );

    return (
        <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    sx={{
                        backgroundColor: '#585aeb',
                        '&:hover': {
                            backgroundColor: '#4648c4',
                        },
                    }}
                >
                    Save Changes
                </Button>
            </Box>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2, height: 'calc(100vh - 60px)' }}>
                {/* {sections.map((section) => renderSection(section.name, section.fields))} */}
                {environmentalManagementDataSections.map((section) => (
                    <div key={`${section.name}-${factoryData._id}`}>
                        {renderSection(section.name, section.fields)}
                    </div>
                ))}
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert 
                    onClose={() => setSnackbarOpen(false)} 
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default EnvironmentalManagementDataTab;