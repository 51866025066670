import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
  Skeleton,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { debounce } from "lodash";
import moment from "moment";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TopBreadcrumbs from "./topBreadcrumbs.component";
import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getFactoryById,
  getListOfAllFactories,
  getSearchResultOfFactories,
} from "../reducers/factory.reducer";
import { selectOrganisationId } from "../selectors/login.selector";
import {
  selectFactoryCache,
  selectFactoryList,
  selectSearchCount,
  selectSearchResultsFactoryList,
  selectTotalCount,
  selectActionNeededData,
  selectActionNeededDataForFactories
} from "../selectors/factory.selector";
import { selectLoadingState } from "../selectors/misc.selector";
import { AuditStyles } from "../styles";

const FactoryTableDataGrid = () => {
  const dispatch = useDispatch();
  const factoriesList = useSelector(selectFactoryList);
  const isLoading = useSelector(selectLoadingState);
  const totalCount = useSelector(selectTotalCount);

  const navigateToFactoryDetails = (supplierId, factoryId) => {
    dispatch(getFactoryById({ supplierId, factoryId }));
    dispatch(getSupplierById(supplierId));
    navigate(`/suppliers/${supplierId}/factory/${factoryId}`);
  };

  //const factoriesWithActionNeededData = useSelector(selectActionNeededDataForFactories);

  // Helper function to get action needed data for a factory
  const getActionNeededData = (factory) => {
    return selectActionNeededData.resultFunc({ factoryData: factory });
  };

  const rows = factoriesList.map((factory) => {
    const actionNeededData = getActionNeededData(factory);
    //console.log("actionNeededData", actionNeededData);
    const actionAlertTrigger = actionNeededData.triggers?.overallTriggered ? "Yes" : "No";


    // console.log("ffff factoryId", factory?.factoryId);
    return {
      id: factory?.factoryId, // Unique ID for the grid's row key
      factoryName: factory?.name,
      factoryIdFromMetadata: factory?.factoryIdFromMetadata || "-",
      country: factory?.location,
      tier: factory?.tier || "-",
      associatedSupplier: factory?.supplierName || "-",
      actionAlert: actionAlertTrigger,
      // actionAlert: '',
      supplierId: factory?.supplierId,
      vendorRating: factory?.metadata?.vendorRating || "-",
      complianceStatus: factory?.metadata?.complianceStatus || "-",
      lastAuditDate: factory?.metadata?.socialAssessmentInfo?.lastAssessmentDate ? moment(factory.metadata.socialAssessmentInfo.lastAssessmentDate).format("MMM. DD, YYYY") : "-"
    };
  });

  const columns = [
    { field: "complianceStatus", headerName: "Compliance Status", flex: 1.5 },
    { field: "factoryName", headerName: "Facility Name", flex: 1 },
    {
      field: "associatedSupplier",
      headerName: "Supplier Name",
      flex: 1.2,
    },
    // {
    //   field: "factoryIdFromMetadata",
    //   headerName: "Site ID",
    //   flex: 1,
    // },
    {
      field: "tier",
      headerName: "Tier",
      type: "number",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "vendorRating",
      headerName: "Rating",
      flex: 1.2,
      renderCell: (params) => {
        const rating = params.value?.toLowerCase();
        let backgroundColor;
        switch (rating) {
          case "green":
            backgroundColor = AuditStyles.auditRatingColors.green;
            break;
          case "yellow":
            backgroundColor = AuditStyles.auditRatingColors.yellow;
            break;
          case "red":
            backgroundColor = AuditStyles.auditRatingColors.red;
            break;
          case "orange":
            backgroundColor = AuditStyles.auditRatingColors.orange;
            break;
          default:
            backgroundColor = "transparent";
        }
        return (
          <Box
            sx={{
              display: "inline-block",
              padding: "4px 8px",
              borderRadius: "4px",
              backgroundColor: backgroundColor,
              color: "black",
            }}
          >
            {params.value || "-"}
          </Box>
        );
      },
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
    },
    {
      field: "lastAuditDate",
      headerName: "Last Audit Date",
      flex: 1,
    },
    {
      field: "actionAlert",
      headerName: "Action Items",
      flex: 0.75,
      renderCell: (params) => {
        // Conditionally render Chip if value is "Yes" or "No"
        if (params.value === "Yes" || params.value === "No") {
          return (
            <Chip
              label={params.value}
              sx={{
                borderRadius: "16px",
                fontWeight: 500,
                fontSize: 12,
                backgroundColor: params.value === "Yes" ? "#FECDCA" : "#F2F4F7",
                color: params.value === "Yes" ? "#B42318" : "default",
              }}
            />
          );
        } else {
          // Render plain text for other values
          return <span>{params.value}</span>;
        }
      },
    },
    {
      field: ".",
      headerName: "",
      flex: 0.75,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            // console.log("params.row.supplierId", params.row);
            navigateToFactoryDetails(params.row.supplierId, params.row.id);
          }}
          sx={{
            backgroundColor: "#6172F3",
            color: "white",
            "&:hover": {
              backgroundColor: "#5667e2",
              fontWeight: "bold",
            },
            textTransform: "none",
            minWidth: "79px",
            height: "40px",
            borderRadius: "8px",
          }}
          size="small"
        >
          Details
        </Button>
      ),
    },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
    filter: {
      filterModel: {
        items: [],
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredFactories, setFilteredFactories] = useState([]);

  const [count, setCount] = useState(totalCount);

  const factoryCache = useSelector((state) => selectFactoryCache(state, page));
  const navigate = useNavigate();

  const organisationId = useSelector(selectOrganisationId);
  const searchCount = useSelector(selectSearchCount);
  const searchResults = useSelector(selectSearchResultsFactoryList);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredFactories(searchResults);
    } else {
      setFilteredFactories(factoriesList);
    }
  }, []);

  // commenting this code. This was for the old table.
  // useEffect(() => {
  //   if (factoryCache) {
  //     setFilteredFactories(factoryCache);
  //   } else {
  //     const limit = 8;
  //     const offset = (page - 1) * limit;

  //     dispatch(getListOfAllFactories({ page, limit, offset, organisationId }));
  //   }
  // }, [page, factoryCache]);

  useEffect(() => {
    if (organisationId) {
      dispatch(
        getListOfAllFactories({ page: 1, limit: 8, offset: 1, organisationId })
      );
    }
  }, [organisationId]); // Runs when organisationId changes

  const debouncedFetchFactories = useCallback(
    debounce((term) => {
      const response = dispatch(getSearchResultOfFactories({ search: term }));
      console.log(response);
    }, 100), // Delay in milliseconds
    []
  );

  const copyToClipboard = () => {
    navigator.clipboard.writeText("https://q-auditsense-1086719075712.us-central1.run.app/" + organisationId)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const location = useLocation();

  return (
    <Box sx={{ p: 3 }}>
      <TopBreadcrumbs />
      <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
        <div>
          <Typography variant="h6" style={{ fontSize: 20, fontWeight: 600 }}>
            Facilities
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 14, fontWeight: 400, marginBottom: 8 }}
          >
            Manage your Facilities here
          </Typography>
        </div>
        <Button
          variant="outlined"
          onClick={copyToClipboard}
          sx={{
            color: "#6172F3",
            "&:hover": {
              color: "white",
              backgroundColor: "#5667e2",
            },
            textTransform: "none",
            width: "180px",
            height: "40px",
            borderRadius: "8px",
            mr: "48px",
          }}
        >
          Presourcing Form
          <img
                    style={{ paddingLeft: 8 }}
                    src="/ne_arrow.svg"
                    alt="arrow icon"
                  />
        </Button>
      </div>
      <Divider />
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1, mt: 2 }}>
        {/* <TextField
          label="Search supplier"
          value={searchTerm}
          onChange={handleSearch}
          variant="outlined"
          size="small"
          sx={{ width: "300px" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src="./search.svg" alt="Search" />
              </InputAdornment>
            ),
          }}
        /> */}
      </Box>
      <div style={{ width: "100%", padding: "24px 48px" }}>
        <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
          {isLoading ? (
            <Box sx={{ width: "100%" }}>
              {[...Array(10)].map((_, index) => (
                <Skeleton
                  key={index}
                  height={60}
                  animation="wave"
                  sx={{ my: 1 }}
                />
              ))}
            </Box>
          ) : (
            <DataGrid
              {...dataGridFormat}
              disableColumnSelector
              disableDensitySelector
              slots={{ toolbar: GridToolbar }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              getRowHeight={() => "auto"}
              sx={{
                borderRadius: "8px",
                padding: "16px",
                "& .MuiDataGrid-cell": {
                  padding: "8px",
                },
                "& .MuiDataGrid-row": {
                  maxHeight: "none !important",
                },
                "& .MuiDataGrid-columnHeaders": {
                  backgroundColor: "#f0f0f0", // Light gray background
                  color: "#333", // Dark text color
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnHeader": {
                  padding: "16px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
              }}
            />
          )}
        </div>
      </div>
    </Box>
  );
};

export default FactoryTableDataGrid;
