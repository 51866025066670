import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Box,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Card,
  CardContent,
  Chip,
  Autocomplete,
  IconButton,
  Collapse,
  Link,
  Snackbar,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  createNewSupplierFromSAQ,
  fetchSAQSuppliers,
  updateSupplierData,
} from "../../reducers/supplier.reducer";
import { selectUserInfo } from "../../selectors/login.selector";
import { selectSignedUrls } from "../../selectors/factory.selector";
import _ from "lodash";
import { useCallback } from "react";
import renderFormFields from "../renderFormFields";
import { deepCloneWithDates, getChangedPaths, getValue } from "../../utils/lib";
import SaveIcon from "@mui/icons-material/Save";
import { saqSupplierSections } from '../../configs/supplierQuestionnaireDataSections';

const ENABLE_ASSIGN_EXISTING_SUPPLIER = false;

const SUPPLY_CHAIN_CONTACTS = [
  {
    name: "Julie Hendrixson",
    email: "julie.hendrixson@thereformation.us",
  },
  {
    name: "Aki Mizumoto",
    email: "aki.mizumoto@thereformation.us",
  },
  {
    name: "Darwin Hsing",
    email: "darwin.hsing@thereformation.us",
  },
  {
    name: "Alison Hill",
    email: "alison.hill@thereformation.us",
  },
  {
    name: "Carolyn Swenson",
    email: "carolyn.swenson@thereformation.us",
  },
  {
    name: "Bentley Beich",
    email: "bentley.beich@thereformation.us",
  },
  {
    name: "Olivia Poletti",
    email: "olivia.poletti@thereformation.us",
  },
  {
    name: "Ana Vera",
    email: "ana.vera@thereformation.us",
  },
  {
    name: "Nikki Player",
    email: "nikki.player@thereformation.us",
  },
];

const SUPPLY_CHAIN_TEAMS = [
  "Domestic",
  "Mexico",
  "Product Innovation",
  "Raw Materials",
  "Marketing Collab",
  "Import",
];

const ONBOARDING_STATUS = [
  "Testing",
  "Full Onboarding",
];

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const SupplierQuestionnaireDetailView = ({
  open,
  onClose,
  saqData,
  supplierDropdownData,
  organisationId,
}) => {
  const dispatch = useDispatch();
  const signedUrls = useSelector(selectSignedUrls);

  const [formData, setFormData] = useState({});
  const [initialData, setInitialData] = useState({});

  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const [supplyChainContact, setSupplyChainContact] = useState("");
  const [supplyChainEmail, setSupplyChainEmail] = useState("");
  const [supplyChainTeam, setSupplyChainTeam] = useState("");
  const [onboardingStatus, setOnboardingStatus] = useState("");

  const [expandedSections, setExpandedSections] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [feedback, setFeedback] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const [uploadDialogOpen, setUploadDialogOpen] = useState({
    open: false,
    fieldKey: null,
  });

  const userInfo = useSelector(selectUserInfo);
  const isGlobalLoading = useSelector((state) => state.factory.loading);
  const prevLoading = usePrevious(isGlobalLoading);

  const [matchedSupplier, setMatchedSupplier] = useState(null);

  useEffect(() => {
    if (saqData?.name) {
      // Find if a supplier with the same name exists in supplierDropdownData
      const existingSupplier = supplierDropdownData.find(
        (supplier) => supplier.supplierId === saqData.assignedToSupplier
      );

      if (existingSupplier) {
        setMatchedSupplier(existingSupplier);
      }
    }
  }, [saqData, supplierDropdownData]);

  useEffect(() => {
    if (saqData) {
      // Deep clone saqData for formData and initialData separately
      const processedData = deepCloneWithDates(saqData);
      setFormData(processedData);

      const initialDataClone = deepCloneWithDates(saqData);
      setInitialData(initialDataClone);
    }
    if (saqData?.metadata) {
      // Set supply chain contact if it exists in saqData
      if (saqData.metadata.supplyChainContact) {
        setSupplyChainContact(saqData.metadata.supplyChainContact);
        console.log("setting supplychaincontact to ", saqData.metadata.supplyChainContact);
        setSupplyChainEmail(saqData.metadata.supplyChainContactEmail || "");
      }

      // Set supply chain team if it exists
      if (saqData.metadata.supplyChainTeam) {
        setSupplyChainTeam(saqData.metadata.supplyChainTeam);
      }

      // Set onboarding status if it exists
      if (saqData.metadata.onboardingStatus) {
        setOnboardingStatus(saqData.metadata.onboardingStatus);
      }
    }
  }, [saqData]);

  // Reset states when dialog opens
  useEffect(() => {
    if (open) {
      setSelectedSupplier(null);
      setIsLoading(false);
      setFeedback({ open: false, message: "", severity: "success" });
    }
  }, [open]);

  // Monitor selection changes (if needed)
  useEffect(() => {
    // You can add any logic needed when selections change
    console.log("Selections changed:", { selectedSupplier });
  }, [selectedSupplier]);

  useEffect(() => {
    // Check if we've just finished loading (prev was true, current is false)
    if (prevLoading && !isGlobalLoading) {
      //console.log("Supplier creation completed");
      setFeedback({
        open: true,
        message: "Supplier created successfully!",
        severity: "success",
      });
      setIsLoading(false);
    }
  }, [isGlobalLoading, prevLoading]);

  useEffect(() => {}, [selectedSupplier, supplierDropdownData]);

  useEffect(() => {
    // Initialize all sections as expanded
    const allExpanded = saqSupplierSections.reduce((acc, section) => {
      acc[section.name] = true;
      return acc;
    }, {});
    setExpandedSections(allExpanded);
  }, []);

  const handleConfirmDialogOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmedUpdate = async () => {
    setIsLoading(true);
    handleConfirmDialogClose();
    try {
      const changes = prepareUpdateData(saqData);
      await dispatch(
        updateSupplierData({
          organisationId,
          supplierId: selectedSupplier.supplierId, // Assuming this is available in saqData
          changes: changes,
        })
      ).unwrap();

      // Update SAQ with assignedToFactory
      const updatedSAQ = {
        ...saqData,
        assignedToSupplier: selectedSupplier.supplierId, // Set the factoryId in the SAQ
      };

      // Call the backend to update the SAQ
      await dispatch(updateSupplierData(updatedSAQ)).unwrap();

      onClose();
    } catch (error) {
      console.error("Error updating supplier:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSupplierChange = (event) => {
    const selectedSupplierId = event.target.value;
    if (selectedSupplierId === "new") {
      setSelectedSupplier("new");
    } else {
      const selectedSupplierObject = supplierDropdownData.find(
        (supplier) => supplier.supplierId === selectedSupplierId
      );
      setSelectedSupplier(selectedSupplierObject);
    }
  };

  const prepareUpdateData = (saqData) => {
    // Exclude fields that shouldn't be updated
    const excludeFields = [
      "_id",
      "createdAt",
      "updatedAt",
      "supplierId",
    ];

    const changes = Object.entries(saqData).reduce((acc, [key, value]) => {
      if (!excludeFields.includes(key)) {
        acc[key] = value;
      }
      return acc;
    }, {});

    // Ensure supplierStatus is set to 'live'
    changes.supplierStatus = "live";

    return changes;
  };

  const handleFieldChange = useCallback((name, value) => {
    setFormData((prevData) => {
      const newData = _.cloneDeep(prevData);

      // Convert empty strings to null
      let updatedValue = value;
      if (value === "") {
        updatedValue = null;
      }
      _.set(newData, name, updatedValue);

      return newData;
    });
  }, []);


  const handleAssignSAQ = async () => {
    // Make sure we have the required values
    if (!saqData) {
      console.error("No SAQ data available");
      return;
    }
    if (!saqData.metadata?.supplyChainContact && !supplyChainContact) {
      setFeedback({
        open: true,
        message: "Supply Chain Contact is required",
        severity: "error",
      });
      return;
    }
    setIsLoading(true);
    try {
      // We only support creating new suppliers from SAQ
      const result = await dispatch(
        createNewSupplierFromSAQ({
          organisationId,
          supplierId: saqData.supplierId,
          supplierName: saqData.name,
          metadata: {
            supplyChainContact: saqData.metadata.supplyChainContact,
            supplyChainContactEmail: saqData.metadata.supplyChainContactEmail,
            supplyChainTeam: saqData.metadata.supplyChainTeam,
            onboardingStatus: onboardingStatus,
          },
        })
      );

      console.log("Supplier creation result:", result);

      setFeedback({
        open: true,
        message: "Supplier created successfully!",
        severity: "success",
      });
      onClose();

      try {
        // Refresh the SAQ suppliers list - put this in its own try/catch
        await dispatch(fetchSAQSuppliers());
      } catch (refreshError) {
        // Log the error but don't change the feedback message
        console.error("Error refreshing suppliers list:", refreshError);
      }
      
      // Close the dialog
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Error creating supplier:", error);
      setFeedback({
        open: true,
        message: "Failed to create supplier",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSection = (sectionName) => {
    setExpandedSections((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName],
    }));
  };

  const handleFileClick = (key) => {
    if (signedUrls[key]) {
      window.open(signedUrls[key], "_blank");
    } else {
      dispatch({ type: "factory/getSignedUrl", payload: { key } });
    }
  };


  const getValue = (obj, path, defaultValue = "") => {
    if (Array.isArray(path)) {
      for (const p of path) {
        const value = _.get(obj, p);
        if (
          value !== undefined &&
          value !== null &&
          !(typeof value === "object" && Object.keys(value).length === 0)
        ) {
          return value;
        }
      }
      return defaultValue;
    }

    const value = _.get(obj, path);

    if (value === undefined || value === null) {
      return defaultValue;
    } else if (
      typeof value === "object" &&
      !Array.isArray(value) &&
      Object.keys(value).length === 0
    ) {
      // Return defaultValue if value is an empty object
      return defaultValue;
    } else if (Array.isArray(value) && value.length === 0) {
      // Return defaultValue if value is an empty array
      return defaultValue;
    } else {
      return value;
    }
  };

  const handleCloseFeedback = (event, reason) => {
    if (reason === "clickaway") return;
    setFeedback((prev) => ({ ...prev, open: false }));
  };

  const renderSection = (sectionName, fields) => (
    <Card sx={{ mb: 2, boxShadow: "none", border: "1px solid #e0e0e0" }}>
      <CardContent sx={{ p: 0 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
            borderBottom: expandedSections[sectionName]
              ? "1px solid #e0e0e0"
              : "none",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {sectionName}
          </Typography>
          <IconButton onClick={() => toggleSection(sectionName)}>
            {expandedSections[sectionName] ? (
              <ExpandLessIcon />
            ) : (
              <ExpandMoreIcon />
            )}
          </IconButton>
        </Box>
        <Collapse in={expandedSections[sectionName]}>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={2}>
              {fields.map((field) => (
                <Grid
                  id={field.dbMapping}
                  item
                  xs={12}
                  sm={field.gridSize?.sm || 6}
                  md={field.gridSize?.md || 4}
                  key={`${sectionName}-${field.dbMapping}`}
                >
                  {renderFormFields({
                    field,
                    value: getValue(formData, field.dbMapping),
                    handleChange: handleFieldChange,
                    formData: saqData,
                    signedUrls,
                    dispatch,
                    handleFileClick,
                    uploadDialogOpen,
                    setUploadDialogOpen,
                    factoryData: saqData,
                    isReadOnly: !!matchedSupplier,
                    isSupplierLevel: true,
                  })}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );


  async function handleSaveChanges() {
    try {
      const changes = getChangedPaths(formData, initialData);

      if (supplyChainContact || supplyChainTeam || onboardingStatus) {
        changes.metadata = {
          ...changes.metadata,
          supplyChainContact: supplyChainContact,
          supplyChainContactEmail: supplyChainEmail,
          supplyChainTeam: supplyChainTeam,
          onboardingStatus: onboardingStatus,
        };
      }

      console.log("Changes:", changes);

      if (Object.keys(changes).length > 0) {
        // First update factory data
        await dispatch(
          updateSupplierData({
            organisationId,
            supplierId: saqData.supplierId,
            changes,
          })
        );

        // Then fetch fresh SAQ data
        await dispatch(fetchSAQSuppliers());

        setFeedback({
          open: true,
          message: "Changes saved successfully",
          severity: "success",
        });
      } else {
        setFeedback({
          open: true,
          message: "No changes to save",
          severity: "info",
        });
      }
    } catch (error) {
      console.error("Error saving changes:", error);
      setFeedback({
        open: true,
        message: "Failed to save changes",
        severity: "error",
      });
    }
  }

  console.log("matchedSupplier", matchedSupplier);


  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          //console.log("Dialog close triggered", { isLoading });
          !isLoading && onClose();
        }}
        maxWidth="lg"
        fullWidth
        disableEscapeKeyDown={isLoading}
      >
        <DialogContent sx={{ p: 3 }}>
          <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
            Intake Form Details
          </Typography>

          {/* First row: Select Facility, Select Supplier, and Create New Facility button */}
          <Box sx={{display: "flex", flexDirection: "row", gap: 2, alignItems: "center", mb: 2, width: "100%"}}>
          <Button
              variant="contained"
              onClick={handleAssignSAQ}
              disabled={
                isLoading || 
                isGlobalLoading || 
                matchedSupplier || 
                userInfo?.role === "sourcing"
              }
              sx={{
                backgroundColor: "#6172F3",
                color: "white",
                "&:hover": {
                  backgroundColor: "#5667e2",
                  fontWeight: "bold",
                },
                textTransform: "none",
                height: "40px",
                borderRadius: "8px",
                px: 3, // Add some horizontal padding
              }}
            >
              {isLoading || isGlobalLoading
                ? "Processing..."
                : "Create New Supplier"}
            </Button>
          </Box>
          
          {matchedSupplier && (
            <Box sx={{ mb: 3 }}>
              <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold" }}>
                Supplier has been created with the current pre-sourcing form.
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <a
                  href={`/suppliers/${matchedSupplier.supplierId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "#4C5ED9",
                    textDecoration: "none",
                    fontWeight: "bold",
                    fontFamily: "Inter",
                  }}
                >
                  {matchedSupplier.name}
                </a>
                <img
                  style={{ paddingLeft: 8 }}
                  src="/ne_arrow.svg"
                  alt="arrow icon"
                />
              </Box>
            </Box>
          )}
        
        {/* New Supply Chain Team Info section */}
        <Card sx={{ mb: 3, boxShadow: "none", border: "1px solid #e0e0e0" }}>
            <CardContent sx={{ p: 0 }}>
            <Box
                sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 2,
                borderBottom: "1px solid #e0e0e0",
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Supply Chain Team Info
                </Typography>
                <IconButton onClick={() => toggleSection("supplyChainTeam")}>
                <ExpandLessIcon />
                </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography
                  variant="body2"
                  sx={{
                    color: "#6C757D",
                    fontStyle: "italic",
                    mb: 2,
                  }}
                >
                  Please save supply chain team info before hitting the 'Create New Supplier' button.
                </Typography>
                <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "left",
                    mb: 2,
                    width: "100%",
                }}
                >
                <FormControl sx={{ flex: 1 }}>
                    <Select
                    value={supplyChainContact}
                    onChange={(e) => {
                        const selectedContact = SUPPLY_CHAIN_CONTACTS.find(
                        (contact) => contact.name === e.target.value
                        );
                        if (selectedContact) {
                        setSupplyChainContact(selectedContact.name);
                        setSupplyChainEmail(selectedContact.email);
                        }
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Select Supply Chain Contact" }}
                    sx={{
                        borderRadius: 2,
                        backgroundColor: "#f5f5f5",
                        "& .MuiSelect-select": {
                        p: 1.5,
                        },
                    }}
                    >
                    <MenuItem value="" disabled>
                        Select Supply Chain Contact
                    </MenuItem>
                    {SUPPLY_CHAIN_CONTACTS.map((contact) => (
                        <MenuItem key={contact.email} value={contact.name}>
                        {contact.name}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ flex: 1 }}>
                    <Select
                    value={supplyChainTeam}
                    onChange={(e) => setSupplyChainTeam(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Select Supply Chain Team" }}
                    sx={{
                        borderRadius: 2,
                        backgroundColor: "#f5f5f5",
                        "& .MuiSelect-select": {
                        p: 1.5,
                        },
                    }}
                    >
                    <MenuItem value="" disabled>
                        Select Supply Chain Team
                    </MenuItem>
                    {SUPPLY_CHAIN_TEAMS.map((team) => (
                        <MenuItem key={team} value={team}>
                        {team}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </Box>
                <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "left",
                    mb: 2,
                    width: "100%",
                }}
                >
                {/* <FormControl sx={{ flex: 1 }}>
                    <TextField
                    label="Supply Chain Contact"
                    value={supplyChainContact}
                    disabled
                    size="small"
                    sx={{
                        "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        backgroundColor: "#f5f5f5",
                        p: 0.25,
                        },
                    }}
                    />
                </FormControl> */}
                <FormControl sx={{ flex: 1 }}>
                    <TextField
                    label="Supply Chain Contact Email"
                    value={supplyChainEmail}
                    disabled
                    type="email"
                    size="small"
                    sx={{
                        "& .MuiOutlinedInput-root": {
                        borderRadius: 2,
                        backgroundColor: "#f5f5f5",
                        p: 0.25,
                        },
                    }}
                    />
                </FormControl>
                <FormControl sx={{ flex: 1 }}>
                    <Select
                    value={onboardingStatus}
                    onChange={(e) => setOnboardingStatus(e.target.value)}
                    displayEmpty
                    inputProps={{ "aria-label": "Select Onboarding Status" }}
                    sx={{
                        borderRadius: 2,
                        backgroundColor: "#f5f5f5",
                        "& .MuiSelect-select": {
                        p: 1.5,
                        },
                    }}
                    >
                    <MenuItem value="" disabled>
                        Select Onboarding Status
                    </MenuItem>
                    {ONBOARDING_STATUS.map((status) => (
                        <MenuItem key={status} value={status}>
                        {status}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                </Box>
            </Box>
            </CardContent>
        </Card>

        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 2 }}>
          {/* Add this block to show the read-only message */}
          {!!matchedSupplier && (
            <Box sx={{ 
              mb: 2, 
              p: 2, 
              backgroundColor: "#f8f9fa", 
              borderLeft: "4px solid #6c757d",
              borderRadius: "4px" 
            }}>
              <Typography variant="body2" color="text.secondary">
                <strong>Read-only mode:</strong> This form cannot be edited because a facility has already been created.
              </Typography>
            </Box>
          )}
            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={handleSaveChanges}
              disabled={!!matchedSupplier}
              sx={{
                backgroundColor: "#6172F3",
                color: "white",
                "&:hover": {
                  backgroundColor: "#5667e2",
                  fontWeight: "bold",
                },
                textTransform: "none",
                height: "40px",
                borderRadius: "8px",
              }}
            >
              Save Changes
            </Button>
          </Box>
          {/* Note */}
          <Box
            sx={{
              mb: 1,
              p: 0,
              //backgroundColor: '#F8F9FA',
              //borderRadius: 1,
              //border: '1px solid #E9ECEF'
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: "#6C757D",
                fontStyle: "italic",
              }}
            >
              These are the responses submitted by the supplier.
            </Typography>
          </Box>

          {/* SAQ sections */}
          <Box sx={{ maxHeight: "calc(100vh - 250px)", overflowY: "auto" }}>
            {saqSupplierSections.map((section) =>
              renderSection(section.name, section.fields)
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            //variant="contained"
            onClick={onClose}
            sx={{
              backgroundColor: "#6172F3",
              color: "white",
              "&:hover": {
                backgroundColor: "#5667e2",
                fontWeight: "bold",
              },
              textTransform: "none",
              minWidth: "79px",
              height: "40px",
              borderRadius: "8px",
            }}
            size="small"
          >
            Close
          </Button>
        </DialogActions>
        {/* Confirmation dialog for updating existing factory */}
        {ENABLE_ASSIGN_EXISTING_SUPPLIER && (
          <Dialog
            open={openConfirmDialog}
            onClose={handleConfirmDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm Update"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to update the existing factory with this
                SAQ data? This action will overwrite existing data.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmDialogClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirmedUpdate} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Dialog>

      <Snackbar
        open={feedback.open}
        autoHideDuration={1500}
        onClose={handleCloseFeedback}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ zIndex: 9999 }}
      >
        <Alert
          onClose={handleCloseFeedback}
          severity={feedback.severity}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SupplierQuestionnaireDetailView;