import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
  Skeleton,
} from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

import { debounce } from "lodash";
import moment from "moment";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getFactoryById,
  getListOfAllFactories,
  getSearchResultOfFactories,
} from "../../reducers/factory.reducer";
import { selectOrganisationId } from "../../selectors/login.selector";
import {
  selectFactoryCache,
  selectFactoryList,
  selectSearchCount,
  selectSearchResultsFactoryList,
  selectTotalCount,
} from "../../selectors/factory.selector";
import { selectLoadingState } from "../../selectors/misc.selector";

const FacilityMetaDataGrid = () => {
  const dispatch = useDispatch();
  const factoriesList = useSelector(selectFactoryList);
  const isLoading = useSelector(selectLoadingState);
  const totalCount = useSelector(selectTotalCount);

  const navigateToFactoryDetails = (supplierId, factoryId) => {
    dispatch(getFactoryById({ supplierId, factoryId }));
    dispatch(getSupplierById(supplierId));
    navigate(`/suppliers/${supplierId}/factory/${factoryId}`);
  };

  const rows = factoriesList.map((factory) => {
    return {
      id: factory?.factoryId ?? "", // Unique ID for the grid's row key
      factoryIdFromMetadata: factory?.factoryIdFromMetadata ?? "",
      factoryName: factory?.name ?? "",
      associatedSupplier: factory?.supplierName ?? "",
      tier: factory?.tier ?? "",
      factoryAddress: factory?.metadata?.addressLine1 ?? "",
      factoryCountry: factory?.metadata?.country ?? "",
      factoryCity: factory?.metadata?.city ?? "",
      reportingCheck: factory?.metadata?.reportingCheck ?? "",
      deMinimisCheck: factory?.metadata?.deMinimis?.deMinimisCheck ?? "",
      domesticOrInternational: factory?.metadata?.domesticOrInternational ?? "",
      statusOfCompliance: factory?.metadata?.complianceStatus ?? "",
      vendorRating: factory?.metadata?.vendorRating ?? "",
      complianceAuthorizationDate: factory?.metadata?.miscDates?.complianceAuthorizationDate ? moment(factory?.metadata?.miscDates?.complianceAuthorizationDate).format('MMMM D, YYYY') : '',
      supplyChainStatus: factory?.metadata?.supplyChainStatus ?? "",
      supplyChainPriority: factory?.metadata?.supplyChainPriority ?? "",
      potentialInitialPODate: factory?.metadata?.potentialInitialPODate ? moment(factory?.metadata?.potentialInitialPODate).format('MMMM D, YYYY') : '',
      irssCheck: factory?.metadata?.irss?.check ?? "",
      irssProgramEndDate: factory?.metadata?.irss?.programEndDate ? moment(factory?.metadata?.irss?.programEndDate).format('MMMM D, YYYY') : '',
      irssStatus: factory?.metadata?.irss?.status ?? "",
      lastAssessmentDate: factory?.metadata?.socialAssessmentInfo?.lastAssessmentDate ? moment(factory?.metadata?.socialAssessmentInfo?.lastAssessmentDate).format('MMMM D, YYYY') : '',
      lastSocialAssessmentType: factory?.metadata?.socialAssessmentInfo?.lastSocialAssessmentType ?? "",
      lastSocialAssessmentFirm: factory?.metadata?.socialAssessmentInfo?.lastSocialAssessmentFirm ?? "",
      lastSocialAssessmentPayer: factory?.metadata?.socialAssessmentInfo?.lastSocialAssessmentPayer ?? "",
      nextAssessmentWindowDateStart: factory?.metadata?.socialAssessmentInfo?.nextAssessmentWindowDateStart ? moment(factory?.metadata?.socialAssessmentInfo?.nextAssessmentWindowDateStart).format('MMMM D, YYYY') : '',
      nextAssessmentWindowDateEnd: factory?.metadata?.socialAssessmentInfo?.nextAssessmentWindowDateEnd ? moment(factory?.metadata?.socialAssessmentInfo?.nextAssessmentWindowDateEnd).format('MMMM D, YYYY') : '',
      generalAuditType: factory?.metadata?.socialAssessmentInfo?.generalAuditType ?? "",
      generalAuditFirm: factory?.metadata?.socialAssessmentInfo?.generalAuditFirm ?? "",
      confirmedAuditDate: factory?.metadata?.socialAssessmentInfo?.confirmedAuditDate ? moment(factory?.metadata?.socialAssessmentInfo?.confirmedAuditDate).format('MMMM D, YYYY') : '',
      socialAssessmentPayer: factory?.metadata?.socialAssessmentInfo?.socialAssessmentPayer ?? "",
      socialResponsibilitySignOff: factory?.metadata?.socialResponsibilitySignOff ?? "",
      environmentalResponsibilitySignOff: factory?.metadata?.environmentalResponsibilitySignOff ?? "",

      // contactName: factory?.contactPerson?.name ?? "",
      // contactPosition: factory?.contactPerson?.position ?? "",
      // contactPhone: factory?.contactPerson?.phone ?? "",
      // contactEmail: factory?.contactPerson?.email ?? "",
      productCategories: factory?.metadata?.productCategories ?? "",
      productionProcesses: factory?.metadata?.processInfo?.productionProcesses ?? "",
      productionContractType: factory?.metadata?.processInfo?.productionContractType ?? "",
      supplyChainTeam: factory?.metadata?.supplyChainTeam ?? "",
      supplyChainContact: factory?.metadata?.supplyChainContact ?? "",
      facilityAffiliation: factory?.metadata?.facilityAffiliation ?? "",
      existingClients: factory?.metadata?.existingClients ?? "",
      peakSeason: factory?.metadata?.productionSeasonalityPeak ?? "",
      lowSeason: factory?.metadata?.productionSeasonalityLow ?? "",
      minOrderQty: factory?.metadata?.minOrderQty ?? "",
      avgLeadTime: factory?.metadata?.avgLeadTime ?? "",
      machineCount: factory?.metadata?.machineCount ?? "",
      avgUnitsProducedPerWeek: factory?.metadata?.avgUnitsProducedPerWeek ?? "",
      // privateLabel: factory?.metadata?.privateLabel === "Yes" ? "Yes" : "No",
      // spend: factory?.metadata?.spend ?? "",
      //lastAuditDate: factory?.lastAuditDateTimestamp ? moment(factory.lastAuditDateTimestamp).format('MMMM D, YYYY') : '', //Comment out because the data might be inaccurate
      //auditScore: factory?.auditScore ?? '',
      // openFindings: factory?.issueDetails?.Open ?? '',
      // pastDueFindings: factory?.issueDetails?.PastDue ?? '',
      // criticalFindings: factory?.issueDetails?.Critical ?? '',
      // majorFindings: factory?.issueDetails?.Major ?? '',
      // minorFindings: factory?.issueDetails?.Minor ?? '',
      // ztFindings: factory?.issueDetails?.ZT ?? '',
      // closedFindings: factory?.issueDetails?.Closed ?? '',
      size: factory?.metadata?.size ?? '',
      domesticMigrantWorkers: factory?.metadata?.domesticMigrantWorkers ?? '',
      foreignMigrantWorkers: factory?.metadata?.foreignMigrantWorkers ?? '',
      homeWorkers: factory?.metadata?.homeWorkers ?? '',
      productionSeasonalityLow: factory?.metadata?.productionSeasonalityLow ?? '',
      productionSeasonalityPeak: factory?.metadata?.productionSeasonalityPeak ?? '',
      siteSize: factory?.metadata?.siteSize ?? '',
      // workerCountAgency: factory?.metadata?.workerCountAgency ?? '',
      // workerCountPermanent: factory?.metadata?.workerCountPermanent ?? '',
      // workerCountTemporary: factory?.metadata?.workerCountTemporary ?? '',
      workerCountTotal: factory?.metadata?.workerCountTotal ?? '',
      workerGenderFemale: factory?.metadata?.workerGenderFemale ?? '',
      workerGenderMale: factory?.metadata?.workerGenderMale ?? '',
      workerGenderOther: factory?.metadata?.workerGenderOther ?? '',
      youngWorkers: factory?.metadata?.youngWorkers ?? '',
      irssCheck: factory?.metadata?.irss?.check ?? '',
      irssProgramEndDate: factory?.metadata?.irss?.programEndDate ? moment(factory?.metadata?.irss?.programEndDate).format('MMMM D, YYYY') : '',
      irssStatus: factory?.metadata?.irss?.status ?? '',
    };
  });

  const columns = [
    {
      field: "factoryIdFromMetadata",
      headerName: "Facility ID",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "factoryName",
      headerName: "Facility Name",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "associatedSupplier",
      headerName: "Associated Supplier",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "tier",
      headerName: "Tier",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "factoryAddress",
      headerName: "Facility Address",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "associatedAddresses",
      headerName: "Associated Addresses",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactName",
      headerName: "Contact Person",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactPosition",
      headerName: "Position",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactPhone",
      headerName: "Facility Phone",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "contactEmail",
      headerName: "Email",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productCategories",
      headerName: "Product Categories",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastAuditDate",
      headerName: "Last Audit Date",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "auditScore",
      headerName: "Audit Score",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "vendorRating",
      headerName: "Vendor Rating",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "domesticMigrantWorkers",
      headerName: "Domestic Migrant Workers",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "foreignMigrantWorkers",
      headerName: "Foreign Migrant Workers",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "homeWorkers",
      headerName: "Home Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productionSeasonalityLow",
      headerName: "Production Seasonality (Low)",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productionSeasonalityPeak",
      headerName: "Production Seasonality (Peak)",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "vendorRating",
      headerName: "Vendor Rating",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerCountAgency",
      headerName: "Agency Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerCountPermanent",
      headerName: "Permanent Workers",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerCountTemporary",
      headerName: "Temporary Workers",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerCountTotal",
      headerName: "Total Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerGenderFemale",
      headerName: "Female Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerGenderMale",
      headerName: "Male Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerGenderOther",
      headerName: "Other Gender Workers",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "youngWorkers",
      headerName: "Young Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "irssCheck",
      headerName: "IRSS Check",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "irssStatus",
      headerName: "IRSS Status",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "irssProgramEndDate",
      headerName: "IRSS Program End Date",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: ".",
    //   headerName: "",
    //   flex: 0.75,
    //   renderCell: (params) => (
    //     <Button
    //       variant="contained"
    //       onClick={() => {
    //         // console.log("params.row.supplierId", params.row);
    //         navigateToFactoryDetails(params.row.supplierId, params.row.id);
    //       }}
    //       sx={{
    //         backgroundColor: "#6172F3",
    //         color: "white",
    //         "&:hover": {
    //           backgroundColor: "#5667e2",
    //           fontWeight: "bold",
    //         },
    //         textTransform: "none",
    //         minWidth: "79px",
    //         height: "40px",
    //         borderRadius: "8px",
    //       }}
    //       size="small"
    //     >
    //       Details
    //     </Button>
    //   ),
    // },
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {
        // This can be used to hide or show columns by default
      },
    },
    filter: {
      filterModel: {
        items: [],
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredFactories, setFilteredFactories] = useState([]);

  const [count, setCount] = useState(totalCount);

  const factoryCache = useSelector((state) => selectFactoryCache(state, page));
  const navigate = useNavigate();

  const organisationId = useSelector(selectOrganisationId);
  const searchCount = useSelector(selectSearchCount);
  const searchResults = useSelector(selectSearchResultsFactoryList);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredFactories(searchResults);
    } else {
      setFilteredFactories(factoriesList);
    }
  }, []);

  // commenting this code. This was for the old table.
  // useEffect(() => {
  //   if (factoryCache) {
  //     setFilteredFactories(factoryCache);
  //   } else {
  //     const limit = 8;
  //     const offset = (page - 1) * limit;

  //     dispatch(getListOfAllFactories({ page, limit, offset, organisationId }));
  //   }
  // }, [page, factoryCache]);

  useEffect(() => {
    if (organisationId) {
      dispatch(
        getListOfAllFactories({ page: 1, limit: 8, offset: 1, organisationId })
      );
    }
  }, [organisationId]); // Runs when organisationId changes

  const debouncedFetchFactories = useCallback(
    debounce((term) => {
      dispatch(getSearchResultOfFactories({ search: term }));
    }, 100), // Delay in milliseconds
    []
  );

  const location = useLocation();

  return (
    <Box sx={{ height: "calc(100vh - 250px)", width: "100%", padding: "24px 80px" }}>
      <DataGridPro
        {...dataGridFormat}
        checkboxSelection
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowHeight={() => "auto"}
        sx={{
          borderRadius: "8px",
          padding: "16px",
          "& .MuiDataGrid-cell": {
            padding: "8px",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f0f0f0", // Light gray background
            color: "#333", // Dark text color
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader": {
            padding: "16px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      />
    </Box>
  );
};

export default FacilityMetaDataGrid;
