import React, { useEffect, useState } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import {
  Box,
  Typography,
  Chip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectDueCaps } from "../../selectors/dueAction.selector";
import { setAuditData } from "../../reducers/audit.reducer";
import { getDueActionCerticatesData } from "../../reducers/factory.reducer";
import moment from "moment";
import { selectDueActionCerticatesData } from "../../selectors/factory.selector";
const DueWorkersCompCertificate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDueActionCerticatesData());
  }, []);

  const dueActionCerticatesData = useSelector(selectDueActionCerticatesData);

  // FUNCITON TO CONVERT DAYS to YEARS MONTHS AND DAYs
  //   const convertDaysToYearsMonthsDays = (totalDays) => {
  //     const years = Math.floor(totalDays / 365);
  //     const remainingDaysAfterYears = totalDays % 365;
  //     const months = Math.floor(remainingDaysAfterYears / 30);
  //     const days = remainingDaysAfterYears % 30;

  //     const parts = [];
  //     if (years > 0) parts.push(`${years} ${years === 1 ? 'year' : 'years'}`);
  //     if (months > 0) parts.push(`${months} ${months === 1 ? 'month' : 'months'}`);
  //     if (days > 0) parts.push(`${days} ${days === 1 ? 'day' : 'days'}`);

  //     return parts.join(', ');
  //   };

  const rows = dueActionCerticatesData?.filter((facility) => facility.factoryName !== "Test")
  .map((facility) => ({
    id: facility.id,
    supplierId: facility.supplierId,
    factoryName: facility.factoryName,
    vendorRating: facility.vendorRating,
    complianceStatus: facility.complianceStatus,
    domesticOrInternational: facility.domesticOrInternational,
    businessLicenseExpiryDate: facility.businessLicenseExpiryDate,
    daysSinceBusinessLicenseExpiry: facility.businessLicenseDueDays,
    daysSinceWccExpiry: facility.wccDueDays,
    daysSinceNdaExpiry: facility.ndaDueDays,
    businessLicenseDueDays: facility.businessLicenseDueDays
      ? facility.businessLicenseDueDays > 0
        ? `${facility.businessLicenseDueDays} days`
        : `Past ${facility.businessLicenseDueDays
            .toString()
            .replace("-", "")} days`
      : "-",
    wccExpiryDate: facility.wccExpiryDate,
    wccDueDays: facility.wccDueDays
      ? facility.wccDueDays > 0
        ? `${facility.wccDueDays} days`
        : `Past ${facility.wccDueDays.toString().replace("-", "")} days`
      : "-",
    ndaExpiryDate: facility.ndaExpiryDate,
    ndaDueDays: facility.ndaDueDays
      ? facility.ndaDueDays > 0
        ? `${facility.ndaDueDays} days`
        : `Past ${facility.ndaDueDays.toString().replace("-", "")} days`
      : "-",
  }));

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);

  // Add this filter handling function
  // const handleFilterToggle = (filterType) => {
  //   const newFilters = selectedFilters.includes(filterType)
  //     ? selectedFilters.filter((f) => f !== filterType)
  //     : [...selectedFilters, filterType];

  //   setSelectedFilters(newFilters);
  //   // Apply filters
  //   const filtered = rows?.filter((row) => {
  //     if (newFilters.length === 0) return true;

  //     if (row?.daysSinceBusinessLicenseExpiry) {
  //       const daysSinceBusinessLicenseExpiry =
  //         row?.daysSinceBusinessLicenseExpiry;

  //       return newFilters.some((filter) => {
  //         switch (filter) {
  //           case "pastDue":
  //             return daysSinceBusinessLicenseExpiry <= 0;
  //           case "due1to20":
  //             return (
  //               daysSinceBusinessLicenseExpiry > 0 &&
  //               daysSinceBusinessLicenseExpiry < 20
  //             );
  //           case "due20to60":
  //             return (
  //               daysSinceBusinessLicenseExpiry > 20 &&
  //               daysSinceBusinessLicenseExpiry <= 60
  //             );
  //           case "due60plus":
  //             return daysSinceBusinessLicenseExpiry > 60;
  //           default:
  //             return true;
  //         }
  //       });
  //     }
  //   });

  //   setFilteredRows(filtered);
  // };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // to check which expiry has the most days and use that value for filtering
  // const handleFilterToggle = (filterType) => {
  //   const newFilters = selectedFilters.includes(filterType)
  //     ? selectedFilters.filter((f) => f !== filterType)
  //     : [...selectedFilters, filterType];

  //   setSelectedFilters(newFilters);

  //   // Apply filters
  //   const filtered = rows?.filter((row) => {
  //     if (newFilters.length === 0) return true;

  //     // Get the maximum days value among the three expiry types
  //     const maxDays = Math.max(
  //       row.daysSinceBusinessLicenseExpiry || -Infinity,
  //       row.daysSinceWccExpiry || -Infinity,
  //       row.daysSinceNdaExpiry || -Infinity
  //     );

  //     // If all values are -Infinity (no valid days), return false
  //     if (maxDays === -Infinity) return false;

  //     return newFilters.some((filter) => {
  //       switch (filter) {
  //         case "pastDue":
  //           return maxDays <= 0;
  //         case "due1to20":
  //           return maxDays > 0 && maxDays < 20;
  //         case "due20to60":
  //           return maxDays >= 20 && maxDays <= 60;
  //         case "due60plus":
  //           return maxDays > 60;
  //         default:
  //           return true;
  //       }
  //     });
  //   });

  //   setFilteredRows(filtered);
  // };

  // ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // if ANY of the three expiry types matches the filter criteria (rather than just the maximum):
  const handleFilterToggle = (filterType) => {
    const newFilters = selectedFilters.includes(filterType)
      ? selectedFilters.filter((f) => f !== filterType)
      : [...selectedFilters, filterType];

    setSelectedFilters(newFilters);

    // Apply filters
    const filtered = rows?.filter((row) => {
      if (newFilters.length === 0) return true;

      // Check if any of the three expiry types matches the filter criteria
      const daysValues = [
        row.daysSinceBusinessLicenseExpiry,
        row.daysSinceWccExpiry,
        row.daysSinceNdaExpiry,
      ].filter((days) => days !== undefined && days !== null);

      // If no valid days values exist, return false
      if (daysValues.length === 0) return false;

      return newFilters.some((filter) => {
        // Check if ANY of the days values match the filter criteria
        return daysValues.some((days) => {
          switch (filter) {
            case "pastDue":
              return days <= 0;
            case "due1to20":
              return days > 0 && days < 20;
            case "due20to60":
              return days >= 20 && days <= 60;
            case "due60plus":
              return days > 60;
            default:
              return true;
          }
        });
      });
    });

    setFilteredRows(filtered);
  };

  useEffect(() => {
    setFilteredRows(rows);
  }, [dueActionCerticatesData]);

  const columns = [
    {
      field: "factoryName",
      headerName: "Facility Name",
      width: 170,
      headerAlign: "center",
      filterable: true,
      renderCell: (params) => (
        <Typography
          sx={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            padding: "8px 0px 8px 8px",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "complianceStatus",
      headerName: "Compliance Status",
      width: 150,
      headerAlign: "center",
      filterable: true,
      renderCell: (params) => (
        <Typography
          sx={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            padding: "8px 0px 8px 8px",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "businessLicenseExpiryDate",
      headerName: "Business License Expiry Date",
      width: 130,
      align: "center",
      headerAlign: "center",
      type: "date",
      sortable: true,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? moment(value).format("MMM. DD, YYYY") : "";
      },
    },
    {
      field: "businessLicenseDueDays",
      headerName: "Business License Due Days",
      width: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={params.row.businessLicenseDueDays}
          sx={{
            borderRadius: "8px",
            backgroundColor: params.row.businessLicenseDueDays.includes("Past")
              ? "#DC6803"
              : "#EAECF0",
            color: params.row.businessLicenseDueDays.includes("Past")
              ? "#FFF"
              : "#172B4D",
            fontWeight: "500",
          }}
        />
      ),
    },
    {
      field: "wccExpiryDate",
      headerName: "WCC Expiry Date",
      width: 130,
      align: "center",
      headerAlign: "center",
      type: "date",
      sortable: true,
      valueGetter: (value) => {
        return value && !isNaN(new Date(value).getTime())
          ? new Date(value)
          : null;
      },
      valueFormatter: (value) => {
        return value ? moment(value).format("MMM. DD, YYYY") : "";
      },
    },
    {
      field: "wccDueDays",
      headerName: "WCC Due Days",
      width: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={params.row.wccDueDays}
          sx={{
            borderRadius: "8px",
            backgroundColor: params.row.wccDueDays.includes("Past")
              ? "#DC6803"
              : "#EAECF0",
            color: params.row.wccDueDays.includes("Past") ? "#FFF" : "#172B4D",
            fontWeight: "500",
          }}
        />
      ),
    },
    {
      field: "ndaExpiryDate",
      headerName: "NDA Expiry Date",
      width: 130,
      align: "center",
      headerAlign: "center",
      type: "date",
      sortable: true,
      valueGetter: (value) => {
        return value && !isNaN(new Date(value).getTime())
          ? new Date(value)
          : null;
      },
      valueFormatter: (value) => {
        return value ? moment(value).format("MMM. DD, YYYY") : "";
      },
    },
    {
      field: "ndaDueDays",
      headerName: "NDA Due Days",
      width: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={params.row.ndaDueDays}
          sx={{
            borderRadius: "8px",
            backgroundColor: params.row.ndaDueDays.includes("Past")
              ? "#DC6803"
              : "#EAECF0",
            color: params.row.ndaDueDays.includes("Past") ? "#FFF" : "#172B4D",
            fontWeight: "500",
          }}
        />
      ),
    },
    // {
    //   field: "dueStatus",
    //   headerName: "Due Status",
    //   width: 150,
    //   align: "center",
    //   headerAlign: "center",
    //   sortable: false,
    //   renderCell: (params) => (
    //     <Chip
    //       label={params.row.dueStatus}
    //       sx={{
    //         borderRadius: "8px",
    //         backgroundColor:
    //           params.row.daysSinceAudit <= 0 ? "#DC6803" : "#EAECF0",
    //         color: params.row.daysSinceAudit <= 0 ? "#FFF" : "#172B4D",
    //         fontWeight: "500",
    //       }}
    //     />
    //   ),
    // },
    // {
    //   field: "auditScore",
    //   headerName: "Audit Score",
    //   type: "number",
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    // },
    {
      field: "vendorRating",
      headerName: "Vendor Rating",
      width: 130,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Chip
          label={params.value}
          sx={{
            borderRadius: "8px",
            backgroundColor: params.value?.includes("Green")
              ? "#E6F4EA"
              : params.value?.includes("Yellow")
              ? "#FEF3C7"
              : params.value?.includes("Orange")
              ? "#FFEDD5"
              : "#FEE2E2",
            color: params.value?.includes("Green")
              ? "#137333"
              : params.value?.includes("Yellow")
              ? "#B45309"
              : params.value?.includes("Orange")
              ? "#9A3412"
              : "#B91C1C",
            fontWeight: "500",
          }}
        />
      ),
    },
    {
      field: "domesticOrInternational",
      headerName: "Domestic/International",
      width: 130,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    // {
    //   field: "capStatus",
    //   headerName: "CAP Status",
    //   width: 400,
    //   align: "center",
    //   headerAlign: "center",
    //   sortable: false,
    //   renderCell: (params) => (
    //     <Table size="small" sx={{ width: "100%" }}>
    //       <TableBody>
    //         {/* Header row for severity categories */}
    //         <TableRow>
    //           <TableCell sx={{ borderBottom: "none", width: "80px" }} />
    //           {Object.keys(params.row.severityCategories || {}).map(
    //             (severityCategory, index) => (
    //               <TableCell
    //                 key={index}
    //                 sx={{ p: 0, m: 0, borderBottom: "none", width: "80px" }}
    //               >
    //                 <Typography
    //                   sx={{
    //                     fontWeight: 500,
    //                     fontSize: 14,
    //                     color: "#8B8D97",
    //                     textAlign: "center",
    //                   }}
    //                 >
    //                   {severityCategory}
    //                 </Typography>
    //               </TableCell>
    //             )
    //           )}
    //         </TableRow>

    //         {/* "Past Due" row */}
    //         <TableRow sx={{ borderBottom: "none" }}>
    //           <TableCell
    //             sx={{ p: 0, m: 0, borderBottom: "none", width: "80px" }}
    //           >
    //             <Typography
    //               sx={{
    //                 fontWeight: 500,
    //                 fontSize: 14,
    //                 color: "#8B8D97",
    //                 display: "flex",
    //                 justifyContent: "flex-start",
    //                 alignItems: "center",
    //               }}
    //             >
    //               Past Due
    //             </Typography>
    //           </TableCell>
    //           {Object.entries(params.row.severityCategories || {}).map(
    //             ([severityCategory, color], idx) => (
    //               <TableCell
    //                 key={idx}
    //                 sx={{
    //                   p: 0,
    //                   m: 0,
    //                   borderBottom: "none",
    //                   width: "80px",
    //                 }}
    //               >
    //                 <Box
    //                   sx={{
    //                     height: "24px",
    //                     backgroundColor:
    //                       params.row.pastDueIssues?.[severityCategory] &&
    //                       params.row.pastDueIssues[severityCategory] !== 0
    //                         ? color
    //                         : "#FFFFFF",
    //                     color:
    //                       params.row.pastDueIssues?.[severityCategory] &&
    //                       params.row.pastDueIssues[severityCategory] !== 0
    //                         ? "#FFFFFF"
    //                         : "#E0E0E0",
    //                     display: "flex",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                     fontWeight: 500,
    //                     fontSize: 14,
    //                     border:
    //                       params.row.pastDueIssues?.[severityCategory] &&
    //                       params.row.pastDueIssues[severityCategory] !== 0
    //                         ? `1px solid ${color}`
    //                         : "1px solid #E0E0E0",
    //                   }}
    //                 >
    //                   {params.row.pastDueIssues?.[severityCategory] ?? "0"}
    //                 </Box>
    //               </TableCell>
    //             )
    //           )}
    //         </TableRow>
    //         <TableRow>
    //           <TableCell
    //             sx={{ maxHeight: "2px", padding: "2px", borderBottom: "none" }}
    //           ></TableCell>
    //         </TableRow>
    //         {/* "Open CAP" row with 8px vertical margin on top */}
    //         <TableRow sx={{ borderBottom: "none" }}>
    //           <TableCell
    //             sx={{
    //               p: 0,
    //               m: 0,
    //               borderBottom: "none",
    //               width: "80px",
    //               marginTop: "8px", // Add margin-top to create spacing between rows
    //             }}
    //           >
    //             <Typography
    //               sx={{
    //                 fontWeight: 500,
    //                 fontSize: 14,
    //                 color: "#8B8D97",
    //                 display: "flex",
    //                 justifyContent: "flex-start",
    //                 alignItems: "center",
    //               }}
    //             >
    //               Open CAP
    //             </Typography>
    //           </TableCell>
    //           {Object.entries(params.row.severityCategories || {}).map(
    //             ([severityCategory, color], idx) => (
    //               <TableCell
    //                 key={idx}
    //                 sx={{
    //                   p: 0,
    //                   m: 0,
    //                   borderBottom: "none",
    //                   width: "80px",
    //                   marginTop: "8px", // Add margin-top to create spacing
    //                 }}
    //               >
    //                 <Box
    //                   sx={{
    //                     height: "24px",
    //                     backgroundColor:
    //                       params.row.openIssues?.[severityCategory] &&
    //                       params.row.openIssues[severityCategory] !== 0
    //                         ? color
    //                         : "#FFFFFF",
    //                     color:
    //                       params.row.openIssues?.[severityCategory] &&
    //                       params.row.openIssues[severityCategory] !== 0
    //                         ? "#FFFFFF"
    //                         : "#E0E0E0",
    //                     display: "flex",
    //                     alignItems: "center",
    //                     justifyContent: "center",
    //                     fontWeight: 500,
    //                     fontSize: 14,
    //                     border:
    //                       params.row.openIssues?.[severityCategory] &&
    //                       params.row.openIssues[severityCategory] !== 0
    //                         ? `1px solid ${color}`
    //                         : "1px solid #E0E0E0",
    //                   }}
    //                 >
    //                   {params.row.openIssues?.[severityCategory] ?? "0"}
    //                 </Box>
    //               </TableCell>
    //             )
    //           )}
    //         </TableRow>
    //       </TableBody>
    //     </Table>
    //   ),
    // },
    {
      field: "details",
      headerName: "",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            window.history.pushState(null, "", "/dueAction");

            navigate(
              `/suppliers/${params.row.supplierId}/factory/${params.row.id}`,
              { replace: true }
            );
          }}
          sx={{
            backgroundColor: "#4B68D4",
            color: "white",
            "&:hover": {
              backgroundColor: "#3A54B6",
            },
            textTransform: "none",
            width: "79px",
            height: "40px",
            ml: 4,

            borderRadius: "8px",
          }}
          size="small"
        >
          To Facility
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ width: "100%", padding: "0px 48px", mt: 1 }}>
        <Typography
          style={{
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "30px",
            marginTop: "30px",
          }}
        >
          Legal Documents - Upcoming Expirations
        </Typography>
        {!rows ? (
          <Box sx={{ width: "100%" }}>
            {[...Array(10)].map((_, index) => (
              <Skeleton
                key={index}
                height={60}
                animation="wave"
                sx={{ my: 1 }}
              />
            ))}
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2, display: "flex" }}>
              <Button
                variant={
                  selectedFilters.includes("pastDue") ? "contained" : "outlined"
                }
                onClick={() => handleFilterToggle("pastDue")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Past Due
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due1to20")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due1to20")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 1-20 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due20to60")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due20to60")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 20-60 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due60plus")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due60plus")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 60+ Days
              </Button>
            </Box>
            <div style={{ height: "calc(100vh - 350px)", width: "100%" }}>
              <DataGridPro
                columns={columns}
                rows={filteredRows || []}
                disableColumnSelector
                disableDensitySelector
                pageSize={5}
                rowsPerPageOptions={[5, 10, 15]}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                getRowHeight={() => 90}
                sx={{
                  borderRadius: "8px",

                  "& .MuiDataGrid-main": {
                    // Target the main container
                    overflow: "hidden",
                    "& .MuiDataGrid-virtualScroller": {
                      // Target the virtual scroller
                      height: "100% !important",
                      "& .MuiDataGrid-virtualScrollerContent": {
                        // Target the virtual scroller content
                        height: "auto !important",
                        "& .MuiDataGrid-virtualScrollerRenderZone": {
                          // Target the render zone
                          "& .MuiDataGrid-row": {
                            // Target each row
                            maxHeight: "none !important",
                            "& .MuiDataGrid-cell": {
                              // Target each cell
                              alignItems: "center",
                            },
                          },
                        },
                      },
                    },
                  },

                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f0f0f0",
                    fontWeight: "500",
                  },
                }}
              />
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DueWorkersCompCertificate;