import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Grid,
  Button,
  TextField,
  Typography,
  Card,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getValidData } from "../../../utils/lib";
import { updateFactoryData } from "../../../reducers/factory.reducer";
import { selectSupplierNameFromId } from "../../../selectors/supplier.selector";
import { ComponentStyles } from "../../../styles";

const FactoryInformationEditModal = ({
  open = false,
  onClose,
  internalData,
}) => {
  const initData = {
    factoryId: getValidData(internalData?.metadata?.factoryId),
    alias: getValidData(internalData?.metadata?.alias),
    parentCompany: getValidData(internalData?.metadata?.supplierName),
    supplierId: getValidData(internalData?.metadata?.supplierId),
    address: getValidData(internalData?.metadata?.address),
    vendorRating: getValidData(internalData?.metadata?.vendorRating),
    primaryFacilityContactPersonName: getValidData(
      internalData?.metadata?.primaryFacilityContactPerson?.name
    ),
    primaryFacilityContactPersonPosition: getValidData(
      internalData?.metadata?.primaryFacilityContactPerson?.position
    ),
    primaryFacilityContactPersonPhone: getValidData(
      internalData?.metadata?.primaryFacilityContactPerson?.phone
    ),
    primaryFacilityContactPersonEmail: getValidData(
      internalData?.metadata?.primaryFacilityContactPerson?.email
    ),
    socialComplianceContactPersonName: getValidData(
      internalData?.metadata?.socialComplianceContactPerson?.name
    ),
    socialComplianceContactPersonPosition: getValidData(
      internalData?.metadata?.socialComplianceContactPerson?.position
    ),
    socialComplianceContactPersonPhone: getValidData(
      internalData?.metadata?.socialComplianceContactPerson?.phone
    ),
    socialComplianceContactPersonEmail: getValidData(
      internalData?.metadata?.socialComplianceContactPerson?.email
    ),
    materialsSutainabilityContactPersonName: getValidData(
      internalData?.metadata?.materialsSutainabilityContactPerson?.name
    ),
    materialsSutainabilityContactPersonPosition: getValidData(
      internalData?.metadata?.materialsSutainabilityContactPerson?.position
    ),
    materialsSutainabilityContactPersonPhone: getValidData(
      internalData?.metadata?.materialsSutainabilityContactPerson?.phone
    ),
    materialsSutainabilityContactPersonEmail: getValidData(
      internalData?.metadata?.materialsSutainabilityContactPerson?.email
    ),
    ehsContactPersonName: getValidData(
      internalData?.metadata?.ehsContactPerson?.name
    ),
    ehsContactPersonPosition: getValidData(
      internalData?.metadata?.ehsContactPerson?.position
    ),
    ehsContactPersonPhone: getValidData(
      internalData?.metadata?.ehsContactPerson?.phone
    ),
    ehsContactPersonEmail: getValidData(
      internalData?.metadata?.ehsContactPerson?.email
    ),
    productCategories: getValidData(internalData?.metadata?.productCategories),
    complianceStatus: getValidData(internalData?.metadata?.complianceStatus),
    internalStatusNotes: getValidData(internalData?.metadata?.internalStatusNotes),
  };
  const [formData, setFormData] = useState(initData);

  // Store initial data to compare against
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    setInitialData(initData);
    setFormData(initData);
  }, [internalData]);

  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const changes = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== initialData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});

    if (Object.keys(changes).length > 0) {
      dispatch(
        updateFactoryData({
          organisationId: internalData?.organisationId,
          supplierId: internalData?.supplierId,
          factoryId: internalData?._id,
          changes,
        })
      );
    }
    onClose();
  };

  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, internalData?.supplierId)
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 1300,
          p: { xs: 2, sm: 4 },
          boxShadow:
            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
          border: "1px solid rgba(234, 236, 240, 1)",
          borderRadius: "12px",
          py: 2,
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ display: "flex" }}>
            <Grid item xs={7} sx={{ display: "flex", flexDirection: "column" }}>
              <Card
                sx={{
                  p: 4,
                  boxShadow:
                    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                  border: "1px solid rgba(234, 236, 240, 1)",
                  borderRadius: "12px",
                  height: "100%",
                  overflowY: "auto", 
                  maxHeight: "500px" 
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontWeight: 700,
                        fontSize: 16,
                        color: "#344054",
                        display: "inline-block",
                      }}
                    >
                      Edit general facility information
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mb: 2 }}
                >
                  <Grid item>
                    <Typography>
                      <span style={ComponentStyles.fieldName}>Suppliers:</span>{" "}
                      <Typography
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: "#3538CD",
                          display: "flex",
                          textDecoration: "none",
                        }}
                      >
                        {supplierName}
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      label="Alias"
                      name="alias"
                      variant="outlined"
                      value={formData.alias}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label="Facility ID"
                      name="factoryId"
                      variant="outlined"
                      value={formData.factoryId}
                      onChange={handleChange}
                      margin="normal"
                      disabled
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mb: 2 }}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Facility Address"
                      name="address"
                      variant="outlined"
                      value={formData.address}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Primary Facility Contact Person"
                      name="primaryFacilityContactPersonName"
                      variant="outlined"
                      value={formData.primaryFacilityContactPersonName}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Position"
                      name="primaryFacilityContactPersonPosition"
                      variant="outlined"
                      value={formData.primaryFacilityContactPersonPosition}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Phone"
                      name="primaryFacilityContactPersonPhone"
                      variant="outlined"
                      value={formData.primaryFacilityContactPersonPhone}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="primaryFacilityContactPersonEmail"
                      variant="outlined"
                      value={formData.primaryFacilityContactPersonEmail}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Social Compliance Contact Person"
                      name="socialComplianceContactPersonName"
                      variant="outlined"
                      value={formData.socialComplianceContactPersonName}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Position"
                      name="socialComplianceContactPersonPosition"
                      variant="outlined"
                      value={formData.socialComplianceContactPersonPosition}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Phone"
                      name="socialCompliancePersonPhone"
                      variant="outlined"
                      value={formData.socialComplianceContactPersonPhone}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="socialComplianceContactPersonEmail"
                      variant="outlined"
                      value={formData.socialComplianceContactPersonEmail}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  </Grid>

                  <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Materials Sustainability Contact Person"
                      name="materialsSustainabilityContactPersonName"
                      variant="outlined"
                      value={formData.materialsSustainabilityContactPersonName}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Position"
                      name="materialsSustainabilityContactPersonPosition"
                      variant="outlined"
                      value={formData.materialsSustainabilityContactPersonPosition}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Phone"
                      name="materialsSustainabilityContactPersonPhone"
                      variant="outlined"
                      value={formData.materialsSustainabilityContactPersonPhone}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="materialsSustainabilityContactPersonEmail"
                      variant="outlined"
                      value={formData.materialsSustainabilityContactPersonEmail}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="EHS Contact Person"
                      name="ehsContactPersonName"
                      variant="outlined"
                      value={formData.ehsContactPersonName}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Position"
                      name="ehsContactPersonPosition"
                      variant="outlined"
                      value={formData.ehsContactPersonPosition}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Phone"
                      name="ehsPersonPhone"
                      variant="outlined"
                      value={formData.ehsContactPersonPhone}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="ehsContactPersonEmail"
                      variant="outlined"
                      value={formData.ehsContactPersonEmail}
                      onChange={handleChange}
                      margin="normal"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderRadius: "8px", // Set the border-radius here
                          },
                        },
                      }}
                    />
                  </Grid>

                </Grid>
              </Card>
            </Grid>

            <Grid item xs={5} sx={{ display: "flex", flexDirection: "column" }}>
              <Card
                sx={{
                  p: 1,
                  pt: 4,
                  pb: 2,
                  boxShadow:
                    "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1)",
                  border: "1px solid rgba(234, 236, 240, 1)",
                  borderRadius: "12px",
                  height: "100%",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ mb: 2, ml: 2 }}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{
                        fontWeight: 700,
                        fontSize: 16,
                        color: "#344054",
                        display: "inline-block",
                      }}
                    >
                      Edit Notes
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    justifyContent="space-between"
                    sx={{ mt: 0 }}
                  >
                    <Grid item xs={11}>
                    </Grid>
                    <Grid item xs={11}>
                      <TextField
                        fullWidth
                        multiline
                        rows={12}
                        label="Internal Status Notes"
                        name="internalStatusNotes"
                        variant="outlined"
                        value={formData.internalStatusNotes}
                        onChange={handleChange}
                        margin="normal"
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "8px", // Set the border-radius here
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              type="button"
              onClick={onClose}
              sx={{
                mr: 2,
                borderRadius: "8px",
                textTransform: "none",
                color: "#585aeb",
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#585aeb",
                borderRadius: "8px",
                textTransform: "none",
              }}
            >
              Save Changes
            </Button>
          </Box>
        </form>
      </Card>
    </Modal>
  );
};

export default FactoryInformationEditModal;
