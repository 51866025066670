import React from "react";
import { Box, Grid, Typography, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAuditQuickbaseData } from "../../selectors/audit.selector";

const HistoricalAuditDataTab = () => {
  const { qbInfo } = useSelector(selectAuditQuickbaseData);

  // Define layout configuration with placeholders for whitespace
  const layoutConfig = [
    { label: "Supplier Location", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Supplier Name", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Whitespace", size: { xs: 12, sm: 6, md: 4 } }, 
    { label: "Facility Name", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Facility Country", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Facility City/Region", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Report Owner and Payee", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Whitespace", size: { xs: 12, sm: 6, md: 4 } }, 
    { label: "Whitespace", size: { xs: 12, sm: 6, md: 4 } }, 
    { label: "Audit Date From", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Audit Date To", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Audit Range", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Mutual Recognition Assessment", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Assessment Type", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Whitespace", size: { xs: 12, sm: 6, md: 4 } }, 
    { label: "Monitoring Firm Name", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Branch", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Whitespace", size: { xs: 12, sm: 6, md: 4 } }, 
    { label: "Auditors: Primary", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Auditors: Secondary", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Whitespace", size: { xs: 12, sm: 6, md: 4 } }, 
    { label: "Audit Announcement", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Assessment Report Ref", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Whitespace", size: { xs: 12, sm: 6, md: 4 } }, 
    { label: "Audit Overall Rating/Score", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Ref Report Compliance Rating (if accepted through mutual recognition program)", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Whitespace", size: { xs: 12, sm: 6, md: 4 } }, 
    { label: "Transparency Status", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Date of Follow Up Assessment (if applicable)", size: { xs: 12, sm: 6, md: 4 } },
    { label: "Internal Notes", size: { xs: 12 } },
  ];

  return (
    <Box sx={{ p: 4 }}>
      <Grid container spacing={3}>
        {layoutConfig.map(({ label, size }) => (
          <Grid item {...size} key={label}>
            {label !== "Whitespace" ? (
              <TextField
                label={label}
                size="small"
                value={qbInfo[label] || "-"}
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
                sx={{
                  "& .MuiInputBase-input": {
                    color: "#000",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#666",
                  },
                }}
              />
            ) : (
              <Box sx={{ height: "10px" }} /> // Example whitespace
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

//   return (
//     <Box sx={{ p: 4 }}>
//       <Grid container spacing={3}>
//         {layoutConfig.map(({ label, size }) => (
//           <Grid item {...size} key={label}>
//             {label !== "Whitespace" ? (
//               <>
//                 <Typography variant="body2" sx={{ color: "#666" }}>
//                   {label}
//                 </Typography>
//                 <Typography variant="body1" sx={{ color: "#333" }}>
//                   {qbInfo[label] || "-"}
//                 </Typography>
//               </>
//             ) : (
//               <Box sx={{ height: "40px" }} /> // Example whitespace
//             )}
//           </Grid>
//         ))}
//       </Grid>
//     </Box>
//   );
// };

export default HistoricalAuditDataTab;