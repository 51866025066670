import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectSupplierPageTab } from "../../selectors/supplier.selector";
import { setSupplierPageTab } from "../../reducers/supplier.reducer";

const SupplierTabs = () => {
  const dispatch = useDispatch();
  const value = useSelector(selectSupplierPageTab);
  
  const handleChange = (event, newValue) => {
    dispatch(setSupplierPageTab(newValue));
  };
  
  return (
    <Box sx={{ width: "100%", mt: 1, flexGrow: 1 }}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        sx={{ textDecoration: "none" }}
      >
        <Tab value="status" label="Supply Chain Overview" sx={{ textTransform: "none" }} />
        <Tab value="audits" label="Facility List" sx={{ textTransform: "none" }} />
        <Tab value="supplierData" label="Supplier Data" sx={{ textTransform: "none" }} />
        <Tab value="supplierDocs" label="Documentation & Certifications" sx={{ textTransform: "none" }} />
      </Tabs>
    </Box>
  );
};

export default SupplierTabs;