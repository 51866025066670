import React, { useEffect, useState } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import { Box, Typography, Chip, Button, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { selectDueAudits } from "../../selectors/dueAction.selector";
import { useSelector } from "react-redux";
import moment from "moment";
import { AuditStyles } from "../../styles";
const DueAuditsDataGrid = () => {
  const navigate = useNavigate();

  const selectedDueAudits = useSelector(selectDueAudits);
  console.log("EK", selectedDueAudits);

  // useEffect(() => {
  //   // Filter out rows with factoryName "Test"
  //   const updatedFilteredRows = rows.filter(
  //     row => row.factoryName !== "Test"
  //   );
  //   setFilteredRows(updatedFilteredRows);
  // }, [rows]);

  const rows = selectedDueAudits?.filter((audit) => audit.factoryName !== "Test")
  .map((audit) => ({
    id: audit.factoryId,
    factoryId: audit.factoryId,
    factoryName: audit.factoryName,
    supplierId: audit.supplierId,
    organisationId: audit.organisationId,
    tier: audit.tier,
    deminimis: audit.deminimis,
    deminimisYear: audit.deminimisYear,
    daysSinceAudit: audit.daysSinceAudit,
    lastAuditDate: audit.lastAuditDate,
    lastSocialAssessmentType: audit.lastSocialAssessmentType,
    nextAssessmentWindowDateEnd: audit.nextAssessmentWindowDateEnd,
    nextSocialAssessmentType: audit.nextSocialAssessmentType,
    confirmedDateForNextAudit: audit.confirmedDateForNextAudit || "",
    lastAuditResult: audit.lastAuditResult,
    dueStatus: 
    audit.daysSinceAudit === null || typeof audit.daysSinceAudit === 'undefined' || isNaN(audit.daysSinceAudit)
      ? `Unscheduled`
      : audit.daysSinceAudit > 0
        ? `Past ${audit.daysSinceAudit.toString().replace("-", "")} Days`
        : `in ${audit.daysSinceAudit.toString().replace("-", "")} Days`,
    facilityAddress: audit.facilityAddress,
    facilityCountry: audit.facilityCountry,
    facilityCity: audit.facilityCity,
    supplierName: audit.supplierName,
    workerCount: audit.workerCount,
    workerCountProduction: audit.workerCountProduction,
    foreignMigrantWorkers: audit.foreignMigrantWorkers,
    primaryFacilityContact: audit.primaryFacilityContact,
    primaryFacilityContactEmail: audit.primaryFacilityContactEmail,
    agentContact: audit.agentContact,
    agentContactEmail: audit.agentContactEmail,
    supplyChainStatus: audit.supplyChainStatus,
    complianceStatus: audit.complianceStatus,
    irssStatus: audit.irssStatus,
    lastAuditPreviouslyEnrolledinIRSS: audit.lastAuditPreviouslyEnrolledinIRSS,
    nextRecommendedAuditMonth: audit.nextRecommendedAuditMonth,
    nextRecommendedAuditType: audit.nextRecommendedAuditType,
    nextAuditFirm: audit.nextAuditFirm,
    nextAssessmentWindowDateStart: audit.nextAssessmentWindowDateStart,
    openCAPs: audit.openCAPs,
    auditCadence: audit.auditCadence,
  }));

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);

  // Add this filter handling function
  const handleFilterToggle = (filterType) => {
    const newFilters = selectedFilters.includes(filterType)
      ? selectedFilters.filter((f) => f !== filterType)
      : [...selectedFilters, filterType];

    setSelectedFilters(newFilters);
    // Apply filters
    const filtered = rows?.filter((row) => {
      if (newFilters.length === 0) return true;

      if (row?.daysSinceAudit) {
        const daysSinceAudit = row?.daysSinceAudit;

        return newFilters.some((filter) => {
          switch (filter) {
            case "pastDue":
              return daysSinceAudit > 0;
            case "due1to20":
              return daysSinceAudit <= 0 && daysSinceAudit >= -20;
            case "due20to60":
              return daysSinceAudit < -20 && daysSinceAudit >= -60;
            case "due60plus":
              return daysSinceAudit < -60;
            default:
              return true;
          }
        });
      }
    });

    setFilteredRows(filtered);
  };

  useEffect(() => {
    setFilteredRows(rows);
  }, [selectedDueAudits]);

  const columns = [
    {
      field: "factoryName",
      headerName: "Facility Name",
      width: 170,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography
          sx={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            padding: "8px 0px 8px 8px",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "supplierName",
      headerName: "Supplier Name",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "facilityCountry",
      headerName: "Country",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "tier",
      headerName: "Tier",
      width: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "deminimis",
      headerName: "Deminimis?",
      width: 120,
      align: "center",
      headerAlign: "center",
      sortable: false,
      valueFormatter: (value) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "lastAuditDate",
      headerName: "Last Audit Date",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: true,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? moment(value).format("MMM. DD, YYYY") : "";
      },
    },
    {
      field: "lastSocialAssessmentType",
      headerName: "Last Audit Scheme",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "auditCadence",
      headerName: "Audit Cadence",
      width: 150,
      headerAlign: "center",
      renderCell: (params) => (
        <Typography
          sx={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            padding: "8px 0px 8px 8px",
            fontSize: "14px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            height: "100%",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    // {
    //   field: "nextRecommendedAuditMonth",
    //   headerName: "Next Recommended Audit Month",
    //   width: 150,
    //   align: "center",
    //   headerAlign: "center",
    //   sortable: false,
    //   headerClassName: 'multi-line-header',
    //   sortable: true,
    //   valueGetter: (value) => {
    //     return value ? moment(value, "MMM. YYYY").toDate() : null;
    //   },
    //   valueFormatter: (value) => {
    //     return value ? moment(value).format("MMM. YYYY") : "";
    //   },
    // },
    {
      field: "nextRecommendedAuditMonth",
      headerName: "Next Recommended Audit Month",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: 'multi-line-header',
      sortable: true,
      valueGetter: (value) => {
        if (!value) return null;
        //const date = moment(value);
        const date = moment(value, "MMM, YYYY", true);
        return date.isValid() ? date.toDate() : null;
      },
      valueFormatter: (value) => {
        return value ? moment(value).format("MMM. YYYY") : "";
      },
    },
    {
      field: "nextRecommendedAuditType",
      headerName: "Next Recommended Audit Scheme",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "nextSocialAssessmentType",
      headerName: "Next Audit Scheme",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "nextAuditFirm",
      headerName: "Next Audit Firm",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "nextAssessmentWindowDateStart",
      headerName: "Next Confirmed Assessment Date Window - Start",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: true,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? moment(value).format("MMM. DD, YYYY") : "";
      },
    },
    {
      field: "nextAssessmentWindowDateEnd",
      headerName: "Next Confirmed Assessment Date Window - End",
      type: "date",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: true,
      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? moment(value).format("MMM. DD, YYYY") : "";
      },
    },
    {
      field: "confirmedDateForNextAudit",
      headerName: "Confirmed Next Audit Date (Audit Due Date)",
      type: "date",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: true,

      valueGetter: (value) => {
        return value ? new Date(value) : null;
      },
      valueFormatter: (value) => {
        return value ? moment(value).format("MMM. DD, YYYY") : "";
      },
    },
    {
      field: "dueStatus",
      headerName: "Due Status",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => (
        <Chip
          label={params.row.dueStatus}
          sx={{
            borderRadius: "8px",
            backgroundColor:
              params.row.daysSinceAudit > 0 ? "#DC6803" : "#EAECF0",
            color: params.row.daysSinceAudit > 0 ? "#FFF" : "#172B4D",
            fontWeight: "500",
          }}
        />
      ),
    },
    {
      field: "lastAuditResult",
      headerName: "Vendor Rating",
      type: "number",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const rating = params.value?.toLowerCase();
        let backgroundColor;
        let color;
        if (rating.includes("green")) {
          backgroundColor = "#E6F4EA"; // Nicer green background
          color = "#137333"; // Nicer green text
        } else if (rating.includes("yellow")) {
          backgroundColor = "#FEF3C7";
          color = "#B45309";
        } else if (rating.includes("orange")) {
          backgroundColor = "#FFEDD5";
          color = "#9A3412";
        } else if (rating.includes("red")) {
          backgroundColor = "#FEE2E2";
          color = "#B91C1C";
        } else {
          backgroundColor = "#F0F0F0";
          color = "#000";
        }
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            {/* <Typography
              sx={{
                borderRadius: "4px",
                backgroundColor: backgroundColor,
                color: color,
                padding: "4px 12px",
                height: "32px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {params.value || "-"}
            </Typography> */}
            <Chip
          label={params.value}
          sx={{
            borderRadius: "8px",
            backgroundColor: backgroundColor,
            color: color,
            fontWeight: "500",
          }}
        />
          </Box>
        );
      },
    },
    {
      field: "supplyChainStatus",
      headerName: "Supply Chain Status",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "complianceStatus",
      headerName: "Compliance Status",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "irssStatus",
      headerName: "IRSS Status",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "lastAuditPreviouslyEnrolledinIRSS",
      headerName: "Last Audit Previously Enrolled in IRSS",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
      valueFormatter: (value) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "openCAPs",
      headerName: "Open CAPs?",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
      valueFormatter: (value) => {
        return value ? "Yes" : "No";
      },
    },
    {
      field: "facilityAddress",
      headerName: "Facility Address",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "facilityCity",
      headerName: "Facility City",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "workerCount",
      headerName: "Worker Count",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "workerCountProduction",
      headerName: "Total Number of Production Employees",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "foreignMigrantWorkers",
      headerName: "Number of Foreign Migrant Workers",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "primaryFacilityContact",
      headerName: "Primary Facility Contact",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "primaryFacilityContactEmail",
      headerName: "Primary Facility Contact Email",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "agentContact",
      headerName: "Agent Contact",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "agentContactEmail",
      headerName: "Agent Contact Email",
      width: 150,
      align: "center",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "details",
      headerName: "",
      width: 150,
      sortable: false,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={() => {
            window.history.pushState(null, "", "/dueAction");

            navigate(
              `/suppliers/${params.row.supplierId}/factory/${params.row.factoryId}`,
              { replace: true }
            );
          }}
          sx={{
            backgroundColor: "#4B68D4",
            color: "white",
            "&:hover": {
              backgroundColor: "#3A54B6",
            },
            textTransform: "none",
            width: "79px",
            height: "40px",
            ml: 4,

            borderRadius: "8px",
          }}
          size="small"
        >
          To Facility
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ width: "100%", padding: "0px 48px", mt: 1 }}>
        <Typography
          style={{
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "30px",
            marginTop: "30px",
          }}
        >
          Audit Scheduling
        </Typography>
        {!rows ? (
          <Box sx={{ width: "100%" }}>
            {[...Array(10)].map((_, index) => (
              <Skeleton
                key={index}
                height={60}
                animation="wave"
                sx={{ my: 1 }}
              />
            ))}
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2, display: "flex" }}>
              <Button
                variant={
                  selectedFilters.includes("pastDue") ? "contained" : "outlined"
                }
                onClick={() => handleFilterToggle("pastDue")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Confirmed Audit Date: Past Due
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due1to20")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due1to20")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Confirmed Audit Date: Due in 1-20 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due20to60")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due20to60")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Confirmed Audit Date: Due in 20-60 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due60plus")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due60plus")}
                sx={{
                  //   borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Confirmed Audit Date: Due in 60+ Days
              </Button>
            </Box>
            <div style={{ height: "calc(100vh - 350px)", width: "100%" }}>
              <DataGridPro
                columns={columns}
                rows={filteredRows || []}
                //disableColumnSelector
                disableDensitySelector
                pageSize={5}
                rowsPerPageOptions={[5, 10, 15]}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                getRowHeight={() => 70}
                sx={{
                  borderRadius: "8px",

                  "& .MuiDataGrid-main": {
                    // Target the main container

                    "& .MuiDataGrid-virtualScroller": {
                      // Target the virtual scroller
                      height: "100% !important",
                      "& .MuiDataGrid-virtualScrollerContent": {
                        // Target the virtual scroller content
                        height: "auto !important",
                        "& .MuiDataGrid-virtualScrollerRenderZone": {
                          // Target the render zone
                          "& .MuiDataGrid-row": {
                            // Target each row
                            maxHeight: "none !important",

                            "& .MuiDataGrid-cell": {
                              // Target each cell
                              alignItems: "center",
                            },
                          },
                        },
                      },
                    },
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f0f0f0",
                    fontWeight: "500",
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    whiteSpace: 'normal',
                    lineHeight: '1.2',
                    textAlign: 'center',
                  },
                }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      facilityAddress: false,
                      facilityCity: false,
                      primaryFacilityContact: false,
                      primaryFacilityContactEmail: false,
                      agentContact: false,
                      agentContactEmail: false,
                      workerCount: false,
                      workerCountProduction: false,
                      foreignMigrantWorkers: false,
                    },
                  },
                }}
              />
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DueAuditsDataGrid;
