const BASE_URL =
  process.env.BASE_URL || "https://s-auditsense-et6rmlvh6q-uc.a.run.app";
const LOCAL = `${BASE_URL}/v1`;
// const LOCAL = `http://localhost:3000/v1`;

export const USER = {
  LOGIN: `${LOCAL}/users/login`,
  GET_USERS_BY_ORGANISATION_ID: `${LOCAL}/users/organisation/<ORGANISATION_ID>`,
};

export const EMAIL = {
  SEND_TO_MENTIONED: `${LOCAL}/emails/sendEmails/mentionedUsers`,
};

export const SUPPLIERS = {
  LIST: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>`,
  SEARCH: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/search`,
  GET_BY_ID: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>`,
  UPDATE_BY_ID: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>`,
  CREATE_NEW_FROM_SAQ: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/create-new`,
  CREATE_SUPPLIER: `${LOCAL}/suppliers/organisations/<ORGANISATION_ID>/suppliers`,
};

export const FACTORIES = {
  LIST: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>`,
  SEARCH: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/search`,
  GET_LIST_BY_ORGANISATION_ID: `${LOCAL}/factories/organisations/<ORGANISATION_ID>`,
  GET_BY_ID: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>`,
  UPDATE_BY_ID: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>`,
  UPLOAD_FILE: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/upload`,
  GET_SIGNED_URL: `${LOCAL}/factories/files/signed-url`,
  CREATE_NEW_FROM_SAQ: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/create-new`,
  GENERATE_REF_ID: `${LOCAL}/factories/generateRefId`,
  CREATE_FACTORY: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factory`,
  GET_DUE_ACTION_CERTIFICATES_DATA: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/dueAction/certificates`,
  EXPORT_FACILITY_DATA: `${LOCAL}/factories/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/export`,
};

export const AUDITS = {
  LIST: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>`,
  GET_BY_ID: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>`,
  UPDATE_BY_ID: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>`,
  UPLOAD_REPORT: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>/upload`,
  PROCESS_REPORT: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>/process`,
  REGENERATE_CAP: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>/regenerateCap`,
  DELETE_CAP_DETAILS_BY_ID: `${LOCAL}/audits/organisations/<ORGANISATION_ID>/suppliers/<SUPPLIER_ID>/factories/<FACTORY_ID>/audits/<AUDIT_ID>/capdetails/<FINDING_ID>`,
};

export const ORGANISATIONS = {
  GET_RISK_CHART_DATA: `${LOCAL}/organisations/<ORGANISATION_ID>/chart`,
  GET_DUE_CAPS: `${LOCAL}/organisations/<ORGANISATION_ID>/dueCaps`,
  GET_DUE_AUDITS: `${LOCAL}/organisations/<ORGANISATION_ID>/dueAuditsRef`,
  GET_DUE_IRSS: `${LOCAL}/organisations/<ORGANISATION_ID>/dueIrssRef`,
};

export const ANALYTICS = {
  GET_URL: `${LOCAL}/analytics/organisations/<ORGANISATION_ID>/`,
};
