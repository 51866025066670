import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";

const selectAudit = (state) => state.audit;

const processAuditScore = (audit) => {
  if (!audit.isHistoricalAudit) {
    return audit.risk?.auditRating;
  }

  const colors = ["green", "red", "yellow", "orange"];

  // Check auditRating for color strings
  const auditRating = audit.metadata?.refReportComplianceRating.toLowerCase();
  if (audit.metadata?.refReportComplianceRating) {
    //console.log("auditRating inside", auditRating);
    return audit.metadata?.refReportComplianceRating;
  }

  // Check auditScore for color strings
  const auditScore = audit.metadata?.auditRating.toLowerCase();
  if (colors.some((color) => auditScore.includes(color))) {
    return audit.metadata?.auditRating;
  }

  let numericScore = parseFloat(auditScore);

  if (isNaN(numericScore)) {
    const match = auditScore.match(/(\d+)\//);
    if (match) {
      numericScore = parseFloat(match[1]);
    }
  }

  if (!isNaN(numericScore)) {
    if (numericScore >= 91) {
      return "GREEN";
    } else if (numericScore >= 71) {
      return "YELLOW";
    } else if (numericScore >= 51) {
      return "ORANGE";
    } else {
      return "RED";
    }
  }

  return audit.auditScore;
};

const processAuditScoreList = (audit) => {
  if (!audit.isHistoricalAudit) {
    return audit.auditScore;
  }

  const colors = ["green", "red", "yellow", "orange"];

  // Check auditRating for color strings
  const auditRating = audit.auditRating.toLowerCase();
  if (colors.some((color) => auditRating.includes(color))) {
    return audit.auditRating;
  }

  // Check auditScore for color strings
  const auditScore = audit.auditScore.toLowerCase();
  if (colors.some((color) => auditScore.includes(color))) {
    return audit.auditScore;
  }

  let numericScore = parseFloat(auditScore);

  if (isNaN(numericScore)) {
    const match = auditScore.match(/(\d+)\//);
    if (match) {
      numericScore = parseFloat(match[1]);
    }
  }

  if (!isNaN(numericScore)) {
    if (numericScore >= 91) {
      return "GREEN";
    } else if (numericScore >= 71) {
      return "YELLOW";
    } else if (numericScore >= 51) {
      return "ORANGE";
    } else {
      return "RED";
    }
  }

  return audit.auditScore;
};

export const selectAuditList = createSelector(
  [selectAudit],
  (audit) => audit.audits
);

// export const selectAuditListForFactory = createSelector(
//   [selectAudit, (_, factoryId) => factoryId],
//   (audit, factoryId) =>
//     factoryId
//       ? audit.audits.filter(
//           (audit) => audit.factoryId.toString() === factoryId.toString()
//         )
//       : audit.audits
// );

export const selectAuditListForFactory = createSelector(
  [selectAudit, (_, factoryId) => factoryId],
  (audit, factoryId) =>
    factoryId
      ? audit.audits
          .filter(
            (audit) => audit.factoryId.toString() === factoryId.toString()
          )
          .map((audit) => ({
            ...audit,
            auditScore: processAuditScoreList(audit),
          }))
      : audit.audits.map((audit) => ({
          ...audit,
          auditScore: processAuditScoreList(audit),
        }))
);

export const selectCapListForFactory = createSelector(
  [selectAudit, (_, factoryId) => factoryId],
  (audit, factoryId) => {
    const audits = factoryId
      ? audit.audits.filter(
          (audit) => audit.factoryId.toString() === factoryId.toString()
        )
      : audit.audits;

    const CapList = audits
      .filter((audit) => audit.state === "active")
      .flatMap(
        (audit) =>
          audit.issueDetails?.capDetails?.map((capDetails) => ({
            auditId: audit.auditId,
            ...capDetails,
          })) || []
      );

    return CapList;
  }
);

export const selectMostRecentAuditIdForFactory = createSelector(
  [selectAudit, (_, factoryId) => factoryId],
  (audit, factoryId) =>
    factoryId
      ? audit.audits.filter(
          (audit) => audit.factoryId.toString() === factoryId.toString()
        )[0]?.auditId
      : audit.audits[0]?.auditId
);

export const selectAuditNameFromId = createSelector(
  [selectAudit, (_, id) => id],
  (audit, id) => {
    if (id && id !== "" && audit.audits.length > 0) {
      const auditName = audit.audits.filter(
        (audit) => audit.auditId.toString() === id.toString()
      );
      return auditName[0]?.metadata?.auditType;
    } else {
      return null;
    }
  }
);

// export const selectAuditData = createSelector(
//   [selectAudit],
//   (audit) => audit.auditData
// );
export const selectAuditData = createSelector([selectAudit], (audit) => {
  if (!audit.auditData) return null;

  return {
    ...audit.auditData,
    auditScore: processAuditScore(audit.auditData),
  };
});

export const selectSelectedAuditIssueId = createSelector(
  [selectAudit],
  (audit) => audit.selectedAuditIssueId
);

export const selectAuditXlsxFile = createSelector([selectAudit], (audit) =>
  audit.auditData?.files?.filter((file) => file.type === "xlsx")
);

export const selectAuditPageTab = createSelector(
  [selectAudit],
  (audit) => audit.auditPageTab
);

export const selectAuditReportPageTab = createSelector(
  [selectAudit],
  (audit) => audit.auditReportPageTab
);

export const selectAuditIssuesData = createSelector([selectAudit], (audit) => {
  const issueDetails = audit.auditData?.issueDetails?.issueDetails || {};
  const severityCategories =
    audit.auditData?.issueDetails?.severityCategories || {};

  // Create a new object with the desired order, including "Moderate"
  const orderedCategories = {
    ZT: severityCategories["ZT"] || "",
    Critical: severityCategories["Critical"] || "",
    Major: severityCategories["Major"] || "",
    Moderate: "#667085", // Add "Moderate" with the same color as "Minor"
    Minor: severityCategories["Minor"] || "",
    Unsure: "black" || "",
  };

  // Use the new orderedCategories object instead of modifying the original
  const updatedSeverityCategories = { ...orderedCategories };

  const detailedIssues = Object.keys(updatedSeverityCategories).map(
    (category) => ({
      category,
      open: issueDetails.open?.[category] || 0,
      pastDue: issueDetails.pastDue?.[category] || 0,
      totalClosed: issueDetails.closed?.[category] || 0,
      color: updatedSeverityCategories[category],
    })
  );

  const issuesSummary = {
    open: detailedIssues.reduce((acc, curr) => acc + (curr.open || 0), 0),
    pastDue: detailedIssues.reduce((acc, curr) => acc + (curr.pastDue || 0), 0),
    totalClosed: detailedIssues.reduce(
      (acc, curr) => acc + (curr.totalClosed || 0),
      0
    ),
  };
  return {
    total: detailedIssues.reduce(
      (acc, curr) => acc + ((curr.open || 0) + (curr.totalClosed || 0)),
      0
    ),
    issuesSummary,
    detailedIssues,
  };
});

export const selectAuditIssueCAPData = createSelector(
  [selectAudit],
  (audit) => {
    const capStartDate = moment(
      new Date(
        audit?.auditData?.metadata?.capStartDate ||
          audit?.auditData?.metadata?.auditEndDate
      )
    ).isValid()
      ? new Date(
          audit?.auditData?.metadata?.capStartDate ||
            audit?.auditData?.metadata?.auditEndDate
        )
      : null;
    const currentDate = new Date();

    return audit?.auditIssueCAPData
      ? Object.values(audit.auditIssueCAPData).map((issue) => {
          const timelineDays = issue.timeline?.match(/(\d+)\s*months?/);
          const timelineNumber = timelineDays
            ? parseInt(timelineDays[1], 10) * 30
            : 0;
          const dueInDays = capStartDate
            ? Math.floor(
                (capStartDate.getTime() +
                  timelineNumber * 24 * 60 * 60 * 1000 -
                  currentDate.getTime()) /
                  (24 * 60 * 60 * 1000)
              )
            : null;

          return {
            id: issue.id,
            clientCodeTitle: issue.clientCodeTitle,
            issue: issue.issueSummary,
            type: issue.type,
            subType: issue.subType,
            severity: issue.severity,
            statusChipLabel: issue.statusChipLabel,
            status: issue.status,
            timeline: issue.timeline,
            note: issue.note || null,
            dueInDays: dueInDays,
          };
        })
      : [];
  }
);

export const selectAuditIssueCAPDataGroupedByType = createSelector(
  [selectAudit],
  (audit) => {
    if (audit.auditIssueCAPData) {
      const data = Object.values(audit.auditIssueCAPData).reduce(
        (acc, issue) => {
          acc[issue.type] = acc[issue.type] || [];
          acc[issue.type].push({ id: issue.id, issue: issue.issueSummary });
          return acc;
        },
        {}
      );

      return data;
    } else {
      return {};
    }
  }
);

export const selectAuditIssueDetails = createSelector(
  [selectAudit],
  (audit) => {
    const selectedIssueId = audit.selectedAuditIssueId;

    return selectedIssueId && audit.auditIssueCAPData[selectedIssueId]
      ? audit.auditIssueCAPData[selectedIssueId]
      : {};
  }
);

export const selectAuditQuickbaseData = createSelector(
  [selectAudit],
  (audit) => {
    const metadata = audit.auditData?.metadata || null;

    const isHistoricalAudit = audit.auditData?.isHistoricalAudit;

    const qbInfo = {
      "Supplier Location": metadata?.supplierLocation,
      "Supplier Name": metadata?.supplierName,
      "Facility Name": metadata?.facilityName,
      "Facility Country": metadata?.facilityCountry,
      "Facility City/Region": metadata?.facilityCity,
      "Report Owner and Payee": metadata?.reportOwnerAndPayee,
      "Audit Date From": metadata?.auditStartDate,
      "Audit Date To": metadata?.auditEndDate,
      "Audit Range": metadata?.auditRange,
      "Mutual Recognition Assessment": metadata?.mutualRecognitionAssessment,
      "Assessment Type": metadata?.auditType,
      "Monitoring Firm Name": metadata?.auditFirm,
      Branch: metadata?.auditFirmBranch,
      "Auditors: Primary": metadata?.auditorsPrimary,
      "Auditors: Secondary": metadata?.auditorsSecondary,
      "Assessment Report Ref": metadata?.assessmentReportRef,
      "Audit Announcement": metadata?.auditAnnouncement,
      "Audit Overall Rating/Score": metadata?.auditRating,
      "Ref Report Compliance Rating (if accepted through mutual recognition program)":
        metadata?.refReportComplianceRating,
      "Transparency Status": metadata?.transparencyStatus,
      "Date of Follow Up Assessment (if applicable)":
        metadata?.followUpAssessmentDate,
      "Internal Notes": metadata?.internalNotes,
    };

    return {
      isHistoricalAudit,
      qbInfo,
    };
  }
);
