import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Tooltip,
  Chip
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectFactoryData } from "../../selectors/factory.selector";
import { getListOfAllAudits, getAuditById } from "../../reducers/audit.reducer";
import RiskCard from "../riskCard.component";
import FacilityIssuesCard from "./facilityIssuesCard.component";
import TwoLineCard from "../twoLineCard.component";
import { FacilityStyles, AuditStyles, ComponentStyles } from "../../styles";
import moment from "moment";

const getColorForRating = (rating) => {
  switch (rating) {
    case "Green (A)":
      return AuditStyles.auditRatingColors.green;
    case "Yellow (B)":
      return AuditStyles.auditRatingColors.yellow;
    case "Orange (C)":
      return AuditStyles.auditRatingColors.orange;
    case "Red (D)":
      return AuditStyles.auditRatingColors.red;
    default:
      return "black";
  }
};


const FactoryMostRecentAuditDetails = ({ selectedFactory }) => {
  const auditEndDate = selectedFactory?.latestAudit?.metadata?.auditEndDate;
  const nextAuditDate =
    selectedFactory?.metadata?.socialAssessmentInfo?.confirmedAuditDate;
  const formattedNextAuditDate = nextAuditDate
    ? moment(nextAuditDate).format("MMM. DD, YYYY")
    : "-";
  const daysUntilNextAudit = nextAuditDate
    ? moment(nextAuditDate).diff(moment(), "days")
    : null;
  console.log("daysUntilNextAudit");
  const nextAuditType =
    selectedFactory?.metadata?.socialAssessmentInfo?.generalAuditType;
  const rating =
    selectedFactory?.latestAudit?.risk?.auditRating || "Coming Soon.";
    console.log("selectedFactory", selectedFactory);

  const formatDate = (dateString) => {
    return dateString ? moment(dateString).format("MMM, YYYY") : "-";
  };

  console.log("selectedFactory?.auditCadence", selectedFactory?.auditCadence);

  return (
    <>
      <Typography sx={FacilityStyles.mostRecentAuditHeading}>
        Details from Most Recent Audit on <u>{auditEndDate}</u>
      </Typography>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          md={4}
          container
          direction="column"
          spacing={2}
          sx={{ height: "100%" }}
        >
          <Grid item sx={{ flexGrow: 1.1 }}>
            <TwoLineCard
              title="Rating"
              nextAuditDate={rating}
              textColor={getColorForRating(rating)}
            />
          </Grid>
          <Grid item sx={{ flexGrow: 1.1 }}>
            <TwoLineCard
              title="Audit Type"
              nextAuditDate={
                selectedFactory?.latestAudit?.metadata?.auditType || "-"
              }
            />
          </Grid>
        </Grid>
        <Grid item sm={12} md={8}>
          <FacilityIssuesCard latestAuditData={selectedFactory.latestAudit} />
        </Grid>
        <Grid item xs={12}>
          <TwoLineCard
            title="ConfirmedNext Audit Date and Type"
            nextAuditDate={
              <>
                {formattedNextAuditDate}
                {daysUntilNextAudit !== null && (
                  <Box
                    component="span"
                    sx={{
                      marginLeft: 1,
                      padding: "3px 8px",
                      fontSize: "18px",
                      fontWeight: 500,
                      borderRadius: "4px",
                      border: "1px solid #D0D5DD",
                      background: "white",
                      color: "#344054",
                      display: "inline-block",
                      verticalAlign: "middle",
                    }}
                  >
                    {daysUntilNextAudit !== null
                      ? `In ${daysUntilNextAudit} Days`
                      : ""}
                  </Box>
                )}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {` for`}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {selectedFactory?.metadata?.socialAssessmentInfo
                  ?.generalAuditType || "-"}
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined" style={ComponentStyles.twoLineThreeLineCard}>
            <CardContent style={{ padding: "16px 32px" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                <Typography
                  style={{
                    fontSize: 18,
                    fontWeight: 700,
                    color: "#344054",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Audit Cadence Calculation
                </Typography>
                <Tooltip title="The Audit cadence calculuation is in beta release version requesting for comment and feedback. If you spot any abnormality, please reach out to the Elm AI Team!">
                  <Chip 
                    label="Beta" 
                    size="small"
                    sx={{
                      backgroundColor: '#4C5ED9',
                      color: 'white',
                      fontWeight: 500,
                      fontSize: '12px',
                      height: '24px'
                    }}
                  />
                </Tooltip>
              </Box>
              <Divider />
              
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} sx={{ pr: 1 }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Last Audit Date
                    </Typography>
                    <Typography sx={ComponentStyles.fieldValue}>
                      {selectedFactory?.latestAudit?.metadata?.auditEndDate || "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sx={{ pr: 1 }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Next Audit Date
                    </Typography>
                    <Typography sx={ComponentStyles.fieldValue}>
                      {selectedFactory?.auditCadence?.nextAuditDate || "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sx={{ pr: 1 }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Audit Cadence
                    </Typography>
                    <Typography sx={ComponentStyles.fieldValue}>
                      {selectedFactory?.auditCadence?.supplementaryData?.auditCadence || "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider sx={{ my: 2 }} />
                  </Grid>

                  <Grid item xs={4} sx={{ pr: 1 }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Location
                    </Typography>
                    <Typography sx={ComponentStyles.fieldValue}>
                      {selectedFactory?.auditCadence?.supplementaryData?.location || "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sx={{ pr: 1 }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Country Risk
                    </Typography>
                    <Typography sx={ComponentStyles.fieldValue}>
                      {selectedFactory?.auditCadence?.supplementaryData?.countryRisk || "-"} ({selectedFactory?.metadata?.country || "-"})
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sx={{ pr: 1 }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      ZT/Critical Issues
                    </Typography>
                    <Typography sx={ComponentStyles.fieldValue}>
                      {selectedFactory?.auditCadence?.supplementaryData?.hasZTorCritical ? "Yes" : "No"}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sx={{ pr: 1 }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Original Audit Grade
                    </Typography>
                    <Typography sx={ComponentStyles.fieldValue}>
                      {selectedFactory?.auditCadence?.supplementaryData?.grade || "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sx={{ pr: 1 }}>
                    <Typography sx={ComponentStyles.fieldName}>
                    After-CAP Updated Audit Grade
                    </Typography>
                    <Typography sx={ComponentStyles.fieldValue}>
                      {selectedFactory?.auditCadence?.supplementaryData?.updatedAuditGrade || "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sx={{ pr: 1 }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Authorization Status
                    </Typography>
                    <Typography sx={ComponentStyles.fieldValue}>
                      {selectedFactory?.metadata?.complianceStatus || "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} sx={{ pr: 1 }}>
                    <Typography sx={ComponentStyles.fieldName}>
                      Last Audit Type
                    </Typography>
                    <Typography sx={ComponentStyles.fieldValue}>
                      {selectedFactory?.latestAudit?.metadata?.auditType || "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default FactoryMostRecentAuditDetails;
