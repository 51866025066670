import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Chip,
  Skeleton,
} from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

import { debounce } from "lodash";
import moment from "moment";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import {
  getListOfAllSuppliers,
  getSearchResultOfSuppliers,
  getSupplierById,
} from "../../reducers/supplier.reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import {
  getFactoryById,
  getListOfAllFactories,
  getSearchResultOfFactories,
} from "../../reducers/factory.reducer";
import { selectOrganisationId } from "../../selectors/login.selector";
import {
  selectFactoryCache,
  selectFactoryList,
  selectSearchCount,
  selectSearchResultsFactoryList,
  selectTotalCount,
} from "../../selectors/factory.selector";
import { selectLoadingState } from "../../selectors/misc.selector";

const FacilityMetaDataGrid = () => {
  const dispatch = useDispatch();
  const factoriesList = useSelector(selectFactoryList);
  const isLoading = useSelector(selectLoadingState);
  const totalCount = useSelector(selectTotalCount);

  const navigateToFactoryDetails = (supplierId, factoryId) => {
    dispatch(getFactoryById({ supplierId, factoryId }));
    dispatch(getSupplierById(supplierId));
    navigate(`/suppliers/${supplierId}/factory/${factoryId}`);
  };
  

  const rows = factoriesList.map((factory) => {
    console.log(factory);
    return {
      id: factory?.factoryId ?? "", // Unique ID for the grid's row key
      factoryIdFromMetadata: factory?.factoryIdFromMetadata ?? "",
      factoryName: factory?.name ?? "",
      associatedSupplier: factory?.supplierName ?? "",
      statusOfCompliance: factory?.metadata?.complianceStatus ?? "",
      tier: factory?.tier ?? "",
      factoryAddress: factory?.metadata?.addressLine1 ?? "",
      factoryCountry: factory?.metadata?.country ?? "",
      factoryCity: factory?.metadata?.city ?? "",
      factoryState: factory?.metadata?.state ?? "",
      doNotReportCheck: factory?.metadata?.doNotReportCheck ?? "",
      deMinimisCheck: factory?.metadata?.deMinimis?.deMinimisCheck === false ? "" : (factory?.metadata?.deMinimis?.deMinimisCheck ?? ""),
      deMinimisYear: factory?.metadata?.deMinimis?.deMinimisYear ?? "",
      domesticOrInternational: factory?.metadata?.domesticOrInternational ?? "",
      vendorRating: factory?.metadata?.vendorRating ?? "",
      complianceAuthorizationDate: factory?.metadata?.miscDates?.complianceAuthorizationDate ? moment(factory?.metadata?.miscDates?.complianceAuthorizationDate).format('MMMM D, YYYY') : '',
      supplyChainStatus: factory?.metadata?.supplyChainStatus ?? "",
      supplyChainPriority: factory?.metadata?.supplyChainPriority ?? "",
      potentialInitialPODate: factory?.metadata?.potentialInitialPODate ? moment(factory?.metadata?.potentialInitialPODate).format('MMMM D, YYYY') : '',
      irssCheck: factory?.metadata?.irss?.check ?? "",
      irssProgramEndDate: factory?.metadata?.irss?.programEndDate ? moment(factory?.metadata?.irss?.programEndDate).format('MMMM D, YYYY') : '',
      irssStatus: factory?.metadata?.irss?.status ?? "",
      lastAssessmentDate: factory?.metadata?.socialAssessmentInfo?.lastAssessmentDate ? moment(factory?.metadata?.socialAssessmentInfo?.lastAssessmentDate).format('MMMM D, YYYY') : '',
      lastSocialAssessmentType: factory?.metadata?.socialAssessmentInfo?.lastSocialAssessmentType ?? "",
      lastSocialAssessmentFirm: factory?.metadata?.socialAssessmentInfo?.lastSocialAssessmentFirm ?? "",
      lastSocialAssessmentPayer: factory?.metadata?.socialAssessmentInfo?.lastSocialAssessmentPayer ?? "",
      nextAssessmentWindowDateStart: factory?.metadata?.socialAssessmentInfo?.nextAssessmentWindowDateStart ? moment(factory?.metadata?.socialAssessmentInfo?.nextAssessmentWindowDateStart).format('MMMM D, YYYY') : '',
      nextAssessmentWindowDateEnd: factory?.metadata?.socialAssessmentInfo?.nextAssessmentWindowDateEnd ? moment(factory?.metadata?.socialAssessmentInfo?.nextAssessmentWindowDateEnd).format('MMMM D, YYYY') : '',
      generalAuditType: factory?.metadata?.socialAssessmentInfo?.generalAuditType ?? "",
      generalAuditFirm: factory?.metadata?.socialAssessmentInfo?.generalAuditFirm ?? "",
      confirmedAuditDate: factory?.metadata?.socialAssessmentInfo?.confirmedAuditDate ? moment(factory?.metadata?.socialAssessmentInfo?.confirmedAuditDate).format('MMMM D, YYYY') : '',
      socialAssessmentPayer: factory?.metadata?.socialAssessmentInfo?.socialAssessmentPayer ?? "",
      socialResponsibilitySignOff: factory?.metadata?.socialResponsibilitySignOff ?? "",
      environmentalResponsibilitySignOff: factory?.metadata?.environmentalResponsibilitySignOff ?? "",
      productCategories: factory?.metadata?.productCategories ?? "",
      productionProcesses: factory?.metadata?.processInfo?.productionProcesses ?? "",
      productionContractType: factory?.metadata?.processInfo?.productionContractType ?? "",
      supplyChainTeam: factory?.metadata?.supplyChainTeam ?? "",
      supplyChainContact: factory?.metadata?.supplyChainContact ?? "",
      facilityAffiliation: factory?.metadata?.facilityAffiliation ?? "",
      existingClients: factory?.metadata?.existingClients ?? "",
      peakSeason: factory?.metadata?.productionSeasonalityPeak ?? "",
      lowSeason: factory?.metadata?.productionSeasonalityLow ?? "",
      minOrderQty: factory?.metadata?.minOrderQty ?? "",
      avgLeadTime: factory?.metadata?.avgLeadTime ?? "",
      machineCount: factory?.metadata?.machineCount ?? "",
      avgUnitsProducedPerWeek: factory?.metadata?.avgUnitsProducedPerWeek ?? "",
      size: factory?.metadata?.size ?? '',
      domesticMigrantWorkers: factory?.metadata?.domesticMigrantWorkers ?? '',
      foreignMigrantWorkers: factory?.metadata?.foreignMigrantWorkers ?? '',
      homeWorkers: factory?.metadata?.homeWorkers ?? '',
      productionSeasonalityLow: factory?.metadata?.productionSeasonalityLow ?? '',
      productionSeasonalityPeak: factory?.metadata?.productionSeasonalityPeak ?? '',
      siteSize: factory?.metadata?.siteSize ?? '',
      workerCountTotal: factory?.metadata?.workerCountTotal ?? '',
      workerGenderFemale: factory?.metadata?.workerGenderFemale ?? '',
      workerGenderMale: factory?.metadata?.workerGenderMale ?? '',
      workerGenderOther: factory?.metadata?.workerGenderOther ?? '',
      youngWorkers: factory?.metadata?.youngWorkers ?? '',
      irssCheck: factory?.metadata?.irss?.check ?? '',
      irssProgramEndDate: factory?.metadata?.irss?.programEndDate ? moment(factory?.metadata?.irss?.programEndDate).format('MMMM D, YYYY') : '',
      irssStatus: factory?.metadata?.irss?.status ?? '',
      supplierType: factory?.metadata?.processInfo?.supplierType ?? "",
      unionPresent: factory?.metadata?.freedomOfAssoc?.unionCheck ?? "",
      cbaPresent: factory?.metadata?.freedomOfAssoc?.cbaCheck ?? "",
      osId: factory?.metadata?.ids?.osId ?? "",
    };
  });

  const columns = [
    {
      field: "factoryIdFromMetadata",
      headerName: "Facility ID",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "factoryName",
      headerName: "Facility Name",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "associatedSupplier",
      headerName: "Associated Supplier",
      width: 250,
      align: "center",
      headerAlign: "center",
    },
   
    {
      field: "tier",
      headerName: "Tier",
      width: 80,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "statusOfCompliance",
      headerName: "Compliance Status",
      width: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "factoryAddress",
      headerName: "Facility Address",
      width: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "factoryCountry",
      headerName: "Country",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "factoryCity",
      headerName: "City",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "factoryState",
      headerName: "State",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "supplierType",
      headerName: "Facility Type",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "unionPresent",
      headerName: "Union Present",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "cbaPresent",
      headerName: "CBA Present",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "osId",
      headerName: "OS ID",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "doNotReportCheck",
      headerName: "Do Not Report Check",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "deMinimisCheck",
      headerName: "De Minimis Check",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "deMinimisYear",
      headerName: "De Minimis Year",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "domesticOrInternational",
      headerName: "Domestic/International",
      width: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "vendorRating",
      headerName: "Vendor Rating",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "complianceAuthorizationDate",
      headerName: "Compliance Authorization Date",
      width: 220,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "supplyChainStatus",
      headerName: "Supply Chain Status",
      width: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "supplyChainPriority",
      headerName: "Supply Chain Priority",
      width: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "potentialInitialPODate",
      headerName: "Potential Initial PO Date",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "irssCheck",
      headerName: "IRSS Check",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "irssProgramEndDate",
      headerName: "IRSS Program End Date",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "irssStatus",
      headerName: "IRSS Status",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastAssessmentDate",
      headerName: "Last Assessment Date",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastSocialAssessmentType",
      headerName: "Last Social Assessment Type",
      width: 220,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastSocialAssessmentFirm",
      headerName: "Last Social Assessment Firm",
      width: 220,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lastSocialAssessmentPayer",
      headerName: "Last Social Assessment Payer",
      width: 220,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nextAssessmentWindowDateStart",
      headerName: "Next Assessment Window Start",
      width: 220,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "nextAssessmentWindowDateEnd",
      headerName: "Next Assessment Window End",
      width: 220,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "generalAuditType",
      headerName: "General Audit Scheme",
      width: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "generalAuditFirm",
      headerName: "General Audit Firm",
      width: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "confirmedAuditDate",
      headerName: "Confirmed Audit Date",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "socialAssessmentPayer",
      headerName: "Social Assessment Payer",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "socialResponsibilitySignOff",
      headerName: "Social Responsibility Sign Off",
      width: 240,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "environmentalResponsibilitySignOff",
      headerName: "Environmental Responsibility Sign Off",
      width: 280,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productCategories",
      headerName: "Product Type",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productionProcesses",
      headerName: "Production Processes",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "productionContractType",
      headerName: "Production Contract Type",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "supplyChainTeam",
      headerName: "Supply Chain Team",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "supplyChainContact",
      headerName: "Supply Chain Contact",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "facilityAffiliation",
      headerName: "Facility Affiliation",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "existingClients",
      headerName: "Existing Clients",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "peakSeason",
      headerName: "Peak Season",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "lowSeason",
      headerName: "Low Season",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "minOrderQty",
      headerName: "Minimum Order Quantity",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "avgLeadTime",
      headerName: "Average Lead Time",
      width: 160,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "machineCount",
      headerName: "Machine Count",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "avgUnitsProducedPerWeek",
      headerName: "Avg Units Produced/Week",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "size",
      headerName: "Size",
      width: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "domesticMigrantWorkers",
      headerName: "Domestic Migrant Workers",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "foreignMigrantWorkers",
      headerName: "Foreign Migrant Workers",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "homeWorkers",
      headerName: "Home Workers",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "siteSize",
      headerName: "Site Size",
      width: 120,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerCountTotal",
      headerName: "Total Workers",
      width: 130,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerGenderFemale",
      headerName: "Female Workers",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerGenderMale",
      headerName: "Male Workers",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workerGenderOther",
      headerName: "Other Gender Workers",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "youngWorkers",
      headerName: "Young Workers",
      width: 140,
      align: "center",
      headerAlign: "center",
    },
    
  ];

  const initialState = {
    columns: {
      columnVisibilityModel: {},
    },
    filter: {
      filterModel: {
        items: [],
      },
    },
  };

  const dataGridFormat = {
    columns,
    rows,
    initialState,
  };

  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredFactories, setFilteredFactories] = useState([]);

  const [count, setCount] = useState(totalCount);

  const factoryCache = useSelector((state) => selectFactoryCache(state, page));
  const navigate = useNavigate();

  const organisationId = useSelector(selectOrganisationId);
  const searchCount = useSelector(selectSearchCount);
  const searchResults = useSelector(selectSearchResultsFactoryList);

  useEffect(() => {
    if (searchTerm !== "") {
      setFilteredFactories(searchResults);
    } else {
      setFilteredFactories(factoriesList);
    }
  }, []);

  // commenting this code. This was for the old table.
  // useEffect(() => {
  //   if (factoryCache) {
  //     setFilteredFactories(factoryCache);
  //   } else {
  //     const limit = 8;
  //     const offset = (page - 1) * limit;

  //     dispatch(getListOfAllFactories({ page, limit, offset, organisationId }));
  //   }
  // }, [page, factoryCache]);

  useEffect(() => {
    if (organisationId) {
      dispatch(
        getListOfAllFactories({ page: 1, limit: 8, offset: 1, organisationId })
      );
    }
  }, [organisationId]); // Runs when organisationId changes

  const debouncedFetchFactories = useCallback(
    debounce((term) => {
      dispatch(getSearchResultOfFactories({ search: term }));
    }, 100), // Delay in milliseconds
    []
  );

  const location = useLocation();

  // Add this new state for column visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});

  // Add this state to track if we're in filtered view
  const [isFiltered, setIsFiltered] = useState(false);

  // Add this state for filter model
  const [filterModel, setFilterModel] = useState({ items: [] });

  // Modify the function to toggle between views
  const toggleBasicColumns = () => {
    if (isFiltered) {
      // Reset both column visibility and filters
      setColumnVisibilityModel({});
      setFilterModel({ 
        items: [],
        logicOperator: 'and'
      });
      setIsFiltered(false);
    } else {
      // Hide all columns first
      const hideAllColumns = columns.reduce((acc, column) => {
        acc[column.field] = false;
        return acc;
      }, {});

      // Set visible columns
      setColumnVisibilityModel({
        ...hideAllColumns,
        factoryCountry: true,
        associatedSupplier: true,
        factoryName: true,
        factoryAddress: true,
        factoryCity: true,
        supplierType: true,
        deMinimisCheck: true,
        deMinimisYear: true,
        productCategories: true,
        workerCountTotal: true,
        unionPresent: true,
        cbaPresent: true,
        osId: true,
        lastAssessmentDate: true,
      });

      // Set compliance status filter - modified to match the working example
      setFilterModel({
        items: [
          {
            field: 'tier',
            operator: 'doesNotContain',
            value: 'Agent'
          },
          {
            field: 'statusOfCompliance',
            operator: 'isAnyOf',
            value: ['AUTHORIZED', 'PROBATIONARY PERIOD', 'AUTHORIZED CONTINGENT']
          }
        ],
        logicOperator: 'and'
      });

      setIsFiltered(true);
    }
  };

  return (
    <Box sx={{ height: "calc(100vh - 250px)", width: "100%", padding: "24px 80px" }}>
      <Button 
        variant={isFiltered ? "contained" : "outlined"}
        color="primary"
        onClick={toggleBasicColumns}
        sx={{ mb: 2 }}
      >
       Filter for FLA facility list reporting
      </Button>

      <DataGridPro
        {...dataGridFormat}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        filterModel={filterModel}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              logicOperator: 'and'
            },
          },
        }}
        checkboxSelection
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        getRowHeight={() => "auto"}
        sx={{
          borderRadius: "8px",
          padding: "16px",
          "& .MuiDataGrid-cell": {
            padding: "8px",
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f0f0f0", // Light gray background
            color: "#333", // Dark text color
            fontWeight: "bold",
          },
          "& .MuiDataGrid-columnHeader": {
            padding: "16px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      />
    </Box>
  );
};

export default FacilityMetaDataGrid;
