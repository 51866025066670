import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, Button, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSupplierData } from "../../selectors/supplier.selector";
import SupplierInformationEditModal from "./EditModals/editSupplierInformation.component";
import EditIcon from "@mui/icons-material/Edit";
import { selectEditModeState } from "../../selectors/misc.selector";
import SupplierInformationDrawer from "./supplierInformationDrawer.component";
import { ComponentStyles, SupplierStyles } from "../../styles";
import SupplierTierMapDrawer from "./supplierTierMapDrawer.component";
import { Link } from "react-router-dom";

const SupplierInformation = () => {
  const supplierData = useSelector(selectSupplierData);
  
  const [isEditing, setIsEditing] = useState(false);
  const editMode = useSelector(selectEditModeState);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTierMapDrawerOpen, setIsTierMapDrawerOpen] = useState(false);

  console.log(supplierData);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const toggleTierMapDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsTierMapDrawerOpen(open);
  };

  const copyToClipboard = () => {
    const orgID = supplierData?.organisationId;
    const supplierID = supplierData?._id;
    navigator.clipboard.writeText("https://q-auditsense-ref-supplier-1086719075712.us-central1.run.app/" + orgID + "/" + supplierID)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const formatAddress = (metadata) => {
    if (!metadata) return "";
    const addressParts = [];
    // Add street line 1 if it exists
    if (metadata.streetLine1 || metadata.addressLine1) {
      addressParts.push(metadata.streetLine1 || metadata.addressLine1);
    }
    // Add street line 2 if it exists
    if (metadata.streetLine2 || metadata.addressLine2) {
      addressParts.push(metadata.streetLine2 || metadata.addressLine2);
    }
    // Create city-state-zip group with proper commas
    const cityStateGroup = [];
    if (metadata.city) {
      cityStateGroup.push(metadata.city);
    }
    if (metadata.state) {
      cityStateGroup.push(metadata.state);
    }
    
    // Add the city-state group if not empty
    if (cityStateGroup.length > 0) {
      addressParts.push(cityStateGroup.join(", "));
    }
    // Join all parts with commas and return
    return addressParts.join(", ");
  };

  return (
    <Card style={SupplierStyles.supplierInformationCard}>
      <SupplierInformationEditModal
        open={isEditing}
        onClose={() => setIsEditing(false)}
        supplierData={supplierData}
      />

      {supplierData && (
        <Box sx={{ p: 3, backgroundColor: "#FFF", borderRadius: "12px" }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
            <Typography 
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#111111"
                }}
              >
              General Supplier Information
              {editMode && (
                <EditIcon
                  fontSize={"2"}
                  sx={ComponentStyles.editButton}
                  onClick={toggleEdit}
                />
              )}
            </Typography>
            
            <Box>
              <Button
                variant="outlined"
                onClick={toggleTierMapDrawer(true)}
                sx={{
                  color: "#6172F3",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#5667e2",
                  },
                  textTransform: "none",
                  height: "40px",
                  borderRadius: "8px",
                  mr: 1
                }}
              >
                Tier Map
              </Button>
              
            </Box>
          </Box>

          {/* First row: Number of Facilities, Tier, Supplier ID */}
          <Grid container spacing={10} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Number of Facilities</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {supplierData?.factoryData?.totalFactories}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Tier</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {Array.isArray(supplierData?.metadata?.tier) 
                  ? supplierData?.metadata?.tier.join(", ")
                  : supplierData?.metadata?.tier || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Country</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {supplierData?.metadata?.country || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Company Address</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {formatAddress(supplierData?.metadata) || "-"}
              </Typography>
            </Grid>
          </Grid>

          {/* Third row: Contact information */}
          <Grid container spacing={10} sx={{ mb: 3 }}>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Primary Contact Name</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {supplierData?.metadata?.primarySupplierContactPerson?.name || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Position</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {supplierData?.metadata?.primarySupplierContactPerson?.position || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Phone</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {supplierData?.metadata?.primarySupplierContactPerson?.phone || "-"}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={ComponentStyles.fieldName}>Email</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {supplierData?.metadata?.primarySupplierContactPerson?.email || "-"}
              </Typography>
            </Grid>
          </Grid>

          {/* Third row: Supply Chain Contact (only if exists) */}
            <Grid container spacing={10} sx={{ mb: 3 }}>
              <Grid item xs={6}>
                <Typography sx={ComponentStyles.fieldName}>Supply Chain Contact</Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {supplierData?.metadata?.supplyChainContact || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={ComponentStyles.fieldName}>Supply Chain Team</Typography>
                <Typography sx={ComponentStyles.fieldValue}>
                  {supplierData?.metadata?.supplyChainTeam || "-"}
                </Typography>
              </Grid>
            </Grid>
          
          {/* Fourth row: Associated Facilities */}
          <Grid container spacing={10}>
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Tier 1 Facilities</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {supplierData?.factoryData?.tier1Factories && 
                  supplierData.factoryData.tier1Factories.length > 0 ? (
                    supplierData.factoryData.tier1Factories.map((factory) => (
                      <Link 
                        key={factory.factoryId} 
                        to={`/suppliers/${supplierData?._id}/factory/${factory.factoryId}`} 
                        style={{ 
                          color: "#6172F3", 
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center", 
                          marginBottom: "8px"
                        }}
                      >
                        {factory.name}
                        <img style={{ paddingLeft: 8 }} src="/ne_arrow.svg" alt="arrow icon" />
                      </Link>
                    ))
                  ) : (
                    <Box>No Tier 1 facilities found</Box>
                  )}
                </Box>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Tier 2 Facilities</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  {supplierData?.factoryData?.tier2Factories && 
                  supplierData.factoryData.tier2Factories.length > 0 ? (
                    supplierData.factoryData.tier2Factories.map((factory) => (
                      <Link 
                        key={factory.factoryId} 
                        to={`/suppliers/${supplierData?._id}/factory/${factory.factoryId}`} 
                        style={{ 
                          color: "#6172F3", 
                          textDecoration: "none",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px" 
                        }}
                      >
                        {factory.name}
                        <img style={{ paddingLeft: 8 }} src="/ne_arrow.svg" alt="arrow icon" />
                      </Link>
                    ))
                  ) : (
                    <Box>No Tier 2 facilities found</Box>
                  )}
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      
      <SupplierInformationDrawer
        supplierData={supplierData}
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />
      <SupplierTierMapDrawer
        supplierData={supplierData}
        isDrawerOpen={isTierMapDrawerOpen}
        toggleDrawer={toggleTierMapDrawer}
      />
    </Card>
  );
};

export default SupplierInformation;