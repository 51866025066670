import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  Divider,
  CardContent,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { selectFactoryData } from "../../selectors/factory.selector";
import { selectAuditData } from "../../selectors/audit.selector";
import { selectIsAdmin } from "../../selectors/login.selector";
import { getFactoryById } from "../../reducers/factory.reducer";
import { useParams } from "react-router-dom";
import moment from "moment";
import AuditInformationEditModal from "./EditModals/editAuditInformation.component";
import { selectEditModeState } from "../../selectors/misc.selector";
import EditIcon from "@mui/icons-material/Edit";
import { AuditStyles, ComponentStyles } from "../../styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const AuditInformation = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const selectedAuditData = useSelector(selectAuditData);
  const selectedFactoryData = useSelector(selectFactoryData);
  const [factoryData, setFactoryData] = useState(selectedFactoryData);
  const [isEditingInternal, setIsEditingInternal] = useState(false);

  const editMode = useSelector(selectEditModeState);
  const adminUser = useSelector(selectIsAdmin);

  const getDisplayValue = (value) => {
    if (selectedAuditData?.state === "test" && !adminUser) {
      return "-";
    }
    return value || "-";
  };

  useEffect(() => {
    if (
      selectedAuditData &&
      ((selectedFactoryData?._id &&
        selectedAuditData?.factoryId !== selectedFactoryData?._id) ||
        !selectedFactoryData)
    ) {
      dispatch(
        getFactoryById({
          supplierId: params.supplierId,
          factoryId: selectedAuditData.factoryId,
        })
      );
    }
    setFactoryData(selectedFactoryData);
  }, [selectedFactoryData, selectedAuditData]);

  const toggleEditInternal = () => {
    setIsEditingInternal(!isEditingInternal);
  };

  const auditRating = selectedAuditData?.isHistoricalAudit
    ? selectedAuditData?.auditRatingClean || selectedAuditData?.auditRating || "-"
    : selectedAuditData?.risk?.auditRating || "-";
  
  const vendorRating = selectedAuditData?.risk?.auditRatingAfterCAP || selectedAuditData?.risk?.auditRating || "-";
  // Determine if the current audit is the latest
  const isLatestAudit = 
  selectedAuditData?.metadata?.auditEndDate === 
    selectedFactoryData?.metadata?.last4Audits?.last4AuditEndDates?.[
      (selectedFactoryData?.metadata?.last4Audits?.last4AuditEndDates?.length || 0) - 1
    ] && 
  !selectedAuditData?.isHistoricalAudit;
  return (
    factoryData &&
    (!editMode ? (
      <Box sx={AuditStyles.containerBox}>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={AuditStyles.containerGrid}
        >
          <Grid item>
            <Typography
              sx={{
                ...AuditStyles.headingsNameBold700,
                display: "inline-block",
              }}
            >
              Audit Information
            </Typography>
          </Grid>
          {editMode && (
            <EditIcon
              fontSize={"2"}
              sx={ComponentStyles.editButton}
              onClick={toggleEditInternal}
            />
          )}
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Card variant="outlined" style={AuditStyles.editModeCard}>
              <CardContent
                style={{
                  padding: "4px",
                  paddingLeft: "32px",
                  paddingRight: vendorRating
                    ? "18px"
                    : "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography 
                style={
                  AuditStyles.headingsNameBold700
                }>
                  Vendor Score
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "16px 16px" }}
                />
                <Typography style={AuditStyles.headingsNameBold700}>
                  <Tooltip title={`Numerical Score: ${selectedAuditData?.risk?.auditScoreAfterCAP || selectedAuditData?.risk?.auditScore || "-"}`}>
                    <span>{getDisplayValue(vendorRating)}</span>
                  </Tooltip>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card variant="outlined" style={AuditStyles.editModeCard}>
              <CardContent
                style={{
                  padding: "4px",
                  paddingLeft: "32px",
                  paddingRight: selectedAuditData?.metadata?.originalAuditScore
                    ? "18px"
                    : "32px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography style={AuditStyles.headingsNameBold700}>
                  Reformation Audit Score
                </Typography>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: "16px 16px" }}
                />
                <Typography style={AuditStyles.headingsNameBold700}>
                  <Tooltip title={`Numerical Score: ${selectedAuditData?.risk?.auditScore ?? "-"}`}>
                    <span>{getDisplayValue(auditRating)}</span>
                  </Tooltip>
                </Typography>
              </CardContent>
            </Card>
          </Grid> 
        </Grid>

        {/* Company Address */}
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Scheme</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(selectedAuditData?.metadata?.auditType)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Start Date</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(
                selectedAuditData?.metadata?.auditStartDate
                  ? moment(selectedAuditData?.metadata?.auditStartDate).format(
                      "MMM. DD, YYYY"
                    )
                  : "-"
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>End Date</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(
                selectedAuditData?.metadata?.auditEndDate
                  ? `${moment(selectedAuditData?.metadata?.auditEndDate).format(
                      "MMM. DD, YYYY"
                    )}`
                  : "-"
              )}
            </Typography>
          </Grid>
        </Grid>
        {/* Company information */}
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Type</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(
                selectedAuditData?.metadata?.auditTypeFrequency ||
                  selectedAuditData?.metadata?.auditRange
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Audit Funder</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(
                selectedAuditData?.metadata?.auditFunder ||
                  selectedAuditData?.metadata?.reportOwnerAndPayee
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Auditor Firm</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(selectedAuditData?.metadata?.auditFirm)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>Auditor Name</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(
                selectedAuditData?.auditor ||
                  selectedAuditData?.metadata?.auditorsPrimary
              )}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>MRA</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(selectedAuditData?.metadata?.mra)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>IRSS</Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(selectedAuditData?.metadata?.irss?.irssCheck)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              Language (Management)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(selectedAuditData?.metadata?.primaryLanguage)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              Language (Workers)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(selectedAuditData?.metadata?.workersLanguage)}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography sx={AuditStyles.fieldName}>
              Original Audit Score
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(selectedAuditData?.metadata?.originalAuditScore)}
            </Typography>
          </Grid>
          {isLatestAudit && (
            <>
              <Grid item xs={4}>
                <Typography sx={AuditStyles.fieldName}>
                  Last Audit Scheme
                </Typography>
                <Typography sx={AuditStyles.fieldValue}>
                  {getDisplayValue(
                    selectedFactoryData?.metadata?.last4Audits?.last4AuditTypes
                      ?.length > 1
                      ? selectedFactoryData?.metadata?.last4Audits
                          ?.last4AuditTypes?.[
                          (selectedFactoryData?.metadata?.last4Audits
                            ?.last4AuditTypes?.length || 0) - 2
                        ]
                      : "-"
                  )}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography sx={AuditStyles.fieldName}>
                  Last Audit Date
                </Typography>
                <Typography sx={AuditStyles.fieldValue}>
                  {getDisplayValue(
                    selectedFactoryData?.metadata?.last4Audits
                      ?.last4AuditEndDates?.length > 1
                      ? selectedFactoryData?.metadata?.last4Audits
                          ?.last4AuditEndDates?.[
                          (selectedFactoryData?.metadata?.last4Audits
                            ?.last4AuditEndDates?.length || 0) - 2
                        ]
                      : "-"
                  )}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid container spacing={2} sx={AuditStyles.containerGrid}>
          <Grid item xs={12}>
            <Typography sx={AuditStyles.fieldName}>
              CAP Start Date (Used for Past Due Calculation)
            </Typography>
            <Typography sx={AuditStyles.fieldValue}>
              {getDisplayValue(
                selectedAuditData?.metadata?.capStartDate
                  ? moment(selectedAuditData?.metadata?.capStartDate).format(
                      "MMM. DD, YYYY"
                    )
                  : `${moment(selectedAuditData?.metadata?.auditEndDate).format(
                      "MMM. DD, YYYY"
                    )} (Default as Audit Date)`
              )}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <AuditInformationEditModal
        open={isEditingInternal}
        onClose={() => setIsEditingInternal(false)}
        factoryData={selectedFactoryData}
        auditData={selectedAuditData}
      />
    ))
  );
};

export default AuditInformation;