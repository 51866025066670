import React, { useEffect, useState, useRef } from "react";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import {
  Box,
  Typography,
  Chip,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Skeleton,
} from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDueActionIRSSData } from "../../reducers/audit.reducer";
import { selectDueActionIRSSData } from "../../selectors/audit.selector";

const DueIRSS = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getDueActionIRSSData());
    }, []);

    const dueActionIRSSData = useSelector(selectDueActionIRSSData).audits;
    console.log("dueActionIRSSData", dueActionIRSSData);

    useEffect(() => {
        const filtered = rows.filter((row) => row.factoryName !== "Test");
        setFilteredRows(filtered);
      }, [dueActionIRSSData]);

    const rows = Array.isArray(dueActionIRSSData) ? dueActionIRSSData.map((audit) => ({
        id: audit.auditId,
        supplierId: audit.supplierId,
        factoryId: audit.factoryId,
        factoryName: audit.factoryName,
        supplierName: audit.supplierName,
        facilityCountry: audit.facilityCountry,
        irssStatus: audit.irssStatus,
        lastAuditTypeFrequency: audit.lastAuditTypeFrequency,
        auditDate: audit.auditDate,
        auditScheme: audit.auditScheme,
        auditTypeFrequency: audit.auditTypeFrequency,
        auditRating: audit.auditRating,
        auditRatingAfterCAP: audit.auditRatingAfterCAP,
        postIRSSAuditRating: audit.postIRSSAuditRating,
        irssEndDate: audit.irssEndDate,
        irssStartDate: audit.irssStartDate,
        programEndsInDays: audit.programEndsInDays,
        supplyChainContact: audit.supplyChainContact,
        irssNotes: audit.irssNotes,
        dueStatus:
            audit.programEndsInDays > 0
            ? `in ${audit?.programEndsInDays?.toString().replace("-", "")} Days`
            : `Past ${audit.programEndsInDays?.toString().replace("-", "")} Days`,
    })) : [];

    const [selectedFilters, setSelectedFilters] = useState([]);
    const [filteredRows, setFilteredRows] = useState(rows);

    const handleFilterToggle = (filterType) => {
        const newFilters = selectedFilters.includes(filterType)
          ? selectedFilters.filter((f) => f !== filterType)
          : [...selectedFilters, filterType];
    
        setSelectedFilters(newFilters);
    
        // Apply filters
        const filtered = rows?.filter((row) => {
          if (newFilters.length === 0) return true;
    
          // Check if any of the three expiry types matches the filter criteria
          const daysValues = [
            row.programEndsInDays,
          ].filter((days) => days !== undefined && days !== null);
    
          // If no valid days values exist, return false
          if (daysValues.length === 0) return false;
    
          return newFilters.some((filter) => {
            // Check if ANY of the days values match the filter criteria
            return daysValues.some((days) => {
              switch (filter) {
                case "pastDue":
                  return days <= 0;
                case "due1to20":
                  return days > 0 && days < 20;
                case "due20to60":
                  return days >= 20 && days <= 60;
                case "due60plus":
                  return days > 60;
                default:
                  return true;
              }
            });
          });
        });
    
        setFilteredRows(filtered);
    };
  
    const columns = [
        {
            field: "factoryName",
            headerName: "Facility Name",
            width: 150,
            headerAlign: "center",
            filterable: true,
            renderCell: (params) => (
                <Typography
                sx={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    padding: "8px 0px 8px 8px",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                }}
                >
                {params.value}
                </Typography>
            ),
        },
        {
            field: "supplierName",
            headerName: "Supplier Name",
            width: 150,
            headerAlign: "center",
            filterable: true,
            renderCell: (params) => (
            <Typography
                sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                padding: "8px 0px 8px 8px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                height: "100%",
                }}
            >
                {params.value}
            </Typography>
            ),
        },
        {
            field: "facilityCountry",
            headerName: "Facility Country",
            width: 120,
            headerAlign: "center",
            filterable: true,
            renderCell: (params) => (
            <Typography
                sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                padding: "8px 0px 8px 8px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                height: "100%",
                }}
            >
                {params.value}
            </Typography>
            ),
        },
        {
            field: "irssStatus",
            headerName: "IRSS Status",
            width: 120,
            headerAlign: "center",
            filterable: true,
            renderCell: (params) => (
            <Typography
                sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                padding: "8px 0px 8px 8px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                height: "100%",
                }}
            >
                {params.value}
            </Typography>
            ),
        },
        {
            field: "irssStartDate",
            headerName: "IRSS Program Start Date",
            width: 120,
            align: "center",
            headerAlign: "center",
            type: "date",
            valueGetter: (value) => {
                return value ? new Date(value) : null;
            },
              valueFormatter: (value) => {
                return value ? moment(value).format("MMM. DD, YYYY") : "";
            },
        },
        {
            field: "irssEndDate",
            headerName: "IRSS Program End Date",
            width: 120,
            align: "center",
            headerAlign: "center",
            type: "date",
            valueGetter: (value) => {
                return value ? new Date(value) : null;
            },
              valueFormatter: (value) => {
                return value ? moment(value).format("MMM. DD, YYYY") : "";
            },
        },
        {
        field: "dueStatus",
        headerName: "IRSS Due Status",
        width: 130,
        align: "center",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => (
            <Chip
            label={params.row.dueStatus}
            sx={{
                borderRadius: "8px",
                backgroundColor: params.row.dueStatus.includes("Past")
                ? "#DC6803"
                : "#EAECF0",
                color: params.row.dueStatus.includes("Past")
                ? "#FFF"
                : "#172B4D",
                fontWeight: "500",
            }}
            />
        ),
        },
        {
          field: "irssNotes",
          headerName: "IRSS Notes",
          width: 200,
          headerAlign: "center",
          filterable: true,
          renderCell: (params) => (
          <Typography
              sx={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              padding: "8px 0px 8px 8px",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              height: "100%",
              }}
          >
               {params.value ? `${params.value.substring(0, 70)}...` : ""}
          </Typography>
          ),
      },
      {
          field: "details",
          headerName: "",
          width: 150,
          sortable: false,
          renderCell: (params) => (
            <Button
              variant="contained"
              onClick={() => {
                // console.log("Navigating to audit with:", {
                //   supplierId: params.row.supplierId,
                //   factoryId: params.row.factoryId,
                //   auditId: params.row.id
                // });
                //window.history.pushState(null, "", "/dueAction");
    
                navigate(
                  `/suppliers/${params.row.supplierId}/factory/${params.row.factoryId}/audit/${params.row.id}`,
                  { replace: true }
                );
              }}
              sx={{
                backgroundColor: "#4B68D4",
                color: "white",
                "&:hover": {
                  backgroundColor: "#3A54B6",
                },
                textTransform: "none",
                width: "79px",
                height: "40px",
                ml: 4,
    
                borderRadius: "8px",
              }}
              size="small"
            >
              To Audit
            </Button>
          ),
        },
        {
            field: "lastAuditTypeFrequency",
            headerName: "Last Audit Type",
            width: 120,
            headerAlign: "center",
            filterable: true,
            renderCell: (params) => (
            <Typography
                sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                padding: "8px 0px 8px 8px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                height: "100%",
                }}
            >
                {params.value}
            </Typography>
            ),
        },
        {
            field: "auditDate",
            headerName: "Audit Date",
            width: 120,
            align: "center",
            headerAlign: "center",
            type: "date",
            valueGetter: (value) => {
                return value ? new Date(value) : null;
            },
              valueFormatter: (value) => {
                return value ? moment(value).format("MMM. DD, YYYY") : "";
            },
        },
        {
            field: "auditScheme",
            headerName: "Audit Scheme",
            width: 120,
            headerAlign: "center",
            filterable: true,
            renderCell: (params) => (
            <Typography
                sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                padding: "8px 0px 8px 8px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                height: "100%",
                }}
            >
                {params.value}
            </Typography>
            ),
        },
        {
            field: "auditTypeFrequency",
            headerName: "Audit Type",
            width: 120,
            headerAlign: "center",
            filterable: true,
            renderCell: (params) => (
            <Typography
                sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                padding: "8px 0px 8px 8px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                height: "100%",
                }}
            >
                {params.value}
            </Typography>
            ),
        },
        {
            field: "auditRating",
            headerName: "Audit Rating",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const rating = params.value?.toLowerCase();
                let backgroundColor;
                let color;
                if (rating.includes("green")) {
                  backgroundColor = "#E6F4EA"; // Nicer green background
                  color = "#137333"; // Nicer green text
                } else if (rating.includes("yellow")) {
                  backgroundColor = "#FEF3C7";
                  color = "#B45309";
                } else if (rating.includes("orange")) {
                  backgroundColor = "#FFEDD5";
                  color = "#9A3412";
                } else if (rating.includes("red")) {
                  backgroundColor = "#FEE2E2";
                  color = "#B91C1C";
                } else {
                  backgroundColor = "#F0F0F0";
                  color = "#000";
                }
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Chip
                  label={params.value}
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: backgroundColor,
                    color: color,
                    fontWeight: "500",
                  }}
                />
                  </Box>
                );
              },
        },
        {
            field: "postIRSSAuditRating",
            headerName: "Post IRSS Audit Rating",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const rating = params.value?.toLowerCase();
                let backgroundColor;
                let color;
                if (rating.includes("green")) {
                  backgroundColor = "#E6F4EA"; // Nicer green background
                  color = "#137333"; // Nicer green text
                } else if (rating.includes("yellow")) {
                  backgroundColor = "#FEF3C7";
                  color = "#B45309";
                } else if (rating.includes("orange")) {
                  backgroundColor = "#FFEDD5";
                  color = "#9A3412";
                } else if (rating.includes("red")) {
                  backgroundColor = "#FEE2E2";
                  color = "#B91C1C";
                } else {
                  backgroundColor = "#F0F0F0";
                  color = "#000";
                }
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <Chip
                  label={params.value}
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: backgroundColor,
                    color: color,
                    fontWeight: "500",
                  }}
                />
                  </Box>
                );
              },
        },
        {
            field: "supplyChainContact",
            headerName: "Supply Chain Contact",
            width: 120,
            headerAlign: "center",
            filterable: true,
            renderCell: (params) => (
            <Typography
                sx={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                padding: "8px 0px 8px 8px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                height: "100%",
                }}
            >
                {params.value}
            </Typography>
            ),
        },
    ];

    
  return (
    <Box sx={{ px: 3 }}>
      <Box sx={{ width: "100%", padding: "0px 48px", mt: 1 }}>
        <Typography
          style={{
            fontSize: 30,
            fontWeight: 700,
            marginBottom: "30px",
            marginTop: "30px",
          }}
        >
          IRSS Due Action
        </Typography>
        {!rows ? (
          <Box sx={{ width: "100%" }}>
            {[...Array(10)].map((_, index) => (
              <Skeleton
                key={index}
                height={60}
                animation="wave"
                sx={{ my: 1 }}
              />
            ))}
          </Box>
        ) : (
          <>
            <Box sx={{ mb: 2, display: "flex" }}>
              <Button
                variant={
                  selectedFilters.includes("pastDue") ? "contained" : "outlined"
                }
                onClick={() => handleFilterToggle("pastDue")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Past Due
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due1to20")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due1to20")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 1-20 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due20to60")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due20to60")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 20-60 Days
              </Button>
              <Button
                variant={
                  selectedFilters.includes("due60plus")
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handleFilterToggle("due60plus")}
                sx={{
                  // borderRadius: "20px",
                  textTransform: "none",
                  "&.MuiButton-contained": {
                    backgroundColor: "#4B68D4",
                  },
                }}
              >
                Due in 60+ Days
              </Button>
            </Box>
            <div style={{ height: "calc(100vh - 350px)", width: "100%" }}>
              <DataGridPro
                columns={columns}
                rows={filteredRows || []}
                disableColumnSelector
                disableDensitySelector
                pageSize={5}
                rowsPerPageOptions={[5, 10, 15]}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                  },
                }}
                getRowHeight={() => 90}
                sx={{
                  borderRadius: "8px",

                  "& .MuiDataGrid-main": {
                    // Target the main container
                    overflow: "hidden",
                    "& .MuiDataGrid-virtualScroller": {
                      // Target the virtual scroller
                      height: "100% !important",
                      "& .MuiDataGrid-virtualScrollerContent": {
                        // Target the virtual scroller content
                        height: "auto !important",
                        "& .MuiDataGrid-virtualScrollerRenderZone": {
                          // Target the render zone
                          "& .MuiDataGrid-row": {
                            // Target each row
                            maxHeight: "none !important",
                            "& .MuiDataGrid-cell": {
                              // Target each cell
                              alignItems: "center",
                            },
                          },
                        },
                      },
                    },
                  },

                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#f0f0f0",
                    fontWeight: "500",
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    whiteSpace: 'normal',
                    lineHeight: '1.2',
                    textAlign: 'center',
                  },
                }}
              />
            </div>
          </>
        )}
      </Box>
    </Box>
  );
};

export default DueIRSS;