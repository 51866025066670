import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../reducers/login.reducer";
import { selectUserInfo } from "../selectors/login.selector";
import { setLeftSideNavigationSelected } from "../reducers/navigation.reducer";
import { selectLeftSideNavigationSelected } from "../selectors/navigation.selector";
import { ComponentStyles } from "../styles";
import LatestUpdateModal from "./latestUpdateModal.component";

const LeftSideNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const userInfo = useSelector(selectUserInfo);
  const selected = useSelector(selectLeftSideNavigationSelected);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const handleNavigate = (path) => {
    dispatch(setLeftSideNavigationSelected(path));
    navigate(path);
  };
  useEffect(() => {
    if (params["*"] !== "suppliers") {
      dispatch(setLeftSideNavigationSelected(`/${params["*"]}`));
    }
  }, [params]);

  const [UpdateModalOpen, setUpdateModalOpen] = React.useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const handleModalOpen = () => {
    setUpdateModalOpen(true);
  };

  const handleModalClose = () => {
    setUpdateModalOpen(false);
  };

  const sidebarWidth = {
    expanded: 210,
    collapsed: 72,
  };

  const isItemVisible = (itemType) => {
    const role = userInfo?.role || 'standard';
    
    if (role === 'reporting') {
      return ['suppliers', 'facilities', 'insights'].includes(itemType);
    }

    if (role === 'sourcing') {
      return ['suppliers', 'facilities', 'questionnaire'].includes(itemType);
    }
    
    return true;
  };

  return (
    <Box
      onMouseLeave={() => setIsExpanded(false)}
      onMouseEnter={() => setIsExpanded(true)}
      sx={{
        width: isExpanded ? sidebarWidth.expanded : sidebarWidth.collapsed,
        minWidth: isExpanded ? sidebarWidth.expanded : sidebarWidth.collapsed,
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        padding: 2,
        borderRight: "1px solid #cccccc4a",
        borderBottom: "none",
        borderLeft: "none",
        borderTop: "none",
        backgroundColor: "white",
        transition: "all 0.3s ease-in-out",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          ...ComponentStyles.logoBox,
          justifyContent: "flex-start",
          paddingLeft: 1,
        }}
      >
        <img
          src="/Elm_Logo.svg"
          alt="Logo"
          style={{
            ...ComponentStyles.logoImage,
            width: "30px",
          }}
        />
      </Box>

      <List sx={{ width: "100%" }}>
        {isItemVisible("suppliers") && (
          <ListItemButton
            onClick={() => handleNavigate(`/suppliers`)}
            selected={selected === "/suppliers"}
            sx={{
              bgcolor:
                selected === "/suppliers" ? "action.selected" : "inherit",
              borderRadius: "12px",
              marginY: 0.5,
              justifyContent: "flex-start",
              minHeight: 48,
              px: isExpanded ? 2 : 1,
            }}
          >
            <ListItemIcon
              style={{
                minWidth: isExpanded ? 40 : 32,
                marginRight: isExpanded ? 0 : 0,
              }}
            >
              <img
                src="/suppliers.svg"
                alt="Suppliers"
                style={ComponentStyles.navbarListItemIcon}
              />
            </ListItemIcon>
            {isExpanded && (
              <ListItemText
                primary="Suppliers"
                primaryTypographyProps={{
                  sx: {
                    ...ComponentStyles.navbarListItemText,
                    marginLeft: 1,
                  },
                }}
              />
            )}
          </ListItemButton>
        )}

        {isItemVisible("facilities") && (
          <ListItemButton
            onClick={() => handleNavigate(`/factories`)}
            selected={selected === "/factories"}
            sx={{
              bgcolor:
                selected === "/factories" ? "action.selected" : "inherit",
              borderRadius: "12px",
              justifyContent: "flex-start",
              minHeight: 48,
              px: isExpanded ? 2 : 1,
            }}
          >
            <ListItemIcon
              style={{
                minWidth: isExpanded ? 40 : 32,
                marginRight: isExpanded ? "auto" : 0,
              }}
            >
              <img
                src="/factories.svg"
                alt="Factories"
                style={ComponentStyles.navbarListItemIcon}
              />
            </ListItemIcon>
            {isExpanded && (
              <ListItemText
                primary="Facilities"
                primaryTypographyProps={{
                  sx: ComponentStyles.navbarListItemText,
                }}
              />
            )}
          </ListItemButton>
        )}

        {isItemVisible("insights") && (
          <ListItemButton
            onClick={() => handleNavigate(`/insights`)}
            selected={selected === "/insights"}
            sx={{
              bgcolor: selected === "/insights" ? "action.selected" : "inherit",
              borderRadius: "12px",
              marginY: 0.5,
              justifyContent: "flex-start",
              minHeight: 48,
              px: isExpanded ? 2 : 1,
            }}
          >
            <ListItemIcon
              style={{
                minWidth: isExpanded ? 40 : 32,
                marginRight: isExpanded ? "auto" : 0,
              }}
            >
              <img
                src="/insights.svg"
                alt="Insights"
                style={ComponentStyles.navbarListItemIcon}
              />
            </ListItemIcon>
            {isExpanded && (
              <ListItemText
                primaryTypographyProps={{
                  sx: ComponentStyles.navbarListItemText,
                }}
                primary="Insights"
              />
            )}
          </ListItemButton>
        )}
        {isItemVisible("dueAction") && (
          <ListItemButton
            onClick={() => handleNavigate(`/dueAction`)}
            selected={selected === "/dueAction"}
            sx={{
              bgcolor:
                selected === "/dueAction" ? "action.selected" : "inherit",
              borderRadius: "12px",
              marginY: 0.5,
              justifyContent: "flex-start",
              minHeight: 48,
              px: isExpanded ? 2 : 1,
            }}
          >
            <ListItemIcon
              style={{
                minWidth: isExpanded ? 40 : 32,
                marginRight: isExpanded ? "auto" : 0,
              }}
            >
              <img
                src="/dueAction.svg"
                alt="Due Actions"
                style={ComponentStyles.navbarListItemIcon}
              />
            </ListItemIcon>
            {isExpanded && (
              <ListItemText
                primary="Due Actions"
                primaryTypographyProps={{
                  sx: ComponentStyles.navbarListItemText,
                }}
              />
            )}
          </ListItemButton>
        )}
        {isItemVisible("questionnaire") && (
          <ListItemButton
            onClick={() => handleNavigate(`/questionnaire`)}
            selected={selected === "/questionnaire"}
            sx={{
              bgcolor:
                selected === "/questionnaire" ? "action.selected" : "inherit",
              borderRadius: "12px",
              marginY: 0.5,
              justifyContent: "flex-start",
              minHeight: 48,
              px: isExpanded ? 2 : 1,
            }}
          >
            <ListItemIcon
              style={{
                minWidth: isExpanded ? 40 : 32,
                marginRight: isExpanded ? "auto" : 0,
              }}
            >
              <img
                src="/saq.svg"
                alt="SAQ"
                style={ComponentStyles.navbarListItemIcon}
              />
            </ListItemIcon>
            {isExpanded && (
              <ListItemText
                primary="Presourcing Form"
                primaryTypographyProps={{
                  sx: ComponentStyles.navbarListItemText,
                }}
              />
            )}
          </ListItemButton>
        )}
      </List>

      <Box sx={{ flexGrow: 1 }} />

      <Divider sx={{ width: "100%", marginBottom: 2 }} />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: isExpanded ? "space-between" : "center",
          px: "10px",
          py: 2,
        }}
      >
        {isExpanded && (
          <Box>
            <Typography variant="body2" style={{ fontWeight: 600 }}>
              {userInfo.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {/* {userInfo.email} */}
            </Typography>
          </Box>
        )}
        <IconButton
          edge="end"
          aria-label="Logout"
          onClick={() => handleLogout()}
        >
          <img
            src="/logout.svg"
            alt="Logout"
            style={ComponentStyles.navbarListItemIcon}
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default LeftSideNavbar;
