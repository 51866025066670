import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  email: null,
  error: null,
  userInfo: {
    userId: null,
    firstName: null,
    lastName: null,
    token: null,
  },
  loggedIn: false,
  admin: null,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    submitLoginRequest: (state, action) => {
      state.email = action.payload.email;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.userInfo = {
        ...action.payload,
        userId: action.payload._id,
        role: action.payload.role || "standard",
      };
      state.email = action.payload.email || state.email;
      state.loggedIn = true;
      state.error = null;
      state.admin =
        action.payload.email.substring(
          action.payload.email.indexOf("@") + 1
        ) === "elm-ai.com" ||
        action.payload.email.substring(
          action.payload.email.indexOf("@") + 1
        ) === "esger.co";
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem("loginState"); // Clear local storage
      state.email = null;
      state.userInfo = {
        userId: null,
        firstName: null,
        lastName: null,
        token: null,
      };
      state.loggedIn = false;
    },
  },
});
export const { submitLoginRequest, loginSuccess, loginFailure, logout } =
  loginSlice.actions;

export default loginSlice.reducer;
