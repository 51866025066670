import { Country, State } from "country-state-city";
const countryOptions = Country.getAllCountries().map((country) => ({
  label: country.name,
  value: country.isoCode,
}));

export const languages = [
  "Afrikaans",
  "Albanian",
  "Arabic",
  "Armenian",
  "Basque",
  "Bengali",
  "Bulgarian",
  "Catalan",
  "Cambodian",
  "Chinese (Mandarin)",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English",
  "Estonian",
  "Fiji",
  "Finnish",
  "French",
  "Georgian",
  "German",
  "Greek",
  "Gujarati",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Korean",
  "Latin",
  "Latvian",
  "Lithuanian",
  "Macedonian",
  "Malay",
  "Malayalam",
  "Maltese",
  "Maori",
  "Marathi",
  "Mongolian",
  "Nepali",
  "Norwegian",
  "Persian",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Quechua",
  "Romanian",
  "Russian",
  "Samoan",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tamil",
  "Tatar",
  "Telugu",
  "Thai",
  "Tibetan",
  "Tonga",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Welsh",
  "Xhosa",
];

export const saqSections = [
  {
    name: "Facility Basic Information",
    fields: [
      {
        fieldLabel: "Supplier Name (English)",
        dbMapping: "metadata.supplierName",
        fieldType: "string",
      },
      {
        fieldLabel: "Facility Name (English)",
        dbMapping: "name",
        fieldType: "string",
      },
      {
        fieldLabel:
          "Facility Legal Name (Local Language, per Business License)",
        dbMapping: "metadata.facilityLegalName",
        fieldType: "string",
      },
      {
        fieldLabel: "Facility Street Line 1",
        dbMapping: "metadata.addressLine1",
        fieldType: "string",
      },
      {
        fieldLabel: "Facility Street Line 2",
        dbMapping: "metadata.addressLine2",
        fieldType: "string",
      },
      {
        fieldLabel: "Facility Country",
        dbMapping: "metadata.country",
        fieldType: "dropdown",
        dropdownOptions: countryOptions,
        isCountrySelect: true,
      },
      {
        fieldLabel: "State",
        dbMapping: "metadata.state",
        fieldType: "dropdown",
        dropdownOptions: [],
        dependsOn: "metadata.country",
      },
      { fieldLabel: "City", dbMapping: "metadata.city", fieldType: "string" },
    ],
  },
  {
    name: "Agent Information",
    fields: [
      {
        fieldLabel: "Agent Contact Name",
        dbMapping: "metadata.agentContact.name",
        fieldType: "string",
      },
      {
        fieldLabel: "Agent Email",
        dbMapping: "metadata.agentContact.email",
        fieldType: "string",
      },
      {
        fieldLabel: "Agent HQ Address",
        dbMapping: "metadata.agentContact.address",
        fieldType: "string",
      },
      {
        fieldLabel: "Agent Business Name",
        dbMapping: "metadata.agentContact.businessName",
        fieldType: "string",
      },
      {
        fieldLabel: "Agent HQ Street Line 1",
        dbMapping: "metadata.agentContact.streetLine1",
        fieldType: "string",
      },
      {
        fieldLabel: "Agent HQ Street Line 2",
        dbMapping: "metadata.agentContact.streetLine2",
        fieldType: "string",
      },
      {
        fieldLabel: "Agent HQ City",
        dbMapping: "metadata.agentContact.city",
        fieldType: "string",
      },
      {
        fieldLabel: "Agent HQ State",
        dbMapping: "metadata.agentContact.state",
        fieldType: "dropdown",
        dependsOn: "metadata.agentContact.country",
        dropdownOptions: []
      },
      {
        fieldLabel: "Agent HQ Country",
        dbMapping: "metadata.agentContact.country",
        fieldType: "dropdown",
        isCountrySelect: true,
        dropdownOptions: countryOptions
      },
      {
        fieldLabel: "Agent Phone Number",
        dbMapping: "metadata.agentContact.phone",
        fieldType: "string",
      },
      {
        fieldLabel: "Agent Spoken Languages",
        dbMapping: "metadata.agentContact.spokenLanguages",
        fieldType: "dropdown multi",
        dropdownOptions: languages,
      },
    ],
  },
  {
    name: "Facility Contact Information",
    fields: [
      {
        fieldLabel: "Primary Facility Contact Name",
        dbMapping: "metadata.primaryFacilityContactPerson.name",
        fieldType: "string",
      },
      {
        fieldLabel: "Primary Facility Contact Title",
        dbMapping: "metadata.primaryFacilityContactPerson.position",
        fieldType: "string",
      },
      {
        fieldLabel: "Primary Facility Contact Email",
        dbMapping: "metadata.primaryFacilityContactPerson.email",
        fieldType: "string",
      },
      {
        fieldLabel: "Primary Facility Contact Phone",
        dbMapping: "metadata.primaryFacilityContactPerson.phone",
        fieldType: "string",
      },
      {
        fieldLabel: "Primary Facility Contact Spoken Languages",
        dbMapping: "metadata.primaryFacilityContactPerson.spokenLanguages",
        fieldType: "dropdown multi",
        dropdownOptions: languages,
      },
      {
        fieldLabel: "Social Compliance Contact Name",
        dbMapping: "metadata.socialComplianceContactPerson.name",
        fieldType: "string",
      },
      {
        fieldLabel: "Social Compliance Email",
        dbMapping: "metadata.socialComplianceContactPerson.email",
        fieldType: "string",
      },
      {
        fieldLabel: "Social Compliance Phone Number",
        dbMapping: "metadata.socialComplianceContactPerson.phone",
        fieldType: "string",
      },
      {
        fieldLabel: "Social Compliance Spoken Languages",
        dbMapping: "metadata.socialComplianceContactPerson.spokenLanguages",
        fieldType: "dropdown multi",
        dropdownOptions: languages,
      },
      {
        fieldLabel: "Environmental Health & Safety (EHS) Compliance Contact",
        dbMapping: "metadata.ehsContactPerson.name",
        fieldType: "string",
      },
      {
        fieldLabel: "EHS Email",
        dbMapping: "metadata.ehsContactPerson.email",
        fieldType: "string",
      },
      {
        fieldLabel: "EHS Phone Number",
        dbMapping: "metadata.ehsContactPerson.phone",
        fieldType: "string",
      },
      {
        fieldLabel: "EHS Spoken Languages",
        dbMapping: "metadata.ehsContactPerson.spokenLanguages",
        fieldType: "dropdown multi",
        dropdownOptions: languages,
      },
      {
        fieldLabel: "Materials/Sustainability Contact",
        dbMapping: "metadata.materialsSustainabilityContactPerson.name",
        fieldType: "string",
      },
      {
        fieldLabel: "Materials/Sustainability Email",
        dbMapping: "metadata.materialsSustainabilityContactPerson.email",
        fieldType: "string",
      },
      {
        fieldLabel: "Materials/Sustainability Phone Number",
        dbMapping: "metadata.materialsSustainabilityContactPerson.phone",
        fieldType: "string",
      },
      {
        fieldLabel: "Materials/Sustainability Spoken Languages",
        dbMapping:
          "metadata.materialsSustainabilityContactPerson.spokenLanguages",
        fieldType: "dropdown multi",
        dropdownOptions: languages,
      },
    ],
  },
  {
    name: "Production Information",
    fields: [
      {
        fieldLabel: "Facility Tier",
        dbMapping: "tier",
        fieldType: "dropdown",
        dropdownOptions: ["Tier Agent", "Tier 1", "Tier 2", "Tier 3", "Tier 4"],
      },
      {
        fieldLabel:
          "Main Production processes at facility (i.e. cutting, sewing, finishing, etc.)",
        dbMapping: "metadata.processInfo.productionProcesses",
        fieldType: "dropdown multi",
        dropdownOptions: [
          "Agent",
          "Assembly",
          "bias",
          "bias,binding spaghetti",
          "binding spaghetti",
          "Boiling",
          "Bundling",
          "Button hole and keyhole",
          "Buttoning",
          "Candle Making",
          "Coating/Laminating",
          "Combing",
          "Scouring",
          "Compostable Packaging",
          "computer knitting",
          "Converter",
          "Cut & Sew",
          "Cutting",
          "Development",
          "Dispatch",
          "Distribution",
          "Downfill",
          "Dye House",
          "Dyeing",
          "Embroidery",
          "Expedition",
          "Farm",
          "Fiber Producer",
          "Fiber Trader",
          "Final Product Assembly",
          "Finishing",
          "Fusing",
          "Ginner",
          "Glueing",
          "Grading",
          "Handwork",
          "Hard Product Component & Trim Production",
          "Headquarters",
          "Heel Wrapper",
          "Hot Stamping",
          "Importer only",
          "Ironing",
          "Knitting",
          "Knitting to Finishing",
          "Laser",
          "Lasting",
          "Laundry",
          "Linking",
          "manufacturer",
          "Marker",
          "Mechanical Recycling",
          "Mending",
          "Mill",
          "Molding",
          "Nonwoven manufacturing",
          "Other (enter)",
          "Packaging",
          "Packing",
          "Packing, Finishing, Shipping",
          "Painting",
          "Pattern making",
          "Placement Print",
          "Pleating",
          "Pre-fabrication",
          "pre-shrink",
          "Pre-Treatment",
          "Pressing",
          "Printer",
          "Printing",
          "Processor / Reeler",
          "Product Cutting",
          "QC",
          "Repacking",
          "Sampling",
          "Sew",
          "Sewing",
          "Shipping",
          "Slaughterhouse",
          "Smocking",
          "Spinning",
          "Stamping",
          "Stitching",
          "Stone Wash",
          "storage",
          "Supplier",
          "Surface treatment of metals",
          "Tannery",
          "Trading Company",
          "Warehouse",
          "Wash House",
          "Washing",
          "Weaving",
          "Welt Pocket",
          "Yarn Spinner",
        ],
      },
      {
        fieldLabel: "OS ID",
        dbMapping: "metadata.osId",
        fieldType: "string",
      },
      {
        fieldLabel: "Wordly Higg ID",
        dbMapping: "metadata.wordlyHiggId",
        fieldType: "string",
      },
      {
        fieldLabel: "Who are your existing clients (brands/retailers)?",
        dbMapping: "metadata.existingClients",
        fieldType: "string",
      },
      {
        fieldLabel: "Facility Affiliation",
        dbMapping: "metadata.facilityAffiliation",
        fieldType: "dropdown",
        dropdownOptions: [
          "Subcontractor",
          "Joint Venture",
          "Owned",
          "Direct Partner",
        ],
      },
      {
        fieldLabel: "Minimum Order Quantity",
        dbMapping: "metadata.minOrderQty",
        fieldType: "string",
      },
      {
        fieldLabel: "Average Lead time",
        dbMapping: "metadata.avgLeadTime",
        fieldType: "string",
      },
      {
        fieldLabel: "Total Number of Machines",
        dbMapping: "metadata.machineCount",
        fieldType: "string",
      },
      {
        fieldLabel: "Peak Season",
        dbMapping: "metadata.productionSeasonalityPeak",
        fieldType: "dropdown multi",
        dropdownOptions: [
          "Jan",
          "Feb",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      {
        fieldLabel: "Low Season",
        dbMapping: "metadata.productionSeasonalityLow",
        fieldType: "dropdown multi",
        dropdownOptions: [
          "Jan",
          "Feb",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "Sept",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      {
        fieldLabel: "Avg Units Produced/Week",
        dbMapping: "metadata.avgUnitsProducedPerWeek",
        fieldType: "string",
      },
      {
        fieldLabel:
          "Average Annual Production Volume (in meters, units, or kilograms)?",
        dbMapping: "metadata.avgAnnualBusinessVolume",
        fieldType: "string",
      },
    ],
  },
  {
    name: "Social Compliance Information",
    fields: [
      {
        fieldLabel: "Garment Registration / Business License #",
        dbMapping: "metadata.businessLicenseInfo.licenseNo",
        fieldType: "string",
      },
      {
        fieldLabel: "Registration / License Expiration Date",
        dbMapping: "metadata.businessLicenseInfo.licenseExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "Registration/License Upload",
        dbMapping: "metadata.businessLicenseInfo.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "Workers' Comp Certificate (WCC)",
        dbMapping: "metadata.wcc.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "WCC Expiration Date (US only)",
        dbMapping: "metadata.wcc.wccExpDate",
        fieldType: "date",
      },
      { fieldLabel: 'Whitespace', dbMapping: '', fieldType: 'none' },
      {
        fieldLabel: "NDA Signed Document",
        dbMapping: "metadata.nda.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "NDA Signed?",
        dbMapping: "metadata.nda.ndaCheck",
        fieldType: "bool",
      },
      { fieldLabel: "NDA Signed Date", dbMapping: "metadata.nda.ndaSignedDate", fieldType: "date"},
      {
        fieldLabel: "SPG Signed Document",
        dbMapping: "metadata.spgAor.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "SPG Signed?",
        dbMapping: "metadata.spgAor.spgAorCheck",
        fieldType: "bool",
      },
      { fieldLabel: 'SPG Signed Date', dbMapping: 'metadata.spgAor.spgAorDate', fieldType: 'date' },
      {
        fieldLabel: "Dormitories/Employee Housing Present?",
        dbMapping: "metadata.housing.employeeHousingCheck",
        fieldType: "bool",
      },
      {
        fieldLabel: "Do workers pay for housing?",
        dbMapping: "metadata.housing.doWorkersPayForHousing",
        fieldType: "bool",
      },
      {
        fieldLabel: "If so, how much?",
        dbMapping: "metadata.housing.howMuchDoWorkersPayForHousing",
        fieldType: "string",
      },
      {
        fieldLabel: "Are there Multiple Tenants?",
        dbMapping: "metadata.tenants.multipleTenantsCheck",
        fieldType: "bool",
      },
      {
        fieldLabel: "If so, Do they operate under the same business License?",
        dbMapping:
          "metadata.tenants.multipleTenantsDoTheyOperateUnderSameBusinessLicense",
        fieldType: "bool",
      },
      {
        fieldLabel:
          "Does the facility actively* use the Higg FSLM to track and manage their social responsibility impact?",
        dbMapping: "metadata.higg.higgFLSMCheck",
        fieldType: "bool",
      },
      //{ fieldLabel: 'If yes to using the FSLM, please share your latest FSLM/FEM module with Reformation.', dbMapping: 'metadata.higg.fileUpload', fieldType: 'file' },
      //{ fieldLabel: 'Current FSLM Score', dbMapping: 'metadata.higg.higgFSLMScore', fieldType: 'string' },
      //{ fieldLabel: 'Latest FSLM Module Completed', dbMapping: 'metadata.higg.higgFSLMModule', fieldType: 'string' },
      {
        fieldLabel: "Time Recording System",
        dbMapping: "metadata.timeRecordingSystem",
        fieldType: "dropdown",
        dropdownOptions: [
          "Manual Time card",
          "Biometric (ex. eye scan, fingerprint)",
          "Other",
        ],
      },
      {
        fieldLabel: 'If "Other", please describe system',
        dbMapping: "metadata.timeRecordingSystemOther",
        fieldType: "string",
      },
      {
        fieldLabel: "# of Social Assessments facility receives each year",
        dbMapping: "metadata.socialAssessmentInfo.socialAssessmentCount",
        fieldType: "number",
      },
      {
        fieldLabel: "Types of Social Assessments",
        dbMapping: "metadata.socialAssessmentInfo.socialAssessmentTypes",
        fieldType: "dropdown multi",
        dropdownOptions: [
          "ABVETEX",
          "Better Work",
          "Brand Audit",
          "BSCI",
          "Development audit",
          "ERSA",
          "ERSA-FLC",
          "ERSA-FLC 3.0",
          "Fair Trade",
          "Higg VFSLM",
          "Labor Law Monitoring",
          "SA8000",
          "SMETA 2-Pillar",
          "SMETA 4-Pillar",
          "SQP",
          "Verified SAQ (Elevate)",
          "WCA",
          "WRAP",
          "No assessment",
        ],
      },
      {
        fieldLabel: "Date of last social audit / assessment",
        dbMapping: "metadata.socialAssessmentInfo.lastAssessmentDate",
        fieldType: "string",
      },
      {
        fieldLabel: "Type of last audit / assessment",
        dbMapping: "metadata.socialAssessmentInfo.lastSocialAssessmentType",
        fieldType: "string",
      },
      {
        fieldLabel: "MRA Audit",
        dbMapping: "metadata.socialAssessmentInfo.auditForMRA.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "MRA CAP",
        dbMapping: "metadata.socialAssessmentInfo.capForMRA.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel:
          "Name of audit firm that conducted the last audit / assessment",
        dbMapping: "metadata.socialAssessmentInfo.lastAssessmentFirm",
        fieldType: "string",
      },
      {
        fieldLabel: "Do you have your own Code of Conduct?",
        dbMapping: "metadata.coc.hasOwnCoc",
        fieldType: "bool",
      },
      {
        fieldLabel: "Own COC Upload",
        dbMapping: "metadata.coc.ownCoc.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel:
          "Have you adopted or assigned a code of conduct (CoC) and labor and environmental standards, or agreed to and signed a code of conduct of any of your buyers?",
        dbMapping: "metadata.coc.cocCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "Unsure"],
      },
      {
        fieldLabel: "What COC have you signed/aligned with?",
        dbMapping: "metadata.coc.whichCOC",
        fieldType: "dropdown multi",
        dropdownOptions: [
          "Fair Labor Association (FLA)",
          "SA8000",
          "Brand COC",
          "Fair Trade",
          "International COC",
          "Other",
        ],
      },
      {
        fieldLabel: "What partner/client/brand COC have you signed?",
        dbMapping: "metadata.coc.signedPartnerCOC",
        fieldType: "string",
      },
      {
        fieldLabel:
          "If 'NO', are you ready and willing to sign a code of conduct on workplace standards?",
        dbMapping: "metadata.coc.ifNoCocWillingToSign",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No"],
      },
      {
        fieldLabel: "Signed COC Upload",
        dbMapping: "metadata.coc.fileUpload",
        fieldType: "file",
      },
      //{ fieldLabel: 'Do all workers get paid at least the legal minimum wage?', dbMapping: 'metadata.facilityChecks.legalMinWage', fieldType: 'dropdown' },
      {
        fieldLabel:
          "Do you or have you worked with any Fair Labor Association (FLA) Accredited brands?",
        dbMapping: "metadata.fla.flaCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No"],
      },
      {
        fieldLabel: "If 'Yes', please specify which brands",
        dbMapping: "metadata.fla.flaBrands",
        fieldType: "string",
      },
    ],
  },
  {
    name: "Employee/Worker Information",
    fields: [
      {
        fieldLabel: "Total Number of Employees",
        dbMapping: "metadata.workerCountTotal",
        fieldType: "number",
      },
      {
        fieldLabel: "Total Number of Production Employees",
        dbMapping: "metadata.workerCountProduction",
        fieldType: "number",
      },
      {
        fieldLabel: "Number of Male workers",
        dbMapping: "metadata.workerGenderMale",
        fieldType: "number",
      },
      {
        fieldLabel: "Number of Female workers",
        dbMapping: "metadata.workerGenderFemale",
        fieldType: "number",
      },
      {
        fieldLabel: "Number of Local/ Domestic Migrant Workers",
        dbMapping: "metadata.domesticMigrantWorkers",
        fieldType: "number",
      },
      {
        fieldLabel: "Number of Foreign Migrant Workers",
        dbMapping: "metadata.foreignMigrantWorkers",
        fieldType: "number",
      },
      {
        fieldLabel: "Main language(s) spoken by Employees/Workers",
        dbMapping: "metadata.employeePrimarilySpokenLanguage",
        fieldType: "string",
      },
      {
        fieldLabel:
          "Are workers hired through labor brokers/staffing agencies?",
        dbMapping:
          "metadata.employeeInfo.workedHiredThroughBrokersOrAgenciesCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No"],
      },
      {
        fieldLabel: "Do workers pay fees to the brokers/staffing agencies?",
        dbMapping: "metadata.employeeInfo.agencyWorkersFeesPaidCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No"],
      },
      {
        fieldLabel: "Do employees retain their own documents?",
        dbMapping: "metadata.employeeInfo.doEmployeesRetainTheirDocuments",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "Unsure"],
      },
      {
        fieldLabel:
          "If 'NO', who retains documents and where are they maintained?",
        dbMapping: "metadata.employeeInfo.whoRetainsEmployeeDocuments",
        fieldType: "string",
      },
      {
        fieldLabel: "Age of youngest worker at facility",
        dbMapping: "metadata.employeeInfo.ageOfYoungestWorker",
        fieldType: "number",
      },
    ],
  },
  {
    name: "Freedom of Association",
    fields: [
      {
        fieldLabel: "Is there a union in the facility?",
        dbMapping: "metadata.freedomOfAssoc.unionCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No"],
      },
      {
        fieldLabel: "If 'YES', name of Union",
        dbMapping: "metadata.freedomOfAssoc.unionName",
        fieldType: "string",
      },
      {
        fieldLabel: "Do employees pay union dues?",
        dbMapping: "metadata.freedomOfAssoc.employeesPayUnionDuesCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "Optional", "None of the above"],
      },
      {
        fieldLabel: "If 'YES', how much & how often are dues deducted?",
        dbMapping: "metadata.freedomOfAssoc.howMuchHowOftenDues",
        fieldType: "string",
      },
      {
        fieldLabel:
          "Is there a Collective Bargaining Agreement (CBA) in place?",
        dbMapping: "metadata.freedomOfAssoc.cbaCheck",
        fieldType: "string",
      },
      {
        fieldLabel: "If 'YES', CBA valid period",
        dbMapping: "metadata.freedomOfAssoc.cbaPeriod",
        fieldType: "string",
      },
      {
        fieldLabel: "Are there worker's committees present in your facility?",
        dbMapping: "metadata.freedomOfAssoc.workerCommitteeCheck",
        fieldType: "bool",
      },
    ],
  },
  {
    name: "Subcontractor Information",
    fields: [
      {
        fieldLabel:
          "Does the Facility employ subcontractors for any part of the production process?",
        dbMapping: "metadata.subcontractors.subcontractorsCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "N/A"],
      },
      {
        fieldLabel: "If 'Yes', provide details",
        dbMapping: "metadata.subcontractors.details",
        fieldType: "string",
      },
    ],
  },
  {
    name: "Environmental Management",
    fields: [
      {
        fieldLabel:
          "Do you have a comprehensive environmental management system in place?",
        dbMapping: "metadata.envMgmt.envMgmtSystemCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "N/A"],
      },
      {
        fieldLabel:
          "Is your facility in compliance with all federal, state, and local environmental permitting requirements?",
        dbMapping: "metadata.envMgmt.envPermitComplianceCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "N/A"],
      },
      {
        fieldLabel: "Wastewater Discharge Permit",
        dbMapping: "metadata.envMgmt.wastewaterDischargePermitCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "Unknown"],
      },
      {
        fieldLabel: "Wastewater Discharge Permit Expiration Date",
        dbMapping: "metadata.envMgmt.wastewaterDischargePermitExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "Wastewater Discharge Permit Upload",
        dbMapping:
          "metadata.envMgmt.wastewaterDischargePermitUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "Stormwater Discharge Permit",
        dbMapping: "metadata.envMgmt.stormwaterDischargePermitCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "Unknown"],
      },
      {
        fieldLabel: "Stormwater Discharge Permit Expiration Date:",
        dbMapping: "metadata.envMgmt.stormwaterDischargePermitExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "Stormwater Discharge Permit Upload:",
        dbMapping:
          "metadata.envMgmt.stormwaterDischargePermitUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "Waste Generation and Handling Permit",
        dbMapping: "metadata.envMgmt.wasteGenerationHandlingPermitCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "Unknown", "N/A"],
      },
      {
        fieldLabel: "Waste Generation and Handling Permit Expiration Date",
        dbMapping: "metadata.envMgmt.wasteGenerationHandlingPermitExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "Waste Generation and Handling Permit Upload:",
        dbMapping:
          "metadata.envMgmt.wasteGenerationHandlingPermitUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "Air Permit",
        dbMapping: "metadata.envMgmt.airPermitCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "Unknown"],
      },
      {
        fieldLabel: "Air Permit Expiration Date",
        dbMapping: "metadata.envMgmt.airPermitExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "Air Permit Upload:",
        dbMapping: "metadata.envMgmt.airPermitUpload.fileUpload",
        fieldType: "file",
      },
    ],
  },
  {
    name: "Environmental Compliance",
    fields: [
      {
        fieldLabel:
          "Do you have a program/system to review and monitor environmental permit status and renewal?",
        dbMapping: "metadata.envCompliance.envPermitStatusProgramCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No"],
      },
      {
        fieldLabel:
          "If 'Yes', please describe the process & provide SOP (if available)",
        dbMapping: "metadata.envCompliance.envPermitStatusSOPUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel:
          "Does your facility have a means to stay up-to-date with legal requirements regarding environmental compliance (i.e. emissions, water, energy, chemicals, etc.)?",
        dbMapping:
          "metadata.envCompliance.envComplianceLegalRequirementsMeansCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No"],
      },
      {
        fieldLabel:
          "If 'Yes', describe the process & provide SOP (if available)",
        dbMapping:
          "metadata.envCompliance.envComplianceLegalRequirementsSOPUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "Do you have a stormwater management plan?",
        dbMapping: "metadata.envCompliance.stormwaterMgmtPlanCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "N/A"],
      },
      {
        fieldLabel: "Do you have an onsite wastewater treatment system?",
        dbMapping:
          "metadata.envCompliance.wastewaterTreatmentSystemOnsiteCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "N/A", "Indirect emissions"],
      },
      {
        fieldLabel: "Wastewater Treatment Type",
        dbMapping: "metadata.envCompliance.wastewaterTreatmentType",
        fieldType: "dropdown",
        dropdownOptions: [
          "Full Treatment",
          "Municipal/Local Treatment only",
          "Pretreatment only",
          "None",
        ],
      },
      {
        fieldLabel: "Do you have a chemical management plan?",
        dbMapping: "metadata.envCompliance.chemicalMgmtPlanCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "N/A"],
      },
      {
        fieldLabel: "Do you have an emergency spill response plan?",
        dbMapping: "metadata.envCompliance.emergencySpillResponsePlanCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "N/A"],
      },
      {
        fieldLabel:
          "Is a Chemical Inventory List maintained for all production & operational chemicals used onsite?",
        dbMapping: "metadata.envCompliance.chemicalInventoryList",
        fieldType: "dropdown",
        dropdownOptions: [
          "Yes",
          "Partial Yes: Production Chemicals Only",
          "Partial Yes: Operational Chemicals Only",
          "Partial Yes: In Process of Developing Inventory",
          "No",
        ],
      },
      {
        fieldLabel: "If 'Yes', please provide the latest inventory report",
        dbMapping:
          "metadata.envCompliance.chemicalInventoryListUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel:
          "Is the chemical inventory list compliant with the facility Manufacturer Restricted Substance List (MRSL)?",
        dbMapping: "metadata.envCompliance.chemicalInventoryListCompliance",
        fieldType: "dropdown",
        dropdownOptions: [
          "Yes",
          "No",
          "Unknown",
          "Partial Yes",
          "None of the above",
        ],
      },
      {
        fieldLabel:
          "How often is the compliance of the chemical inventory internally verified?",
        dbMapping:
          "metadata.envCompliance.chemicalInventoryInternalVerificationFrequency",
        fieldType: "dropdown",
        dropdownOptions: [
          "Daily",
          "Weekly",
          "Monthly",
          "Quarterly",
          "Annually",
          "Not verified on a regular basis",
          "None of the above",
        ],
      },
      {
        fieldLabel: "Chemical Inventory Compliance Type",
        dbMapping: "metadata.envCompliance.chemicalInventoryComplianceType",
        fieldType: "dropdown multi",
        dropdownOptions: [
          "ZDHC Level 1",
          "ZDHC Level 2",
          "ZDHC Level 3",
          "Bluesign",
          "Oeko-text",
          "GOTS",
          "GRS",
          "None",
        ],
      },
      {
        fieldLabel:
          "Does the facility actively* use the Higg FEM to track and manage their environmental impact?",
        dbMapping: "metadata.higg.higgFEMCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No"],
      },
      {
        fieldLabel:
          "If yes to using the FEM, please share your latest FEM module with Reformation.",
        dbMapping: "metadata.higg.higgFEMPleaseShareModule",
        fieldType: "bool",
      },
      {
        fieldLabel:
          "Has the facility actively participated in any resource effiency or clean chemistry programs in the last two years? (i.e., Apparel Impact Institute's Clean by Design and Carbon Leadership Program, Bluesign, ZDHC's Roadmap to Zero, etc.)",
        dbMapping: "metadata.envCompliance.resourceEfficiencyProgramCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No"],
      },
      {
        fieldLabel:
          "If yes to participating in resource efficiency program(s), please briefly describe the program(s) and when it occurred.",
        dbMapping:
          "metadata.envCompliance.resourceEfficiencyProgramDescription",
        fieldType: "string",
      },
    ],
  },
  {
    name: "Current Certifications/Programs",
    fields: [
      {
        fieldLabel: "BSCI",
        dbMapping: "metadata.certifications.bsciCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "BSCI Expiration Date:",
        dbMapping: "metadata.certifications.bsciExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "BSCI Certificate:",
        dbMapping: "metadata.certifications.bsciUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "ICTI",
        dbMapping: "metadata.certifications.ictiCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "ICTI Expiration Date:",
        dbMapping: "metadata.certifications.ictiExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "ICTI Certificate:",
        dbMapping: "metadata.certifications.ictiUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "WRAP",
        dbMapping: "metadata.certifications.wrapCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "WRAP Expiration Date:",
        dbMapping: "metadata.certifications.wrapExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "WRAP Certificate:",
        dbMapping: "metadata.certifications.wrapUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "SA8000",
        dbMapping: "metadata.certifications.sa8000Check",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "SA8000 Expiration Date:",
        dbMapping: "metadata.certifications.sa8000ExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "SA8000 Certificate:",
        dbMapping: "metadata.certifications.sa8000Upload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "SMETA",
        dbMapping: "metadata.certifications.smetaCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "SMETA Expiration Date:",
        dbMapping: "metadata.certifications.smetaExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "SMETA Certificate:",
        dbMapping: "metadata.certifications.smetaUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "Better Work",
        dbMapping: "metadata.certifications.betterWorkCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "Better Work Expiration Date:",
        dbMapping: "metadata.certifications.betterWorkExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "Better Work Certificate:",
        dbMapping: "metadata.certifications.betterWorkUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "BAP",
        dbMapping: "metadata.certifications.bapCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "BAP Expiration Date:",
        dbMapping: "metadata.certifications.bapExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "BAP Certificate:",
        dbMapping: "metadata.certifications.bapUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "ISO 9001",
        dbMapping: "metadata.certifications.iso9001Check",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "ISO 9001 Expiration Date:",
        dbMapping: "metadata.certifications.iso9001ExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "ISO 9001 Certificate:",
        dbMapping: "metadata.certifications.iso9001Upload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "ISO 14001",
        dbMapping: "metadata.certifications.iso14001Check",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "ISO 14001 Expiration Date:",
        dbMapping: "metadata.certifications.iso14001ExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "ISO 14001 Certificate",
        dbMapping: "metadata.certifications.iso14001Upload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "ISO 18001",
        dbMapping: "metadata.certifications.iso18001Check",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "ISO 18001 Expiration Date:",
        dbMapping: "metadata.certifications.iso18001ExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "ISO 18001 Certificate:",
        dbMapping: "metadata.certifications.iso18001Upload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "BLUESIGN® system",
        dbMapping: "metadata.certifications.bluesignCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "BLUESIGN® system Expiration Date:",
        dbMapping: "metadata.certifications.bluesignExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "BLUESIGN® system Certificate:",
        dbMapping: "metadata.certifications.bluesignUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "OEKO-TEX® Standard 100",
        dbMapping: "metadata.certifications.okeotexStandard100Check",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "OEKO-TEX® Standard 100 Expiration Date:",
        dbMapping: "metadata.certifications.okeotexStandard100ExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "OEKO-TEX® Standard 100 Certificate:",
        dbMapping:
          "metadata.certifications.okeotexStandard100Upload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "OEKO-TEX® Made In Green",
        dbMapping: "metadata.certifications.okeotexMadeInGreenCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "OEKO-TEX® Made In Green Expiration Date:",
        dbMapping: "metadata.certifications.okeotexMadeInGreenExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "OEKO-TEX® Made In Green Certificate:",
        dbMapping:
          "metadata.certifications.okeotexMadeInGreenUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "OEKO-TEX® Detox to Zero",
        dbMapping: "metadata.certifications.okeotexDetoxToZeroCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "OEKO-TEX® Detox to Zero Expiration Date:",
        dbMapping: "metadata.certifications.okeotexDetoxToZeroExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "OEKO-TEX® Detox to Zero Certificate:",
        dbMapping:
          "metadata.certifications.okeotexDetoxToZeroUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "OEKO-TEX® STeP",
        dbMapping: "metadata.certifications.okeotexStepCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "OEKO-TEX® STeP Expiration Date:",
        dbMapping: "metadata.certifications.okeotexStepExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "OEKO-TEX® STeP Certificate:",
        dbMapping: "metadata.certifications.okeotexStepUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "OEKO-TEX® Eco-Passport",
        dbMapping: "metadata.certifications.okeotexEcoPassportCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "OEKO-TEX® Eco-Passport Expiration Date:",
        dbMapping: "metadata.certifications.okeotexEcoPassportExpDate",
        fieldType: "date",
      },
      {
        fieldLabel: "OEKO-TEX® Eco-Passport Certificate:",
        dbMapping:
          "metadata.certifications.okeotexEcoPassportUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "ZDHC In-Check Report",
        dbMapping: "metadata.certifications.zdhcReportCheck",
        fieldType: "dropdown",
        dropdownOptions: ["Yes", "No", "None of the above"],
      },
      {
        fieldLabel: "ZDHC In-Check Report:",
        dbMapping: "metadata.certifications.zdhcReportUpload.fileUpload",
        fieldType: "file",
      },
      {
        fieldLabel: "ZDHC In-Check Report Expiration Date",
        dbMapping: "metadata.certifications.zdhcReportExpDate",
        fieldType: "date",
      },
    ],
  },
];
