import React, { useCallback, useState, useEffect } from "react";

import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
  Box,
  TextField,
  Paper,
  Tooltip,
} from "@mui/material";
import CustomChipLabel from "./customChipLabel.component";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAuditIssueDetails,
  selectSelectedAuditIssueId,
} from "../../selectors/audit.selector";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { getValidData } from "../../utils/lib";
import { debounce } from "lodash";
import {
  selectAutoSaveTimer,
  selectSaveStatus,
} from "../../selectors/misc.selector";
import { saveStatus, setSaveStatus } from "../../reducers/misc.reducer";
import { useParams } from "react-router-dom";
import { updateAuditData } from "../../reducers/audit.reducer";
import { CheckCircleOutline } from "@mui/icons-material";
import { AuditStyles, ComponentStyles } from "../../styles";
const RecommendationsList = ({ title, items }) => (
  <Paper elevation={0} sx={{ mb: 2 }}>
    <Typography
      sx={{
        color: "#475467",
        fontSize: "14px",
        fontWeight: 700,
      }}
    >
      {title}
    </Typography>
    <List dense>
      {items.map((item, index) => (
        <ListItem key={index} disablePadding>
          <ListItemText
            primary={
              <Typography
                sx={
                  AuditStyles.auditReportFieldValue
                }
              >
                {item}
              </Typography>
            }
          />
        </ListItem>
      ))}
    </List>
  </Paper>
);

const AiGeneratedField = ({ label, children }) => (
  <Box>
    <Typography
      sx={{
        color: "#475467",
        fontSize: "14px",
        fontWeight: 700,
        mb: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
      <Tooltip title="Generated by AI">
        <AutoAwesomeIcon sx={{ fontSize: 16, ml: 0.5, color: "darkgrey" }} />
      </Tooltip>
    </Typography>
    {children}
  </Box>
);

const AuditReport = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const data = useSelector(selectAuditIssueDetails);
  const saveStatus = useSelector(selectSaveStatus);
  
  console.log("data", data);

  const selectedIssueId = useSelector(selectSelectedAuditIssueId);

  const debouncedSaveNote = useCallback(
    debounce((newNote) => {
      dispatch(
        updateAuditData({
          auditId: params?.auditId,
          supplierId: params?.supplierId,
          factoryId: params?.factoryId,
          changes: { capDetailsNote: newNote },
          issueId: selectedIssueId,
        })
      );
    }, 1000),
    []
  );

  useEffect(() => {
    if (saveStatus === "saved") {
      setTimeout(() => dispatch(setSaveStatus("")), 1000);
    }
  }, [saveStatus]);
  const [note, setNote] = useState("");
  useEffect(() => {
    setNote("");
    setNote(getValidData(data.note));
  }, [selectedIssueId]);
  const handleNoteChange = (event) => {
    const newValue = event.target.value;
    dispatch(setSaveStatus("saving"));
    setNote(newValue);

    debouncedSaveNote(newValue);
  };

  return (
    <Container sx={{ mt: 2 }}>
      <Box sx={{ p: 2 }}>
        {/* Header Section */}
        <Typography
          sx={{
            color: "#475467",
            fontSize: "30px",
            fontWeight: 500,
            textDecorationLine: "underline",
          }}
          gutterBottom
        >
          Finding
        </Typography>
        <Typography
          sx={{
            ...AuditStyles.auditReportFieldValue,
            fontSize: "24px",
            fontWeight: 400,
            mt: 2,
            mb: 3,
          }}
        >
          {data?.issueSummary || "-"}
        </Typography>

        {/* Details Section */}
        <Grid container spacing={1} sx={{ mt: 1}}>
          <Grid item xs={2}>
            <Typography sx={AuditStyles.fieldTitle}>Finding ID</Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.clientCodeTitle || "-"}
            </Typography>
          </Grid>
          {/* <Grid item xs={3}>
            <Typography sx={AuditStyles.fieldTitle}>Category</Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.type || "-"}
            </Typography>
          </Grid> */}
          <Grid item xs={3}>
            <Typography sx={AuditStyles.fieldTitle}>Vioation Category</Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.subType || "-"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={AuditStyles.fieldTitle}>Repeat Finding</Typography>

            <Chip
              label={data?.repeatedIssue || "No"}
              sx={{
                fontSize: "14px",
                color: "#344054",
                fontWeight: 500,
                textAlign: "center",
                borderRadius: "6px",
                border: "1px solid #D0D5DD",
                background: "#FFF",
                padding: "4px 10px",
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ my: 2 }}>
            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
              }}
            >
              Finding Details
            </Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.issueDetail || "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
              }}
            >
              Audit Code or Legal Reference
            </Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.auditBasis || "N/A"}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
              }}
            >
              Reformation Audit Tool Requirement
            </Typography>
            <Typography sx={AuditStyles.auditReportFieldValue}>
              {data?.clientCode || "-"}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item xs={6} sm={6}  md={2}>
            <AiGeneratedField label="Severity">
              <Typography variant="body1" sx={{ color: "error.main" }}>
                <CustomChipLabel severity={data.severity} />
              </Typography>
            </AiGeneratedField>
          </Grid>

          <Grid item xs={6} sm={6}  md={3}>
            <AiGeneratedField label="Recommended Timeline">
              <Typography
                sx={{
                  ...AuditStyles.auditReportFieldValue,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {data?.timeline || "-"}
              </Typography>
            </AiGeneratedField>
          </Grid>
          {/* Rating Explanation */}
          <Grid item xs={12} sm={12} md= {7}>
            <AiGeneratedField label="Rating Explanation">
              <Typography
                sx={{
                  color: "#45464E",
                  fontSize: "14px",
                  fontWeight: 400,
                }}
              >
                {data?.ratingExplanation || "-"}
              </Typography>
            </AiGeneratedField>
          </Grid>

          {/* Internal Note */}
          <Grid item xs={12}>
            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
              }}
            >
              Internal Note
            </Typography>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={note}
              onChange={handleNoteChange}
              helperText={
                saveStatus === "saving" ? (
                  <Typography
                    sx={{
                      color: "error.main",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Saving...
                  </Typography>
                ) : saveStatus === "saved" ? (
                  <Box sx={ComponentStyles.alignBoxItemsCenter}>
                    <Typography
                      sx={{
                        color: "success.main",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Saved
                    </Typography>
                    <CheckCircleOutline
                      color="success"
                      sx={{ ml: 1 }}
                      fontSize="small"
                    />
                  </Box>
                ) : (
                  ""
                )
              }
              FormHelperTextProps={{
                sx: {
                  ml: 0, // Adjust the left margin as needed
                },
              }}
              inputProps={{
                style: {
                  WebkitTextFillColor: "#45464E",
                  color: "#45464E",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {/* CAP Recommendation Section */}
          <Grid item xs={6} sx={{pr:4}}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "30px",
                fontWeight: 500,
                textDecorationLine: "underline",
                my: 4, // Added margin bottom for spacing
                overflowWrap: "break-word",
                wordWrap: "break-word",
                hyphens: "auto",
              }}
            >
              CAP Recommendations
            </Typography>
            {/* <AiGeneratedField label="Root Cause">
              <RecommendationsList
                title=""
                items={
                  data?.rootCause
                    ? String(data.rootCause).split("\n")
                    : []
                }
              />
            </AiGeneratedField> */}
            <AiGeneratedField label="Immediate Corrective Action Plan">
              <RecommendationsList
                title=""
                items={
                  data?.capRecommendations?.immediateCAP
                    ? String(data.capRecommendations.immediateCAP).split("\n")
                    : []
                }
              />
            </AiGeneratedField>
            <AiGeneratedField label="Long Term Preventative Action">
              <RecommendationsList
                title=""
                items={
                  data?.capRecommendations?.longTermPreventativeAction
                    ? String(
                        data.capRecommendations.longTermPreventativeAction
                      ).split("\n")
                    : []
                }
              />
            </AiGeneratedField>
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                color: "#475467",
                fontSize: "30px",
                fontWeight: 500,
                textDecorationLine: "underline",
                my: 4, // Added margin bottom for spacing
              }}
            >
              Facility Input
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    color: "#475467",
                    fontSize: "14px",
                    fontWeight: 700,
                    mb: 1,

                  }}
                >
                  Accountable Person
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontStyle: "italic",
                    color: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  {data?.responsiblePerson || "To be filled by factory"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    ...AuditStyles.fieldTitle,
                    mb: 1,

                  }}
                >
                  Target Completion Date
                </Typography>
                <Typography sx={AuditStyles.auditReportFieldValue}>
                  {data?.targetDeadline || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
                mt: 2,
              }}
            >
              Root Cause Response
            </Typography>
            {(data?.rootCauseResponse || "To be filled by factory")
              .split('\n')
              .map((paragraph, index) => (
                <Typography
                  key={index}
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontStyle: "italic",
                    color: "rgba(0, 0, 0, 0.6)",
                    mb: 1,
                  }}
                >
                  {paragraph}
                </Typography>
              ))}

            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
                mt: 2,
              }}
            >
              Immediate Corrective Action Plan
            </Typography>
            {(data?.immediateCorrectiveActionPlanFacilityInput || "To be filled by factory")
              .split('\n')
              .map((paragraph, index) => (
                <Typography
                  key={index}
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontStyle: "italic",
                    color: "rgba(0, 0, 0, 0.6)",
                    mb: 1,
                  }}
                >
                  {paragraph}
                </Typography>
              ))}

            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mb: 1,
                mt: 2,
              }}
            >
              Long Term Preventative Action Plan
            </Typography>
            {(data?.longTermPreventativeActionPlanFacilityInput || "To be filled by factory")
              .split('\n')
              .map((paragraph, index) => (
                <Typography
                  key={index}
                  sx={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontStyle: "italic",
                    color: "rgba(0, 0, 0, 0.6)",
                    mb: 1, // Add margin bottom between paragraphs
                  }}
                >
                  {paragraph}
                </Typography>
              ))}

            <Typography
              sx={{
                color: "#475467",
                fontSize: "30px",
                fontWeight: 500,
                textDecorationLine: "underline",
                my: 4, // Added margin bottom for spacing
              }}
            >
              CAP Update
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Typography
                  sx={{
                    ...AuditStyles.fieldTitle,
                    mb: 1,
                  }}
                >
                  Status
                </Typography>
                <Box sx={ComponentStyles.alignBoxItemsStart}>
                  <CustomChipLabel
                    cap={data.statusChipLabel}
                    capLabel={data.status}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  sx={{
                    ...AuditStyles.fieldTitle,
                    mb: 1,
                    mb: 1,
                  }}
                >
                  Actual Completion Date
                </Typography>
                <Typography sx={AuditStyles.auditReportFieldValue}>
                  {data?.completionDate || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Typography
              sx={{
                ...AuditStyles.fieldTitle,
                mt: 3,
              }}
            >
              Verification Note
            </Typography>
            {(data?.verificationNote || "To be filled by factory")
              .split('\n')
              .map((paragraph, index) => (
                <Typography
                  key={index}
                  sx={{
                    ...AuditStyles.auditReportFieldValue,
                    mb: 1,
                  }}
                >
                  {paragraph}
                </Typography>
              ))}
          </Grid>
        </Grid>
        {/* <Typography
          sx={{
            color: "#000",
            textAlign: "right",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
          }}
        >
          Last Updated by AuditSense AI
        </Typography> */}
      </Box>
    </Container>
  );
};

export default AuditReport;
