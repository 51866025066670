import React from "react";
import { Box } from "@mui/material";
import LeftSideNavbar from "../components/leftSideNavbar.component";
import QuestionnaireTable from "../components/QuestionnaireDetails/questionnaireTable.component";

const QuestionnairePage = () => {
  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <LeftSideNavbar />
      <Box sx={{ flex: 1, overflow: "auto" }}>
        <QuestionnaireTable />
      </Box>
    </Box>
  );
};

export default QuestionnairePage;