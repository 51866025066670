import { Country, State } from "country-state-city";
const countryOptions = Country.getAllCountries().map((country) => ({
  label: country.name,
  value: country.isoCode,
}));

export const languages = [
    "Afrikaans",
    "Albanian",
    "Arabic",
    "Armenian",
    "Basque",
    "Bengali",
    "Bulgarian",
    "Catalan",
    "Cambodian",
    "Chinese (Mandarin)",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "English",
    "Estonian",
    "Fiji",
    "Finnish",
    "French",
    "Georgian",
    "German",
    "Greek",
    "Gujarati",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Icelandic",
    "Indonesian",
    "Irish",
    "Italian",
    "Japanese",
    "Javanese",
    "Korean",
    "Latin",
    "Latvian",
    "Lithuanian",
    "Macedonian",
    "Malay",
    "Malayalam",
    "Maltese",
    "Maori",
    "Marathi",
    "Mongolian",
    "Nepali",
    "Norwegian",
    "Persian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Quechua",
    "Romanian",
    "Russian",
    "Samoan",
    "Serbian",
    "Slovak",
    "Slovenian",
    "Spanish",
    "Swahili",
    "Swedish",
    "Tamil",
    "Tatar",
    "Telugu",
    "Thai",
    "Tibetan",
    "Tonga",
    "Turkish",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Vietnamese",
    "Welsh",
    "Xhosa",
  ];

  export const saqSupplierSections = [
    {
      name: "Supplier Basic Information",
      fields: [
        {
            fieldLabel: "Supplier Name (English)",
            dbMapping: "name",
            fieldType: "string",
        },
        {
            fieldLabel: "Supplier Legal Name (Local Language, as per Business License)",
            dbMapping: "metadata.supplierLegalName",
            fieldType: "string",
        },
        {
            fieldLabel: "Supplier Street Line 1",
            dbMapping: "metadata.addressLine1",
            fieldType: "string",
        },
        {
            fieldLabel: "Supplier Street Line 2",
            dbMapping: "metadata.addressLine2",
            fieldType: "string",
        },
        {
            fieldLabel: "Supplier Country",
            dbMapping: "metadata.country",
            fieldType: "dropdown",
            dropdownOptions: countryOptions,
            isCountrySelect: true,
        },
        {
            fieldLabel: "State",
            dbMapping: "metadata.state",
            fieldType: "dropdown",
            dropdownOptions: [],
            dependsOn: "metadata.country",
        },
        {
            fieldLabel: "City",
            dbMapping: "metadata.city",
            fieldType: "string",
        },
    ]},
    {
        name: "Supplier Contact Information",
        fields: [
            {
              fieldLabel: "Primary Supplier Contact Name",
              dbMapping: "metadata.primarySupplierContactPerson.name",
              fieldType: "string",
            },
            {
              fieldLabel: "Primary Supplier Contact Title",
              dbMapping: "metadata.primarySupplierContactPerson.position",
              fieldType: "string",
            },
            {
              fieldLabel: "Primary Supplier Contact Email",
              dbMapping: "metadata.primarySupplierContactPerson.email",
              fieldType: "string",
            },
            {
              fieldLabel: "Primary Supplier Contact Phone",
              dbMapping: "metadata.primarySupplierContactPerson.phone",
              fieldType: "string",
            },
            {
              fieldLabel: "Primary Supplier Contact Spoken Languages",
              dbMapping: "metadata.primarySupplierContactPerson.spokenLanguages",
              fieldType: "dropdown multi",
              dropdownOptions: languages,
            },
            {
              fieldLabel: "Social Compliance Contact Name",
              dbMapping: "metadata.socialComplianceContactPerson.name",
              fieldType: "string",
            },
            {
              fieldLabel: "Social Compliance Email",
              dbMapping: "metadata.socialComplianceContactPerson.email",
              fieldType: "string",
            },
            {
              fieldLabel: "Social Compliance Phone Number",
              dbMapping: "metadata.socialComplianceContactPerson.phone",
              fieldType: "string",
            },
            {
              fieldLabel: "Social Compliance Spoken Languages",
              dbMapping: "metadata.socialComplianceContactPerson.spokenLanguages",
              fieldType: "dropdown multi",
              dropdownOptions: languages,
            },
            {
              fieldLabel: "Environmental Health & Safety (EHS) Compliance Contact",
              dbMapping: "metadata.ehsContactPerson.name",
              fieldType: "string",
            },
            {
              fieldLabel: "EHS Email",
              dbMapping: "metadata.ehsContactPerson.email",
              fieldType: "string",
            },
            {
              fieldLabel: "EHS Phone Number",
              dbMapping: "metadata.ehsContactPerson.phone",
              fieldType: "string",
            },
            {
              fieldLabel: "EHS Spoken Languages",
              dbMapping: "metadata.ehsContactPerson.spokenLanguages",
              fieldType: "dropdown multi",
              dropdownOptions: languages,
            },
            {
              fieldLabel: "Materials/Sustainability Contact",
              dbMapping: "metadata.materialsSustainabilityContactPerson.name",
              fieldType: "string",
            },
            {
              fieldLabel: "Materials/Sustainability Email",
              dbMapping: "metadata.materialsSustainabilityContactPerson.email",
              fieldType: "string",
            },
            {
              fieldLabel: "Materials/Sustainability Phone Number",
              dbMapping: "metadata.materialsSustainabilityContactPerson.phone",
              fieldType: "string",
            },
            {
              fieldLabel: "Materials/Sustainability Spoken Languages",
              dbMapping:
                "metadata.materialsSustainabilityContactPerson.spokenLanguages",
              fieldType: "dropdown multi",
              dropdownOptions: languages,
            },
          ],
        },
        {
        name: "Parent Company Information",
        fields: [
            {
                fieldLabel: "Parent Company Name (English)",
                dbMapping: "metadata.parentCompanyName",
                fieldType: "string",
            },
            {
                fieldLabel: "Parent Company Legal Name (Local Language, as per Business License)",
                dbMapping: "metadata.parentCompanyLegalName",
                fieldType: "string",
            },
            {
                fieldLabel: "Parent Company Street Line 1",
                dbMapping: "metadata.parentCompanyStreetLine1",
                fieldType: "string",
            },
            {
                fieldLabel: "Parent Company Street Line 2",
                dbMapping: "metadata.parentCompanyStreetLine2",
                fieldType: "string",
            },
            {
                fieldLabel: "Country",
                dbMapping: "metadata.parentCompanyCountry",
                fieldType: "dropdown",
                dropdownOptions: countryOptions,
                isCountrySelect: true,
            },
            {
                fieldLabel: "State",
                dbMapping: "metadata.parentCompanyState",
                fieldType: "dropdown",
                dropdownOptions: [],
                dependsOn: "metadata.country",
            },
            {
                fieldLabel: "Supplier City",
                dbMapping: "metadata.parentCompanyCity",
                fieldType: "string",
            },
        ]
    },
    {
        name: "Production Information",
        fields: [
          {
            fieldLabel: "Supplier Tier",
            dbMapping: "metadata.processInfo.tier",
            fieldType: "dropdown multi",
            dropdownOptions: ["Supplier", "Agent"],
          },
          {
            fieldLabel:
              "Main Production processes (Multiple Selection)",
            dbMapping: "metadata.processInfo.productionProcesses",
            fieldType: "dropdown multi",
            dropdownOptions: [
              "Agent",
              "Assembly",
              "bias",
              "bias,binding spaghetti",
              "binding spaghetti",
              "Boiling",
              "Bundling",
              "Button hole and keyhole",
              "Buttoning",
              "Candle Making",
              "Coating/Laminating",
              "Combing",
              "Scouring",
              "Compostable Packaging",
              "computer knitting",
              "Converter",
              "Cut & Sew",
              "Cutting",
              "Development",
              "Dispatch",
              "Distribution",
              "Downfill",
              "Dye House",
              "Dyeing",
              "Embroidery",
              "Expedition",
              "Farm",
              "Fiber Producer",
              "Fiber Trader",
              "Final Product Assembly",
              "Finishing",
              "Fusing",
              "Ginner",
              "Glueing",
              "Grading",
              "Handwork",
              "Hard Product Component & Trim Production",
              "Headquarters",
              "Heel Wrapper",
              "Hot Stamping",
              "Importer only",
              "Ironing",
              "Knitting",
              "Knitting to Finishing",
              "Laser",
              "Lasting",
              "Laundry",
              "Linking",
              "manufacturer",
              "Marker",
              "Mechanical Recycling",
              "Mending",
              "Mill",
              "Molding",
              "Nonwoven manufacturing",
              "Other (enter)",
              "Packaging",
              "Packing",
              "Packing, Finishing, Shipping",
              "Painting",
              "Pattern making",
              "Placement Print",
              "Pleating",
              "Pre-fabrication",
              "pre-shrink",
              "Pre-Treatment",
              "Pressing",
              "Printer",
              "Printing",
              "Processor / Reeler",
              "Product Cutting",
              "QC",
              "Repacking",
              "Sampling",
              "Sew",
              "Sewing",
              "Shipping",
              "Slaughterhouse",
              "Smocking",
              "Spinning",
              "Stamping",
              "Stitching",
              "Stone Wash",
              "storage",
              "Supplier",
              "Surface treatment of metals",
              "Tannery",
              "Trading Company",
              "Warehouse",
              "Wash House",
              "Washing",
              "Weaving",
              "Welt Pocket",
              "Yarn Spinner",
            ],
          },
          {
            fieldLabel: "OS ID",
            dbMapping: "metadata.osId",
            fieldType: "string",
          },
          {
            fieldLabel: "Wordly Higg ID",
            dbMapping: "metadata.wordlyHiggId",
            fieldType: "string",
          },
          {
            fieldLabel: "Who are your existing clients (brands/retailers)?",
            dbMapping: "metadata.existingClients",
            fieldType: "string",
          },
        ],
      },
    
      {
        name: "Social Compliance Information",
        fields: [
            { fieldLabel: "NDA Signed Document", dbMapping: "metadata.nda.fileUpload", fieldType: "file"},
            { fieldLabel: "NDA Signed?", dbMapping: "metadata.nda.ndaCheck", fieldType: "bool"},
            { fieldLabel: "NDA Signed Date", dbMapping: "metadata.nda.ndaSignedDate", fieldType: "date"},
            { fieldLabel: "SPG Signed Document", dbMapping: "metadata.spg.fileUpload", fieldType: "file"},
            { fieldLabel: "SPG Signed?", dbMapping: "metadata.spg.spgCheck", fieldType: "bool"},
            { fieldLabel: "SPG Signed Date", dbMapping: "metadata.spg.spgSignedDate", fieldType: "date"},
        ],
    },
    {
        name: "Employee/Worker Information",
        fields: [
          {
            fieldLabel: "Total Number of Employees",
            dbMapping: "metadata.workerCountTotal",
            fieldType: "number",
          },
          {
            fieldLabel: "Age of youngest worker at facility",
            dbMapping: "metadata.employeeInfo.ageOfYoungestWorker",
            fieldType: "number",
          },
          {
            fieldLabel: "Number of Male workers",
            dbMapping: "metadata.workerGenderMale",
            fieldType: "number",
          },
          {
            fieldLabel: "Number of Female workers",
            dbMapping: "metadata.workerGenderFemale",
            fieldType: "number",
          },
          {
            fieldLabel: "Number of Local/ Domestic Migrant Workers",
            dbMapping: "metadata.domesticMigrantWorkers",
            fieldType: "number",
          },
          {
            fieldLabel: "Number of Foreign Migrant Workers",
            dbMapping: "metadata.foreignMigrantWorkers",
            fieldType: "number",
          },
          {
            fieldLabel: "Main language(s) spoken by Employees/Workers",
            dbMapping: "metadata.employeePrimarilySpokenLanguage",
            fieldType: "string",
          },
          {
            fieldLabel:
              "Are workers hired through labor brokers/staffing agencies?",
            dbMapping:
              "metadata.employeeInfo.workedHiredThroughBrokersOrAgenciesCheck",
            fieldType: "dropdown",
            dropdownOptions: ["Yes", "No"],
          },
          {
            fieldLabel: "Are fees paid to them by the employees/workers?",
            dbMapping: "metadata.employeeInfo.agencyWorkersFeesPaidCheck",
            fieldType: "dropdown",
            dropdownOptions: ["Yes", "No"],
          },
          {
            fieldLabel: "Do employees retain their own documents?",
            dbMapping: "metadata.employeeInfo.doEmployeesRetainTheirDocuments",
            fieldType: "dropdown",
            dropdownOptions: ["Yes", "No", "Unsure"],
          },
          {
            fieldLabel:
              "If 'NO', who retains documents and where are they maintained?",
            dbMapping: "metadata.employeeInfo.whoRetainsEmployeeDocuments",
            fieldType: "string",
          },
        ],
      },
  ];