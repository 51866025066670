import React from "react";
import { Box, Grid, Typography, Drawer, Divider, Checkbox } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { selectSupplierNameFromId } from "../../selectors/supplier.selector";
import { useSelector } from "react-redux";
import { ComponentStyles, FacilityStyles } from "../../styles";
import FacilityTierMap from "./facilityTierMap.component";

const FacilityInformationDrawer = ({
  factoryData,
  isDrawerOpen,
  toggleDrawer,
}) => {
  const params = useParams();
  const supplierName = useSelector((state) =>
    selectSupplierNameFromId(state, params.supplierId)
  );

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
      <Box
        sx={{ width: 800, padding: 5, paddingLeft: 6, paddingRight: 6 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <Box>
          <Typography variant="h4" sx={{ mb: 4, mt: 2 }}>
            More Information
          </Typography>
          <Divider sx={{ mb: 4 }} />

          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Supply Chain Status</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.supplyChainStatus || "-"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={ComponentStyles.fieldName}>Compliance Status</Typography>
              <Typography sx={ComponentStyles.fieldValue}>
                {factoryData?.metadata?.complianceStatus || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FacilityInformationDrawer;
