import React from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { selectAuditPageTab } from "../../selectors/audit.selector";
import { setAuditPageTab } from "../../reducers/audit.reducer";
import { setDueAuctionTabs } from "../../reducers/dueAction.reducer";
import { selectDueActionTabs } from "../../selectors/dueAction.selector";

const DueActionTabs = () => {
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setDueAuctionTabs(newValue));
  };
  const value = useSelector(selectDueActionTabs);
  return (
    <Box sx={{ width: "100%", px: 2, flexGrow: 1, px: 4, mt: 2 }}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        sx={{ textDecoration: "none" }}
      >
        <Tab value="cap" label="CAP" sx={{ textTransform: "none" }} />
        <Tab
          value="audit"
          label="Audit Due Date"
          sx={{ textTransform: "none" }}
        />
        <Tab
          value="wcc"
          label="Certificates/Licenses"
          sx={{ textTransform: "none" }}
        />
      </Tabs>
    </Box>
  );
};

export default DueActionTabs;
